import Highcharts from 'highcharts'

function getBarChartOptions(toolTipFormatter, data, trendName, finalData) {
  

  return {
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    yAxis: {
      title: {
        text: 'Patients Vaccinated (in %)',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },

      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + '%'
        },
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: finalData[0].data,
      crosshair: true,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '%<br/>'
        )
        //'Total: ' + this.point.stackTotal;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y + ' %'
          },
        },
        enableMouseTracking: true,
      },
    },
    series: [
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
        color: '#9EAFDA',
      },
      {
        name: finalData[2].name,
        data: finalData[2].data,
        showInLegend: false,
        color: '#003C71',
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            //maxWidth: 1200
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'right',
              labelFormatter: function () {
                return this.name + ': ' + this.y + '%'
              },
            },
          },
        },
      ],
    },
  }
}

export default getBarChartOptions
