import React, { useState } from 'react';
import Select from 'react-select';
import styles from './Dropdown.module.scss'
function Dropdown({data, typeData,lobData,seasonData,monthData,dataType, defaultdata }) {

  const [selectedValue, setSelectedValue] = useState([]);

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    let updatedValue = {};
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    if (dataType==='type')
    {
      updatedValue = {type:e.map(x => x.value)}
      typeData(updatedValue.type.toString())
    }
    if (dataType==='lob')
    {
      updatedValue = {lob:e.map(x => x.value)}
      lobData(updatedValue.lob.toString())
    }
    if (dataType==='season')
    {
      updatedValue = {season:e.map(x => x.value)}
      seasonData(updatedValue.season.toString())
    }
    if (dataType==='month')
    {
      updatedValue = {month:e.map(x => x.value)}
      monthData(updatedValue.month.toString())
    }
    
  }
  
  return (
    <div className={styles.App}>
      <Select
        
        className="dropdown"
        placeholder="Select Options"
      
        options={data} // set list of the data
        onChange={handleChange} // assign onChange function
        isMulti
        isClearable
        defaultValue= {defaultdata}
        
      />

     
    </div>
  );
}

export default Dropdown;
