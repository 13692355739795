const usStateDummy ={
    "NV": {
        "geo_id": "NV",
        "geo_name": "Nevada",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.234",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.9",
        "istrue": true,
        "forecast_level": 0
    },
    "IL": {
        "geo_id": "IL",
        "geo_name": "Illinois",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.771",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "4.4",
        "istrue": true,
        "forecast_level": 0
    },
    "OK": {
        "geo_id": "OK",
        "geo_name": "Oklahoma",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.893",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.5",
        "istrue": true,
        "forecast_level": 0
    },
    "KY": {
        "geo_id": "KY",
        "geo_name": "Kentucky",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.254",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.9",
        "istrue": true,
        "forecast_level": 0
    },
    "NM": {
        "geo_id": "NM",
        "geo_name": "New Mexico",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.496",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "8.3",
        "istrue": true,
        "forecast_level": 0
    },
    "ID": {
        "geo_id": "ID",
        "geo_name": "Idaho",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.207",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.4",
        "istrue": true,
        "forecast_level": 0
    },
    "OR": {
        "geo_id": "OR",
        "geo_name": "Oregon",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "0.696",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.0",
        "istrue": false,
        "forecast_level": 0
    },
    "TN": {
        "geo_id": "TN",
        "geo_name": "Tennessee",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "3.200",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.9",
        "istrue": true,
        "forecast_level": 0
    },
    "IA": {
        "geo_id": "IA",
        "geo_name": "Iowa",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.561",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.4",
        "istrue": true,
        "forecast_level": 0
    },
    "MS": {
        "geo_id": "MS",
        "geo_name": "Mississippi",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "6.035",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "6.7",
        "istrue": true,
        "forecast_level": 0
    },
    "DC": {
        "geo_id": "DC",
        "geo_name": "District of Columbia",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.433",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.0",
        "istrue": true,
        "forecast_level": 0
    },
    "WI": {
        "geo_id": "WI",
        "geo_name": "Wisconsin",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.819",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.6",
        "istrue": true,
        "forecast_level": 0
    },
    "AL": {
        "geo_id": "AL",
        "geo_name": "Alabama",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.800",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.0",
        "istrue": true,
        "forecast_level": 0
    },
    "AZ": {
        "geo_id": "AZ",
        "geo_name": "Arizona",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.753",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "6.3",
        "istrue": true,
        "forecast_level": 0
    },
    "VT": {
        "geo_id": "VT",
        "geo_name": "Vermont",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.230",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.5",
        "istrue": true,
        "forecast_level": 0
    },
    "LA": {
        "geo_id": "LA",
        "geo_name": "Louisiana",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "4.870",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "8.0",
        "istrue": true,
        "forecast_level": 0
    },
    "NJ": {
        "geo_id": "NJ",
        "geo_name": "New Jersey",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.561",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.9",
        "istrue": true,
        "forecast_level": 0
    },
    "MO": {
        "geo_id": "MO",
        "geo_name": "Missouri",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.135",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "5.4",
        "istrue": true,
        "forecast_level": 0
    },
    "MA": {
        "geo_id": "MA",
        "geo_name": "Massachusetts",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.056",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.2",
        "istrue": false,
        "forecast_level": 0
    },
    "NH": {
        "geo_id": "NH",
        "geo_name": "New Hampshire",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.316",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.3",
        "istrue": true,
        "forecast_level": 0
    },
    "PA": {
        "geo_id": "PA",
        "geo_name": "Pennsylvania",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.911",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.6",
        "istrue": true,
        "forecast_level": 0
    },
    "HI": {
        "geo_id": "HI",
        "geo_name": "Hawaii",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.174",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.2",
        "istrue": true,
        "forecast_level": 0
    },
    "TX": {
        "geo_id": "TX",
        "geo_name": "Texas",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.313",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "7.7",
        "istrue": true,
        "forecast_level": 0
    },
    "MD": {
        "geo_id": "MD",
        "geo_name": "Maryland",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.751",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.8",
        "istrue": true,
        "forecast_level": 0
    },
    "NE": {
        "geo_id": "NE",
        "geo_name": "Nebraska",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.372",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.9",
        "istrue": true,
        "forecast_level": 0
    },
    "CT": {
        "geo_id": "CT",
        "geo_name": "Connecticut",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.719",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.1",
        "istrue": true,
        "forecast_level": 0
    },
    "RI": {
        "geo_id": "RI",
        "geo_name": "Rhode Island",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.591",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.6",
        "istrue": false,
        "forecast_level": 0
    },
    "GA": {
        "geo_id": "GA",
        "geo_name": "Georgia",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.726",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.4",
        "istrue": true,
        "forecast_level": 0
    },
    "SC": {
        "geo_id": "SC",
        "geo_name": "South Carolina",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.680",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.6",
        "istrue": true,
        "forecast_level": 0
    },
    "FL": {
        "geo_id": "FL",
        "geo_name": "Florida",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.115",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.5",
        "istrue": true,
        "forecast_level": 0
    },
    "WV": {
        "geo_id": "WV",
        "geo_name": "West Virginia",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.890",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.5",
        "istrue": true,
        "forecast_level": 0
    },
    "AK": {
        "geo_id": "AK",
        "geo_name": "Alaska",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.742",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "5.4",
        "istrue": true,
        "forecast_level": 0
    },
    "CA": {
        "geo_id": "CA",
        "geo_name": "California",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.409",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "8.0",
        "istrue": true,
        "forecast_level": 0
    },
    "VA": {
        "geo_id": "VA",
        "geo_name": "Virginia",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.448",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.5",
        "istrue": true,
        "forecast_level": 0
    },
    "ME": {
        "geo_id": "ME",
        "geo_name": "Maine",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.263",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.2",
        "istrue": true,
        "forecast_level": 0
    },
    "ND": {
        "geo_id": "ND",
        "geo_name": "North Dakota",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.878",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.1",
        "istrue": true,
        "forecast_level": 0
    },
    "MI": {
        "geo_id": "MI",
        "geo_name": "Michigan",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.781",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.8",
        "istrue": true,
        "forecast_level": 0
    },
    "NC": {
        "geo_id": "NC",
        "geo_name": "North Carolina",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.351",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0.4",
        "istrue": true,
        "forecast_level": 0
    },
    "CO": {
        "geo_id": "CO",
        "geo_name": "Colorado",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.484",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "9.3",
        "istrue": true,
        "forecast_level": 0
    },
    "SD": {
        "geo_id": "SD",
        "geo_name": "South Dakota",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.454",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.6",
        "istrue": true,
        "forecast_level": 0
    },
    "DE": {
        "geo_id": "DE",
        "geo_name": "Delaware",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.749",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.3",
        "istrue": true,
        "forecast_level": 0
    },
    "WY": {
        "geo_id": "WY",
        "geo_name": "Wyoming",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.285",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.7",
        "istrue": true,
        "forecast_level": 0
    },
    "UT": {
        "geo_id": "UT",
        "geo_name": "Utah",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.394",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "7.7",
        "istrue": true,
        "forecast_level": 0
    },
    "AR": {
        "geo_id": "AR",
        "geo_name": "Arkansas",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.492",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.4",
        "istrue": true,
        "forecast_level": 0
    },
    "WA": {
        "geo_id": "WA",
        "geo_name": "Washington",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.161",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "1.1",
        "istrue": false,
        "forecast_level": 0
    },
    "IN": {
        "geo_id": "IN",
        "geo_name": "Indiana",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.702",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.4",
        "istrue": true,
        "forecast_level": 0
    },
    "NY": {
        "geo_id": "NY",
        "geo_name": "New York",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.227",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "2.0",
        "istrue": true,
        "forecast_level": 0
    },
    "MN": {
        "geo_id": "MN",
        "geo_name": "Minnesota",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "1.428",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "7.9",
        "istrue": true,
        "forecast_level": 0
    },
    "OH": {
        "geo_id": "OH",
        "geo_name": "Ohio",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.339",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "3.2",
        "istrue": true,
        "forecast_level": 0
    },
    "MT": {
        "geo_id": "MT",
        "geo_name": "Montana",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "0",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "0",
        "istrue": true,
        "forecast_level": 0
    },
    "KS": {
        "geo_id": "KS",
        "geo_name": "Kansas",
        "geo_type": "STATE",
        "geo_parent": "US",
        "year_week": "2023_46",
        "season": "2023-24",
        "epiweek": 46,
        "year": 2023,
        "data_value": "2.271",
        "week_ending": "2023-11-18",
        "data_type": "FORECAST",
        "change_actual_percentage": "4.7",
        "istrue": true,
        "forecast_level": 0
    }
}

export default usStateDummy