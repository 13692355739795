import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import HC_rounded from 'highcharts-rounded-corners'
import toolTipFormatter from './toolTipFormatterActivityLogs'
HC_rounded(Highcharts)

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const ActivityStatsBar = ({ area, yAxisLabel = false, height = '300px' }) => {
  const dashboardType = area.map((d) => {
    return [d.dashboard_name]
  })
  const hitsValues = area.map((d) => {
    return [d.total_hits]
  })  
  const chartOptions = {
    title: {
      text: undefined,
    },
    
    xAxis: {
      categories: dashboardType,
      // categories: [
      //   'Jan',
      //   'Feb',
      //   'Mar',
      //   'Apr',
      //   'May',
      //   'Jun',
      //   'Jul',
      //   'Aug',
      //   'Sep',
      //   'Oct',
      //   'Nov',
      //   'Dec',
      // ],
      crosshair: true,
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      labels: { enabled: yAxisLabel },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: toolTipFormatter,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',      
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        borderRadiusTopLeft: '30%',
        borderRadiusTopRight: '30%',
      },
    },
    series: [
      {
        name: 'Total Hits', //'Cases Daily',
        data: hitsValues,
        // data: [
        //   49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
        //   95.6, 54.4,
        // ],
        type: 'column',
        zIndex: 1,
        color: '#15A796',
        marker: {
          enabled: false,
        },
      },
    ],
  }
  return (
    <>
      <h4 style={{marginTop:'2rem'}}>Total Hits per Dashboard</h4>
      <HighchartsReact
        containerProps={{
          style: {
            width: '100%',
            position: 'relative',
            height: height,
          },
        }}
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate
        updateArgs={[true, true, true]}
      />
    </>
  )
}

export default ActivityStatsBar
