import React from 'react'
import { MenuContext } from '../Menu'
import Icon from '../Icon'

import styles from './Item.module.scss'
import Badge from '../Badge'

const Item = ({
  children = null,
  href,
  label,
  onClick,
  render,
  selected: isSelected,
  new: isNew,
  ...rest
}) => {
  const [isVisible, setVisible] = React.useState(false)
  const linkRef = React.useRef()
  React.useEffect(() => {
    function documentClick(event) {
      if (!linkRef.current) return
      if (!linkRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('click', documentClick)

    return () => document.removeEventListener('click', documentClick)
  }, [])
  const className = [
    styles.base,
    rest.className ? rest.className : '',
    isVisible ? styles.open : '',
    isSelected ? styles.selected : '',
  ].join(' ')

  if (children) {
    const hideMenu = () => setVisible(false)

    return (
      // eslint-disable-next-line jsx-a11y/role-supports-aria-props
      <li aria-haspopup className={className} ref={linkRef}>
        <span>
          <MenuContext.Provider value={{ isVisible }}>
            <button onClick={() => setVisible((visible) => !visible)}>
              {label}
              {isNew && <Badge text={'New'} />}
              <Icon type="arrow_down" />
            </button>
            {typeof children === 'function' ? children({ hideMenu }) : children}
          </MenuContext.Provider>
        </span>
      </li>
    )
  }

  return (
    <li className={className}>
      <span>
        <MenuContext.Provider value={{ isVisible }}>
          {typeof render === 'function' ? (
            render()
          ) : (
            <a href={href} onClick={onClick}>
              {label}
              New
              {isNew && (
                <span
                  style={{
                    position: 'relative',
                    backgroundColor: 'red',
                    padding: '0.1rem',
                    fontSize: '.25rem',
                    top: '-10px',
                    marginLeft: '0.25rem',
                  }}
                >
                  New
                </span>
              )}
            </a>
          )}
        </MenuContext.Provider>
      </span>
    </li>
  )
}

export default Item
