import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { useGeoFetch } from '../../components/GeoProvider'
import GeoProvider from '../../components/GeoProvider'
import Map from '../../components/Map'
import EmptyMap from '../../components/Map/emptyMap'

import colorAxisFluIndex from '../Flu/FluTabs/FluMap/colorAxisFluIndex'

import FluDefaults from './FluDefaults.js'
import styles from './FluMap.module.scss'

const FluMap = ({
  staticStateJson}) => {
  const history = useHistory()

  const today = dayjs().day(-1).format('YYYY-MM-DD')
  const weekEndData = dayjs().day(-1).format('YYYY-MM-DD')
  const mapOptions = {
    value: 'forecast_level',
    tagKey: 'forecast_level',
    tagValue: 'forecast_value',
    label: 'Flu Forecast Levels',
    colorAxis: colorAxisFluIndex,
    legend: {
      enabled: false,
      title: {
        text: 'Flu Forecast Levels',
      },
    },
  }

  const [states, setStates] = useState(undefined)

  
  // State Data
  const { data: statesTodayData, error: stateTodayDataError } = useGeoFetch(
    today,
    today,
    'flu',
    'state'
  )

  useEffect(() => {
    if (statesTodayData !== undefined && statesTodayData !== null) {
      setStates(statesTodayData.response_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesTodayData])

  if (stateTodayDataError && staticStateJson) {
    const fluDefaults = {}
    fluDefaults[today] = FluDefaults
    return (
      <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
        <div
          onClick={() => {
            history.push('/forecasts/Flumap')
          }}
          className={styles.base}
          title={'Click to view more details'}
        >
          
          <Map
            toolTipFormatter={null}
            activeCbsasData={null}
            statesData={fluDefaults}
            cbsaData={null}
            mapOptions={mapOptions}
            enableMapNavigation={false}
            enableTooltip={false}
            enableMouseTracking={false}
            mapHeight={'40vh'}
            // mapWidth={'50vh'}
            mapType="FLUDASHBOARDMAP"
            stateJson={staticStateJson}
          />
         
        </div>
      </GeoProvider>
    )
  }
  return (
    <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
      {states && staticStateJson ? (
        <div
          className={styles.base}
          onClick={() => {
            history.push('/forecasts/Flumap')
          }}
        >
         
          <Map
            toolTipFormatter={null}
            activeCbsasData={null}
            statesData={states}
            cbsaData={null}
            mapOptions={mapOptions}
            enableMapNavigation={false}
            enableTooltip={false}
            enableMouseTracking={false}
            mapHeight={'40vh'}
            // mapWidth={'50vh'}
            mapType="FLUDASHBOARDMAP"
            stateJson={staticStateJson}
          />
         
        </div>
      ) : (
        <EmptyMap mapHeight={'40vh'} />
      )}
    </GeoProvider>
  )
}


export default FluMap
