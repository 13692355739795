
import React, { useState,useEffect } from 'react'
import styles from './DashboardHeader.module.scss'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'

const DashboardHeader = () => { 

  const [getPatientCount, setPatientCount] = useState()
  const [getTimePeriod, setTimePeriod] = useState()
  const [getSourceData, setSourceData] = useState()

  // Call api here    
  const entity = 'riskcal' 
  const { data: riskData } = useGeoFetch('', '', entity, 'getriskdetails')

useEffect(() => {
 //check data here  
if (riskData !== undefined){
  if(riskData.response_code===200){    
    setPatientCount(riskData.response_data[0].patient_count)
    setTimePeriod(riskData.response_data[0].time_period)  
    setSourceData(riskData.response_data[0].source_data)      
  }
  else
  {
    return <ErrorMessage errorCode={riskData.response_code} errorMessage={riskData.response_message} errorSubject={riskData.response_subject} />
  }
}
}, [riskData])


  return (
    <div className={styles.base}>
      <div className={styles.firstPanel}>
        <h4>Cardiac Risk Calculator</h4>
        <p>
          A machine learning algorithm that calculates the probability of
          cardiac risk in a patient infected with COVID-19.
        </p>
      </div>
      <div className={styles.secondPanel}>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Algorithm</span>
          </div>
            <span className={styles.headerSpan}>Random Forest</span>
        </div>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Patient Condition</span>
          </div>
          <span className={styles.headerSpan}>COVID-19 Positive Patients</span>
        </div>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Patient Count</span>
          </div>
          <span className={styles.headerSpan}>{getPatientCount}</span>
        </div>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Time Period</span>
          </div>
          <span className={styles.headerSpan}>{getTimePeriod}</span>
        </div>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Source of Data</span>
          </div>
            <span className={styles.headerSpan}>{getSourceData}</span>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
