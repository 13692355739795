// import topGraphPanel from '../FluVaccinationDetails/TopGraphPanel'

import React,{useEffect} from 'react'
import styles from './Rarediseases.module.scss'
import Spinner from '../../components/Loader/Spinner'
import Tabs from '../../components/Tabs'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
import Sickle from './SickleDetails'
import Thal from './ThalassemiaDetails'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const RarediseaseDetails = () => {
  const { user } = useAuthState()
  var access = true; 
  // var groups = user.groups  
  // groups.forEach((group, index) => { 
  //   if (group === "IDP_RareDisease") 
  //   {      
  //     access =true;      
  //   }
  // });
  
  useEffect(() => {
    var logObject = {
      dashboard_url: window.location.href,
      dashboard_name: 'Raredisease Dashboard',
      indicator_name: 'Raredisease Dashboard',
      geo_name: '',
      geo_type: '',
      user_name: user.username,
      email: user.email,
    }
    activityLog(logObject)
  }, [1])

  const entity = 'raredisease'

  const { data: sickleData, error } = useGeoFetch('', '', entity, 'getsickle')
  //check data here
  if (sickleData !== undefined) {
    if (sickleData.response_code === 200) {
    } else {
      return (
        <ErrorMessage
          errorCode={sickleData.response_code}
          errorMessage={''}
          errorSubject={sickleData.response_subject}
          errorDetails={sickleData.response_message}
        />
      )
    }
  }

  if (error) {
    return (
      <ErrorMessage
        errorCode={sickleData.response_code}
        errorMessage={sickleData.response_message}
        errorSubject={sickleData.response_subject}
      />
    )
  }

  return   (
    <Layout>
      <Content>
        <div className={styles.base}>
       
       
        { access ? (  
          <>
           <div className={styles.header}>
          <div style={{ float: 'left' }} className={styles.textFormatHeader}>Rare Diseases</div>
           <span style={{color:'#e40046'}}>&nbsp; <b>(Proof of concept)</b></span>
           </div>
           <div className={styles.content}>
              <div className={styles.rarediseases}>
                <Tabs>
                  <Tabs.Panel label="Sickle Cell">
                    {sickleData !== undefined &&
                    sickleData !== null &&
                    sickleData !== '' ? (
                      <Sickle data={sickleData.response_data}></Sickle>
                    ) : (
                      <Spinner />
                    )}
                  </Tabs.Panel>
                  <Tabs.Panel label="Thalassemia">
                    {sickleData !== undefined &&
                    sickleData !== null &&
                    sickleData !== '' ? (
                      <Thal></Thal>
                    ) : (
                      <Spinner />
                    )}
                  </Tabs.Panel>
                </Tabs>
              </div>
            </div> 
            </>        
         ):(
          <>
              <div>
                <div >
                  <h3 style={{color:'#cc0000'}}>
                    Access Denied:
                  </h3>
                  <h4>
                    Sorry, you do not have permission to access this page.<br/>                    
                  </h4>
                  <p>To get access, kindly raise <a href="https://secure.uhc.com/" alt="Secure" target="_blank">Secure</a>  request for <b>"IDP_RareDisease"</b> group.</p>
                  <h4></h4>
                  <br />
                  <br />
                </div>
              </div>             
          </>
         )}
         </div>
      
      </Content>
    </Layout>
  )
    
  
}

export default RarediseaseDetails
