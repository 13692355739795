import React from 'react'

import styles from './RadioButton.module.scss'

const RadioButton = ({
  indeterminate: isIndeterminate,
  label,
  selected: isSelected,
  value,
  name,
  id,
  onChange,
}) => {
  return (
    <label className={styles.base}>
      <input
        type="radio"
        value={value}
        id={id}
        name={name}
        checked={isSelected}
        onChange={onChange}
      />
      <span className={styles.labelText}>{label}</span>
    </label>
  )
}

export default RadioButton
