import React from "react";
import axios from "axios";
import FetchContext from "./FetchContext";

const useFetch = defaultData => {
  const context = React.useContext(FetchContext);

  const source = React.useRef(axios.CancelToken.source());
  const cancel = source.current.cancel;

  const [data, setData] = React.useState(defaultData);
  const [error, setError] = React.useState(null);
  const [hasLoaded, setLoaded] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [request, setRequest] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setLoading(true);

      source.current = axios.CancelToken.source();
      const cancelToken = source.current.token;

      try {
        const result = await axios({ ...context, ...request, cancelToken });

        setData(result.data);
        setLoaded(true);
      } catch (error) {
        if (!axios.isCancel(error)) setError(error);
      }

      setLoading(false);
    };

    if (request != null) fetchData();
  }, [context, request]);

  React.useEffect(() => () => cancel(), [cancel]);

  const reset = (resetData = defaultData) => {
    setData(resetData);
    setLoaded(false);
  };

  return [
    {
      cancel,
      data,
      error,
      hasError: Boolean(error),
      hasLoaded,
      isLoading,
      reset
    },
    setRequest
  ];
};

export default useFetch;
