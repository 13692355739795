import React from 'react'
import Icon from '../Icon'

import styles from './SearchCriteria.module.scss'

const SearchCriteria = React.forwardRef(({ criteria, onRemove }, ref) => (
  <ul ref={ref} className={styles.base}>
    {criteria.map(({ label, value, ...rest }) => (
      <li
        data-criteria
        key={rest.key}
        onKeyDown={({ key, currentTarget }) => {
          if (key === 'Backspace') onRemove(rest.key)

          const next = key === 'ArrowRight'
          const prev = key === 'ArrowLeft'
          if (next || prev) {
            const nextFocus =
              currentTarget[
                next ? 'nextElementSibling' : 'previousElementSibling'
              ]

            if (nextFocus) nextFocus.focus()
          }
        }}
        tabIndex={-1}
      >
        <strong>{label}:</strong> {value}{' '}
        <span
          className={styles.remove}
          onClick={() => onRemove(rest.key)}
          title="Remove"
        >
          <Icon type="remove" />
        </span>
      </li>
    ))}
  </ul>
))

export default SearchCriteria
