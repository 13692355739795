import React from 'react'
import add from '../../assets/images/Add.png';
const ButtonAddUser = ({ userCount, buttonText }) => {
  const cardStyles = {
    container: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '22.78px',
      color: 'rgb(50, 51, 52)',
      float: 'right',
      border: '1px solid',
      width: '10%',
      height: '44px',
      textAlign: 'center',
      marginTop: '-0px',
    },
    button: {
      //padding: '10px',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '22.78px',
      color: 'rgb(50, 51, 52)',
      float: 'right',
      border: '1px solid',
      width: '12%',
      height: '44px',
      textAlign: 'center',
     // marginTop: '-20px',
      float:'right',
      a: {
        cursor: 'pointer',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        textAlign: 'center',
        color: '#424242',
      },
      link:
      {
        color: '#424242',
        padding:'10px'
      }
    },
    add:
    {
      height: '15px',
      width: '20px',
      float: 'right',
      display: 'block',
      marginRight: '3px',
      marginTop: '-29px',
    }

  }

  return (
    // <div style={cardStyles.container}>
    //   <div style={cardStyles.button}>
    //   <img id="open-filter" className={cardStyles.add} src={add}/>
    //     <span style={cardStyles.button.a}><a style={cardStyles.button.link} href="#add">       
    //     {buttonText}        
    //       </a></span>
    //   </div>
    // </div>
    <div style={cardStyles.button}>
      <div style={{marginTop:'8px'}}>
      <img id="open-filter" className={cardStyles.add} src={add} />
      <span style={cardStyles.button.a}><a style={cardStyles.button.link} href="#add">
        {buttonText}
      </a></span>
      </div>
    </div>
  )
}

export default ButtonAddUser
