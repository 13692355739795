

function toolTipFormatter() {
   var url =  `${window.location.origin}/static/location_pin.png`;  
  let notAv = this.point.originating_business === 'Name Not Available'
  const divData = `<div style="font-family:'Optum Sans'; width:100%;background: transparent;border-radius:4px;padding: 16px 0 16px 16px;margin-right:20px">
  <div style="color: #424242;"><div style="  font-style:normal;font-weight:700;font-size: 14px;line-height: 20px;color: #002677;">
  ${this.point.location_code}: ${
    notAv !== true
      ?  this.point.originating_business
      : `<span style="color:#4b4d4f;font-weight:400"> (${this.point.originating_business})</span>`
  }</div>
  <div style="font-style: normal;font-weight: 700;font-size: 14px;line-height: 18px;color: #4B4D4F;margin-top: 16px;">
  ${this.point.primary_location_type_formated}  
  ${
    this.point.primary_location_type_formated !== 'Other'
      ? this.point.primary_location_sub_type_formated
      : ''
  } 
  </div> 
  <div style="margin-right:12px;font-style: normal;font-weight: 400;font-size: 14px;line-height: 18px;display: flex;align-items: center;color: #4b4d4f;margin-top: 16px;">
  
  <div style="padding-right: 10px;"><img src="${url}" alt="location" style="height: 19px; width: 16px; "></div>
  ${this.point.address_line1} ${this.point.address_line2}, <br/>${this.point.geo_name}
  </div></div></div>`
  return divData
}

export default toolTipFormatter
