function toolTipFormatter() {
  let trending = ''
  if (this.point.change_percentage < 0) trending = '&#8595;'
  if (this.point.change_percentage > 0) trending = '&#8593;'

  const pointName =
    this.point.properties.cbsa_name === undefined
      ? ''
      : `/${this.point.properties.cbsa_name}`

  return `<div style="width:110%;background-color:transparent;">
    <div style="width:90%;
    background-color:transparent;
    color:#7d7d7d;padding-left:10px;
    padding-top:10px;padding-right:5px;
    line-height:10px;
    display:inline;">${this.point.name.toUpperCase()}${pointName} FLU INDEX </div>

    <div style="line-height:10px;padding-top:10px;display: inline-block;padding-right:10px;">
    <span style="border-radius:50%;
    background-color:${this.color}; 
    display: inline-block;
    width: 10px;
    height: 10px"></span>&nbsp;
    </div>
    
    <div style="width:90%;padding-left: 10px;">
    <span style="color:black;
                font-weight:bold;font-size:14px;padding-right:10px;">${this.point.forecast_value.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 4,
                  }
                )}</span><span>(${this.point.change_percentage.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  )}%)</span></div>
    <div style="width:90%;padding-left: 10px;padding-right:10px;">
        <span style="font-weight:bold">${trending} </span><span style="font-size:10px">from previous epiweek
        </span>
    </div>
    <hr/ style="width:90%;margin-top:5px;">
    <div style="width:90%;
    padding-left:10px;padding-top:5px;
    font-size:12px;padding-right:10px;padding-bottom:7px;">CI Accuracy* : <span style="font-weight:bold">${this.point.forecast_ci_revised_95.toLocaleString(
      undefined,
      {
        maximumFractionDigits: 2,
      }
    )}%
    </span></div>
    </div>`
}

export default toolTipFormatter
