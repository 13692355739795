import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../../components/AuthProvider'
import Spinner from '../../components/Loader/Spinner'
import Container from '../../components/Container'
import Content from '../../components/Content'
import { IMPLEMENTATION_EMAIL } from '../../utils/constants'

const CreateSession = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const [
    { error, redirectRoute },
    { createSession, preventNextRedirect },
  ] = useAuth()

  React.useEffect(() => {
    const cs = async () => {
      await createSession()
    }

    if (!hasLoaded) {
      setIsLoading(true)
      cs()
      setIsLoading(false)
      setHasLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSession])

  if (error) {
    if (error === 'User Not Found') {
      return (
        <Container>
          <Content>
            <h1>Access Denied</h1>
            <p>
              You don't have permission to access this application. To request
              access to this application contact us at{' '}
              <a href={`mailto:${IMPLEMENTATION_EMAIL}`}>
                {IMPLEMENTATION_EMAIL}
              </a>
              .
            </p>
          </Content>
        </Container>
      )
    }
   //  else {
    //   return <ErrorMessage />
    // }
  }

  if (redirectRoute) {
    preventNextRedirect()

    return <Redirect to={redirectRoute} />
  }

  return (
    <Container>
      <Content>
        <Spinner />
      </Content>
    </Container>
  )
}

export default CreateSession
