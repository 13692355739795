import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GeoProvider from '../../../components/GeoProvider'
import FluGeo from '../FluTabs/FluMap/FluGeo'
import dataDisplayOptions from './FluMap/dataDisplayOptions'
import { startDate, endDate, displayDate } from '../dates'
const FluIndicesTabs = () => {
  const tabIndices = React.useMemo(() => ['#map', '#datatable'], [])
  const [selectedTab, setSelectedTab] = React.useState(0)

  const history = useHistory()
  const location = useLocation()

  const onLoadTabs = React.useCallback(() => {
    for (const key in tabIndices) {
      if (location.hash === tabIndices[key]) setSelectedTab(+key)
    }
  }, [location.hash, tabIndices])

  // const tabsChangeHandler = (index) => {
  //   setSelectedTab(index)
  //   history.push({ hash: tabIndices[index] })
  // }

  React.useEffect(() => {
    onLoadTabs()
  }, [onLoadTabs])

  return (
    
    <GeoProvider
    startDate={startDate}
    endDate={endDate}
    displayDate={displayDate}
    mapOptions={dataDisplayOptions[0]['options'][0]}
  >
    <FluGeo />
  </GeoProvider>
  )
}

export default FluIndicesTabs
