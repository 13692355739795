import React from 'react'
import Breadcrumbs from '../Breadcrumbs'

const DefaultBreadcrumbsPharma = ({
  geo_parent,
  geo_id,
  geo_type,
  dashboardType,
}) => {
  return (
    <Breadcrumbs>
      {geo_type === 'NAT' && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
          />
          <Breadcrumbs.Item className={'label'} key={'US'} label={'US'} last />
        </>
      )}
      {geo_type === 'STATE' && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
            first
          />
          <Breadcrumbs.Item
            key="US"
            label="US"
            href={'/' + dashboardType + 'Dashboard'}
          />
          <Breadcrumbs.Item
            className={'label'}
            key={geo_id}
            label={geo_id}
            last
          />
        </>
      )}
      {(geo_type === 'MSA' || geo_type === 'COUNTY') && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
            first
          />
          <Breadcrumbs.Item
            key="US"
            label="US"
            href={'/' + dashboardType + 'Dashboard'}
          />
          <Breadcrumbs.Item
            key={geo_parent}
            href={
              `/ ${dashboardType}StateDashboard/STATE/${geo_parent}`              
            }
            label={geo_parent}
          />
        </>
      )}
    </Breadcrumbs>
  )
}

export default DefaultBreadcrumbsPharma
