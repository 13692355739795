import React from 'react'

import DefaultBreadcrumbs from '../../components/Breadcrumbs/DefaultBreadcrumbs'
import { useAuthState } from '../../components/AuthProvider'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import Flex from '../../components/Flex'

import styles from './Profile.module.scss'
import activityLog from '../../utils/activityLog'
const Profile = () => {
  const { user, authMode } = useAuthState()

  var url = window.location.href
  var name = ''
  if (url.indexOf('profile') !== -1) name = 'User Profile'

  var logObject = {
    dashboard_url: url,
    dashboard_name: name,
    indicator_name: name,
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            <DefaultBreadcrumbs />
            <div className={styles.content}>
              <h1>
                {user.firstName} {user.lastName}
              </h1>
              <p>This page allows you see your profile information.</p>
            </div>
          </div>
          <div className={styles.body}>
            <Flex gutter={3}>
              <Flex.Item span={12}>
                <div className={styles.details}>
                  <div>
                    <h4>Name</h4>
                    <span>
                      {user.lastName}, {user.firstName}
                    </span>
                  </div>
                  <div>
                    <h4>User Name</h4>
                    <span>{user.username ? user.username : 'NA'}</span>
                  </div>
                  <div>
                    <h4>Email</h4>
                    <span>
                      <a href={'mailto:' + user.email}>{user.email}</a>
                    </span>
                  </div>
                  <div>
                    <h4>Phone</h4>
                    <span>{user.phone ? user.phone : 'NA'}</span>
                  </div>
                  <div>
                    <h4>Role</h4>
                    <span>{user.role}</span>
                  </div>
                  <div>
                    <h4>Authentication System</h4>
                    <span>
                      {authMode === 'msid' ? 'MSID' : 'One Healthcare ID'}
                    </span>
                  </div>
                </div>
              </Flex.Item>
            </Flex>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Profile
