


import React from 'react'
import styles from './DashboardHeader.module.scss'
import countingMethod from './../../utils/getCountingMethod'
import {  useGeoState } from '../../components/GeoProvider'
const CountPanelDetails = ({data}) => {
  const { setVulCount } = useGeoState('')
 
 var res = JSON.parse(data)
 
 setVulCount(countingMethod(res.Member_Vaccinated.Value, 'fixed'))
  return (
    <div className={styles.countPanel}>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Vaccinated</span>
          </div>
          <span className={styles.headerSpan}>
            {countingMethod(res.Member_Vaccinated.Value, 'fixed')}
          </span>
        </div>
        <div className={styles.dateWeek}>
          <div>
            <span className={styles.position}>Unique Claims Filed</span>
          </div>
          <span className={styles.headerSpan}>
            {countingMethod(res.Total_Claims.Value, 'fixed')}
          </span>
        </div>
      </div>
    
    )
}

export default CountPanelDetails


