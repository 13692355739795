import _uniq from "lodash/uniq";

export const TOGGLE_ITEM = "TOGGLE_ITEM";
export const RESET_STATE = "RESET_STATE";
export const SEARCH_ITEMS = "SEARCH_ITEMS";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const initialState = (items, value = []) => ({
  isMultiTier: items.some(item => item.children && item.children.length > 0),
  items: items.map(item => ({
    ...item,
    children: item.children
      ? item.children.map(child => ({
          ...child,
          children: child.children || []
        }))
      : []
  })),
  searchItems: [],
  searchText: "",
  values: typeof value === "string" ? [value] : value
});

export const reducer = (state, action) => {
  const getItems = values => {
    return state.items.map(item => {
      const selected =
        item.children.length > 0
          ? item.children.every(child => values.indexOf(child.value) !== -1)
          : values.indexOf(item.value) !== -1;
      return {
        ...item,
        selected,
        indeterminate:
          !selected &&
          item.children.some(child => values.indexOf(child.value) !== -1),
        children: item.children.map(child => ({
          ...child,
          selected: values.indexOf(child.value) !== -1
        }))
      };
    });
  };

  const getValues = item => {
    const changedValues = new Set(
      item.children.length > 0
        ? item.children.map(({ value }) => value)
        : [item.value]
    );
    // While searching a two-tier data structure, not all child items
    // may be visible. Selecting a parent will only select visible child
    // items; leaving the parent in an indeterminate state.
    //
    // If all visible child items are selected, selecting the parent again
    // will therefore de-select the visible child items.
    const isSearching = state.searchText && state.searchItems.length > 0;
    const forceDeselect =
      isSearching &&
      item.indeterminate &&
      item.children.every(child => child.selected);

    if (item.selected || forceDeselect) {
      // De-selected
      return state.values.filter(value => !changedValues.has(value));
    } else {
      // Select
      return action.isMultiSelect
        ? _uniq([...state.values, ...changedValues])
        : [...changedValues];
    }
  };

  const getSearchItems = (items, searchText) => {
    const hasSearchText = item =>
      item.text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    return items
      .filter(
        item =>
          hasSearchText(item) ||
          (item.children && item.children.some(hasSearchText))
      )
      .map(item => ({
        ...item,
        children: item.children ? item.children.filter(hasSearchText) : []
      }));
  };

  const { type } = action;

  if (type === TOGGLE_ITEM) {
    const values = getValues(action.item);
    const items = getItems(values);
    const searchItems = getSearchItems(items, state.searchText);
    return { ...state, items, searchItems, values };
  } else if (type === RESET_STATE) {
    return initialState(action.items, action.value);
  } else if (type === SEARCH_ITEMS) {
    return {
      ...state,
      searchItems: getSearchItems(state.items, action.searchText),
      searchText: action.searchText
    };
  } else if (type === CLEAR_SEARCH) {
    return { ...state, searchItems: [], searchText: "" };
  }

  throw Error(`Action of type ${type} is not supported`);
};
