import React, { useState, useEffect } from 'react'

import Message from '../../components/Message'

const SystemNotifications = () => {
  // TODO: Implement the ability to clean all messages in case of multiple

  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const systemNotificationApiUrl = `${clientConfigApiDomain}/systemnotifications`

  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [systemNotificationData, setSystemNotificationData] = useState([])

  useEffect(() => {
    const fetchSystemNotificationData = async (currentDate) => {
      const fetchSystemNotificationResponse = await fetch(
        `${systemNotificationApiUrl}?startDate=${currentDate}&endDate=${currentDate}`, {         
          credentials: 'include',
        })
      if (fetchSystemNotificationResponse.status === 200) {
        const fetchSystemNotificationResponseData =
          await fetchSystemNotificationResponse.json()
        setSystemNotificationData(fetchSystemNotificationResponseData)
      }
    }

    if (!hasLoaded) {
      setIsLoading(true)
      const currentDate = new Date().toISOString().split('T')[0]
      fetchSystemNotificationData(currentDate).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    }
  }, [hasLoaded, systemNotificationApiUrl])

  return (
    <>
      {!isLoading &&
        hasLoaded &&
        systemNotificationData.map((notification) => (
          <Message
            type="warning"
            onDismiss={() => {
              const nextNotifications = systemNotificationData.filter(
                (currentNotification) => currentNotification !== notification
              )
              setSystemNotificationData(nextNotifications)
            }}
            key={notification.id}
          >
            {notification.message}
          </Message>
        ))}
    </>
  )
}

export default SystemNotifications
