import React from 'react'

import styles from './ButtonList.module.scss'

const ButtonList = ({
  children = null,
  spacing = 1,
  vertical: isVertical,
  ...rest
}) => (
  <div
    {...rest}
    className={`${styles.base} ${rest.className ? rest.className : ''}`}
  >
    <div
      style={{
        margin: `-${spacing / 2}rem`,
        flexDirection: isVertical ? 'column' : 'row',
      }}
    >
      {React.Children.map(children, (child) => (
        <span style={{ padding: `${spacing / 2}rem` }}>{child}</span>
      ))}
    </div>
  </div>
)

export default ButtonList
