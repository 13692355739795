//import usCountiesAll from '../components/Map/cb_2018_us_county_20m_wkid102004.json1'
import statesIdMap from '../utils/stateIdMap' //Added
export default 

function countyMap(getCountyJsonFile, stateJson) {
  const statesId = statesIdMap(stateJson)
  let idNameMap = new Map()
  getCountyJsonFile.features.forEach((c) => {
    idNameMap[c.properties.GEOID] =
      c.properties.NAME + ' ,' + statesId[c.properties['STATEFP']]
  })
  return idNameMap
}
