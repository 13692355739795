import React from 'react'
import BackToHomeLink from '../BackToHomeLink'
import Container from '../Container'
import Content from '../Content'
import { SUPPORT_EMAIL } from '../../utils/constants'
import ErrorLogs from '../ErrorBoundary/errorlogs'
import { useAuthState } from '../AuthProvider'

const ErrorMessageNew = ({
  errorCode = { errorCode },
  errorSubject = { errorSubject },
  errorMessage = { errorMessage },
  errorDetails = { errorDetails },
}) => {
  const { user } = useAuthState()
  var logObject = {
    dashboard_url: window.location.pathname,
    dashboard_name: 'UI',
    indicator_name: errorDetails,
    trend_type: 'name',
    state_name: '',
    metro_area: '',
    user_name: user.user_name,  //'username',
    email:user.email, //'email',
  }
  ErrorLogs(logObject)
  return (
    <>
      <Container>
        <Content>
          <h1>
            {errorCode}: {errorSubject}
          </h1>
          <p>
            {errorMessage}
            <br />
            <br />
            Error Details : {errorDetails}
          </p>
          <p>
            If you see this message again, contact us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </p>
          <p>
            <BackToHomeLink />
          </p>
        </Content>
      </Container>
    </>
  )
}

export default ErrorMessageNew
