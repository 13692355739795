import React from 'react'
import GeoProvider from '../../../components/GeoProvider'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'

import HRRGeo from './HRRGeo'
import dataDisplayOptions from './dataDisplayOptions'
import {
  startDate,
  endDate,
  displayDate,
  startDateCounties,
  endDateCounties,
  startDateHrr,
  endDateHrr,
} from '../dates'

import styles from './Covid19Map.module.scss'

const HRR = () => {
  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Forecasts HRR',
    indicator_name: 'Forecasts HRR',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              startDateCounties={startDateCounties}
              endDateCounties={endDateCounties}
              startDateHrr={startDateHrr}
              endDateHrr={endDateHrr}
              mapOptions={dataDisplayOptions[0]['options'][0]}
            >
              <HRRGeo />
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default HRR
