import moment from 'moment'
import { CurrentEpiWeek } from './../SeasonalPattern/transformDataForGrid'

const toolTipFormatter = (ordinate, xAxisLable) => {
  let toolTipHTML = ''
 

  return toolTipHTML
}
const toolTipFormatterDateWeek = (ordinate, data) => {
  
  const currentWeekMinus3Weeks = new Date(CurrentEpiWeek())
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  let toolTipHTML = ''

  toolTipHTML = `<div style="background-color: #FFFFFF;color:#4B4D4F;  padding: 16px;border: 1px  #CBCCCD;
  border-radius: 4px; ">
  <span  style="font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  ">Week Ending: </span>
   <span style="font-family: 'Optum Sans', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;">
     ${moment(
      ordinate.x
    ).format('MMM DD,YYYY')}</span>`

  ordinate.points.forEach((point) => {
    let legendSymbol = ''
    if (point.series.legendSymbol !== undefined) {
      let series = point.series
      legendSymbol =
        "<svg width='16' height='16'>" +
        series.legendSymbol.element.outerHTML +
        point.series.legendSymbol.element.previousElementSibling.outerHTML +
        '</svg>'
    }
    
 if (point.series.name.toLocaleString().toLowerCase()==='actual' ||point.series.name.toLocaleString().toLowerCase()==='forecast')
      {toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${point.series.name}s: <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    }
    else{toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${point.series.name}: <b>
    ${point.point.low.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 0,
      }
    )} - ${point.point.high.toLocaleString(undefined, {
      minimumFractionDigits: 0,
    })}</b></h6>`}
   
  })

  return toolTipHTML
}

function epiweekToolTip() {
  return toolTipFormatter(this, 'epiWeek')
}

function dateToolTip() {
  return toolTipFormatter(this)
}
function dateWeekToolTip() {
  return toolTipFormatterDateWeek(this)
}

export { epiweekToolTip, dateToolTip, dateWeekToolTip }
