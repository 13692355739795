const handleFetchResponse = (response) => {
    return response.json().then((res) => {
      if (response.ok) {
        return { ...res }
      } else {
        let error = { ...res}
        return Promise.reject(error)
      }
    })
  }
  export default handleFetchResponse
