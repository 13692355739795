import React from 'react'
import moment from 'moment'
import statesMap from '../../utils/stateMap'
import cbsasMap from '../../utils/cbsasMap'

export const CurrentEpiWeek = () => {
  return moment()
    .startOf('week')
    .days(+6)
    .format('yyyy-MM-DD')
}

export const PreviousEpiWeek = () => {
  return moment().startOf('week').days(-1).format('yyyy-MM-DD')
}

export const TransformData = (data) => {
  let transformedData = new Map()

  Object.entries(data).forEach(([epiWeek, epiWeekData]) => {
    const locationCodes = Object.keys(epiWeekData).filter(
      (key) =>
        !['epi_period_week', 'epi_period_year', 'epi_season'].includes(key)
    )
    const season = epiWeekData['epi_season']
    locationCodes.forEach((value) => {
      transformedData[value] = {
        ...transformedData[value],
        [epiWeekData.epi_period_week]: {
          ...epiWeekData[value],
          epi_period_year: epiWeekData.epi_period_year,
          epi_season: season,
          epi_week: epiWeek,
        },
        parent: epiWeekData[value].parent,
      }
    })
  })
  return transformedData
}

export const FlattenJsonAndMergeData = (stateData, cbsaData) => {
  let stateIdNameMap = React.useMemo(() => statesMap(), [])
  let cbsaIdNameMap = React.useMemo(() => cbsasMap(), [])

  let flattenedArray = []

  var current_epi_week = CurrentEpiWeek()

  Object.entries(cbsaData).forEach(([key, values]) => {
    values['depth'] = 1
    values['identifier'] = key
    values['name'] = cbsaIdNameMap[key]
    values['category'] = 'city'
    stateData[values['parent']]['subRows']
      ? stateData[values['parent']]['subRows'].push(values)
      : (stateData[values['parent']]['subRows'] = [values])
  })

  Object.entries(stateData).forEach(([key, values]) => {
    values['name'] = stateIdNameMap[key]
    values['identifier'] = key
    values['category'] = 'state'

    let current_epi_week_data = Object.values(values).filter(
      (val) => val.epi_week === current_epi_week
    )
    if (current_epi_week_data.length > 0) {
      values['currentWeek'] = current_epi_week_data[0]

      if ('subRows' in values) {
        values['subRows'].forEach((value) => {
          let city_current_epi_week_data = Object.values(value).filter(
            (val) => val.epi_week === current_epi_week
          )
          value['currentWeek'] = city_current_epi_week_data[0]
        })
      }
    }
    flattenedArray.push(values)
  })

  return flattenedArray
}
