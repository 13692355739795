export default function MapLegend(props) {
  return (
    <svg
    viewBox="0 0 90 150"
    height="150"
    width="90"
    align="center"
    margin-top="0"
    {...props}
  >
    <rect
      x="1"
      y="0"
      width="16"
      height="16"
      fill="#C40000"
      //stroke="#C40000"
      strokeWidth="1px"
    ></rect>
    <rect
      x="1"
      y="25"
      width="16"
      height="16"
      fill="#FB8D7B"
      stroke="#FF1A1A"
      strokeWidth="1px"
    ></rect>
    <rect
      x="1"
      y="50"
      width="16"
      height="16"
      fill="#FFFFF0"
      stroke="#C39F00"
      strokeWidth="1px"
    ></rect>
    <rect
      x="1"
      y="75"
      width="16"
      height="16"
      fill="#6CB2A2"
      stroke="#15A796"
      strokeWidth="1px"
    ></rect>
    <rect
      x="1"
      y="100"
      width="16"
      height="16"
      fill="#218371"
      stroke="#218371"
      strokeWidth="1px"
    ></rect>
    <rect
      x="1"
      y="130"
      width="16"
      height="16"
      fill="#6D6F70"
      stroke="#6D6F70"
      strokeWidth="1px"
    ></rect>

    <text
      x="25"
      y="13"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Very High
    </text>
    <text
      x="25"
      y="39"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      High
    </text>
    <text
      x="25"
      y="63"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Medium
    </text>
    <text
      x="25"
      y="87"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Low
    </text>
    <text
      x="25"
      y="113"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Minimal
    </text>

    <path stroke="#CBCCCD" d="M 0 123 L 18 123" fill="#CBCCCD"></path>

    <text
      x="25"
      y="142"
      style={{
        width: '57px',
        height: '16px',
        fontw: 400,
        fontSize: '12.64px',
      }}
    >
      No Data
    </text>
  </svg>
)
}