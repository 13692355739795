import { React, useState } from 'react'
import { useGeoState } from '../GeoProvider'
import moment from 'moment'

import styles from './DisplayDate.module.scss'
import Select from 'react-select'
import { components } from 'react-select'
import {  FormField, Label } from '@better-ui/react'


export const DisplayDateSurveillance = ({
  posOptions,
  lobOptions,
}) => {
  const dataPos = posOptions.map((pos) => {
    return {
      value: pos.data_id,
      label: pos.data_value,
    }
  })
  const dataLob = lobOptions.map((lob) => {
    return {
      value: lob.data_id,
      label: lob.data_value,
    }
  })
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid #4B4D4F',
      boxSizing: 'border-box',
      cursor: 'pointer',
    }),
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
      fontWeight: state.isSelected ? 'bold' : '',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
        cursor: 'pointer',
        padding: '0px 0px 0px 5px',
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#002677',
      borderRadius: '100px',
      width: '32px',

      '&:hover': {
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
      },
    }),
  }
  const {displayDate,setPosSelected ,setLobSelected,setIsChecked} = useGeoState()

  const [selectedPos, setSelectedPos] = useState([dataPos[0]])
  const [selectedLob, setSelectedLob] = useState([dataLob[0]])

function changeHandlerPos(e) {
  setSelectedPos(e)
  setPosSelected(e.value)
  setIsChecked(false)
}
function changeHandlerLob(e) {
  setSelectedLob(e)
  setLobSelected(e.value)
  setIsChecked(false)
}

//// Start here
const Option = (props) => {  
  return (
    <div > 
      <components.Option {...props}>
        <FormField  >
        <Label > {props.label}</Label>
        </FormField>
      </components.Option>
    </div>
   
  )
}
////End here


  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component


  // TODO: can break this into a common function for the display date and this component to use!


  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        <p className={styles.displayDateMargin}>
          Viewing Week of  {' '}
          {moment(displayDate).format('MMMM D, YYYY')}{' '}
        </p>
      </div>
      <div className={styles.dataFilter}>
      <div className={styles.filterOption2}>
          <span>Place of Service</span>
        <Select
           
              options={dataPos}
              onChange={changeHandlerPos}
              value={selectedPos}
              placeholder={`Select POS`}
              styles={colourStyles}
              components={{
                Option,
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={true}
            />
        </div>
      <div className={styles.filterOption2}>
          <span>Line Of Business</span>
        <Select
           
              options={dataLob}
              onChange={changeHandlerLob}
              value={selectedLob}
              placeholder={`Select LOB`}
              styles={colourStyles}
              components={{
                Option,
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={true}
            />
        </div>
       
    
        
      </div>
    </div>
  )
}

export default DisplayDateSurveillance
