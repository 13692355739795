import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import FluDetailedView from './FluDetailedView'
import styles from './FluGeo.module.scss'

const FluGeo = () => {
  const entity = 'flumeter'  
  const location = useLocation()
  const hash = location.hash
 

  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }

  

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'flu/activecbsa'
  )

  // State Combined data for right side graph view
  const { data: statesCombinedData } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'state?seasons=all'
  )

  // metro Combined data for right side graph view
  const { data: metrosCombinedData } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'msa?seasons=all'
  )
  if (activeCbsasDataError) {
    return <ErrorMessage />
  }
  return  (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <FluDetailedView
          cbsaData={metrosCombinedData}
          statesData={statesCombinedData}
          hash={hash}
          
        />
      </div>
    </div>
  )
}

export default FluGeo
