import React from 'react'
import ErrorMessage from '../Layout/ErrorMessage'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.state = { error: null }
  } 
  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo })
  }
  render() {
    if (this.state.hasError) {
      {             
        return (
          <ErrorMessage
            errorCode="401"
            errorSubject="Unable to process the request."
            errorMessage="Impotant Tips: clear browser cache, open dashboard in new window, check network connectivity."
            errorDetails={String(this.state.error)}
          />
        )
      }
    }
    return this.props.children
  }
}
export default ErrorBoundary
