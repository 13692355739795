import colorAxisFluIndex from './colorAxisFluIndex'

const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'forecast_level',
        tagKey: 'forecast_level',
        tagValue: 'forecast_value',
        label: 'Influenza Levels',
        mapTitle: 'United States Influenza ',
        colorAxis: colorAxisFluIndex,
        legend: {
          enabled: false,
          title: {
            text: 'Influenza Levels',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
