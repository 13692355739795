// import colorAxisFluChange from './colorAxisFluChange'
// import legend from './legend'

const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'forecast_level',
        tagKey: 'forecast_level',
        tagValue: 'forecast_value',
        label: 'Influenza Levels',

        mapTitle: 'United States Influenza ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        legend: {
          enabled: false,
          title: {
            text: 'Influenza Levels',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
