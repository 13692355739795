import React from 'react'
import { useMedia } from '../ResponsiveMedia'

import styles from './Button.module.scss'

const Button = React.forwardRef(
  (
    {
      children = null,
      disabled: isDisabled,
      onClick,
      primary: isPrimary,
      type = 'button',
      showArrow = false,
      ...rest
    },
    ref
  ) => (
    <button
      disabled={isDisabled}
      className={`${styles.base} ${isPrimary ? styles.primary : ''} ${
        rest.className ? rest.className : ''
      }`}
      onClick={onClick}
      ref={ref}
      style={{ width: useMedia(rest.width) }}
      type={type}
    >
      {children}
      {showArrow && (
        <div className={styles.iconContainer} data-svg="arrow-right-hover">
          <svg
            focusable="false"
            width="24px"
            height="11px"
            viewBox="0 0 24 11"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-112.000000, -784.000000)">
                <g transform="translate(56.000000, 640.000000)">
                  <g transform="translate(68.500000, 149.500000) scale(-1, 1) rotate(-90.000000) translate(-68.500000, -149.500000) translate(48.000000, 129.000000)">
                    <g transform="translate(20.500000, 20.500000) scale(-1, 1) rotate(-360.000000) translate(-20.500000, -20.500000) ">
                      <polygon
                        transform="translate(20.200000, 20.800000) rotate(-90.000000) translate(-20.200000, -20.800000) "
                        points="26.8930731 15.6 25.9993609 16.51 29.5742097 20.15 8.4 20.15 8.4 21.45 29.5742097 21.45 25.9993609 25.09 26.8930731 26 32 20.8"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}
    </button>
  )
)

export default Button
