const cdcMapLegendHorizontal = {
  tickInterval: 20,
  min: 0,
  max: 100,
  stops: [
    [0, '#fff'],
    [0.1, '#c1d3ec'],
    [0.16, '#98b5df'],
    [0.32, '#6f97d2'],
    [0.48, '#467ac5'],
    [0.64, '#2c60ab'],
    [0.8, '#224b85'],
    [1, '#19365f'],
  ],
  labels: {
    format: '{value}' + '%',
  },
  // reversed: true,
  itemWidth: 100,
}

export default cdcMapLegendHorizontal
