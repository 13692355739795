import React from 'react'

import SearchPopoverStateItems from './SearchPopoverStateItems'
// import SearchPopoverMetroItems from './SearchPopoverMetroItems'
import SearchPopoverMsaItems from './SearchPopoverMsaItems'
// import SearchPopoverCountyItems from './SearchPopoverCountyItems'
// import SearchPopoverHrrItems from './SearchPopoverHrrItems'

import styles from './SearchPopover.module.scss'

const SearchPopover = React.forwardRef(
  (
    {
      criteria,
      onAdd,
      searchText,
      visible: isVisible,
      entity,
      activeMetros,
      showCounties,
      showHrrs,
      searchPopOverHeight,
      overflow,
    },
    ref
  ) => {
    return (
      <>
        <ul
          className={`${styles.base} ${isVisible ? styles.open : ''}`}
          ref={ref}
          style={{
            overflowY: overflow ? 'scroll' : 'hidden',
            height: searchPopOverHeight,
          }}
        >
          <SearchPopoverStateItems
            criteria={criteria}
            onAdd={onAdd}
            searchText={searchText}
            visible={isVisible}
            entity={entity}
          />
          <SearchPopoverMsaItems
            criteria={criteria}
            onAdd={onAdd}
            searchText={searchText}
            visible={isVisible}
            entity={entity}
            activeMetros={activeMetros}
          />
          {/* {entity === 'covid-19' ? (
            <SearchPopoverMsaItems
              criteria={criteria}
              onAdd={onAdd}
              searchText={searchText}
              visible={isVisible}
              entity={entity}
              activeMetros={activeMetros}
            />
          ) : (
            <SearchPopoverMetroItems
              criteria={criteria}
              onAdd={onAdd}
              searchText={searchText}
              visible={isVisible}
              entity={entity}
              activeMetros={activeMetros}
            />
          )} */}

          {/* {showCounties && (
            <SearchPopoverCountyItems
              criteria={criteria}
              onAdd={onAdd}
              searchText={searchText}
              visible={isVisible}
              entity={entity}
            />
          )} */}
          {/* 
          {showHrrs && (
            <SearchPopoverHrrItems
              criteria={criteria}
              onAdd={onAdd}
              searchText={searchText}
              visible={isVisible}
              entity={entity}
            />
          )} */}
        </ul>
      </>
    )
  }
)
export default SearchPopover
