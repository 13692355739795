const colorAxis = {
  dataClasses: [
    {
      to: 0,
      color: '#D9D8D8',
    },
    {
      from: 1,
      to: 10,
      color: '#CCDAFF',
    },
    {
      from: 10.01,
      to: 20,
      color: '#B5C4EC',
    },
    {
      from: 20.01,
      to: 30,
      color: '#9EAFDA',
    },
    {
      from: 30.01,
      to: 40,
      color: '#879BC8',
    },
    {
      from: 40.01,
      to: 50,
      color: '#7086B6',
    },
    {
      from: 50.01,
      to: 60,
      color: '#5973A4',
    },
    {
      from: 60.01,
      to: 70,
      color: '#426093',
    },
    {
      from: 70.01,
      to: 80,
      color: '#284D82',
    },
    {
      from: 80.01,
      to: 90,
      color: '#003C71',
    },
    {
      from: 90.01,
      to: 100,
      color: '#03234F',
    },
  ],
}
export default colorAxis
