import React from "react";
import Label from "../Label";
import TextBox from "../TextBox";
import { WindowResize } from "../WindowEvent";
import CheckBox from "./CheckBox";
import ItemsLoadingState from "./ItemsLoadingState";
import SearchClearButton from "./SearchClearButton";
import {
  initialState,
  reducer,
  RESET_STATE,
  SEARCH_ITEMS,
  TOGGLE_ITEM,
  CLEAR_SEARCH
} from "./stateHelper";

import styles from "./SelectInput.module.scss";

const SelectInput = ({
  customSelectedText,
  errorText,
  id,
  items = [],
  label,
  loading: isLoading = false,
  multiple: isMultiSelect = false,
  onChange,
  required: isRequired = false,
  value,
  ...rest
}) => {
  const inputRef = React.useRef();
  const popoverRef = React.useRef();
  const searchRef = React.useRef();

  const [state, dispatch] = React.useReducer(
    reducer,
    initialState(items, value)
  );
  const getSelectedTextByValue = value => {
    let selectedItem;
    if (state.isMultiTier) {
      for (let item of state.items) {
        const match = item.children.find(child => child.value === value);
        if (match) {
          selectedItem = match;
          break;
        }
      }
    } else {
      selectedItem = state.items.find(item => item.value === value);
    }

    return selectedItem.text;
  };
  const getSelectedText =
    customSelectedText ||
    (values => {
      if (values.length === 0) return "None selected";
      if (values.length === 1) return getSelectedTextByValue(state.values[0]);
      return `${values.length} selected`;
    });

  // Watch for items change; signals reset
  const prevItems = React.useRef(items);
  React.useEffect(() => {
    if (prevItems.current !== items) {
      prevItems.current = items;
      dispatch({
        type: RESET_STATE,
        items,
        value
      });
    }
  }, [items, value]);

  // Handle value onChange event
  const prevStateValue = React.useRef(state.values);
  React.useEffect(() => {
    if (prevStateValue.current !== state.values) {
      prevStateValue.current = state.values;
      if (isMultiSelect) {
        onChange(state.values);
      } else {
        onChange(state.values.length === 1 ? state.values[0] : "");
      }
    }
  }, [isMultiSelect, onChange, state.values]);

  const [isVisible, setVisible] = React.useState(false);

  // Set popover position
  const [popoverStyle, setPopoverStyle] = React.useState(null);
  const updatePopoverPosition = React.useCallback(() => {
    if (popoverRef.current === null) return;

    const maxWidth = window.innerWidth;
    const { left } = inputRef.current.getBoundingClientRect();
    const { width } = popoverRef.current.getBoundingClientRect();
    const rightAlign = left + width > maxWidth;

    setPopoverStyle(rightAlign ? { left: "auto", right: 0 } : null);
  }, [popoverRef]);

  const className = [
    styles.base,
    rest.className ? rest.className : "",
    isMultiSelect ? styles.multiSelect : "",
    state.isMultiTier ? styles.multiTier : ""
  ].join(" ");

  return (
    <div className={className}>
      {typeof label === "string" ? (
        <Label
          error={Boolean(errorText)}
          htmlFor={id}
          onClick={() => inputRef.current.focus()}
          required={isRequired}
        >
          {label}
        </Label>
      ) : (
        label
      )}
      <button
        onClick={() => {
          updatePopoverPosition();
          setVisible(true);
          setTimeout(() => {
            if (searchRef.current) {
              searchRef.current.focus();
            } else {
              popoverRef.current.focus();
            }
          }, 50);
        }}
        ref={inputRef}
      >
        {getSelectedText(state.values)}
      </button>
      <WindowResize handler={updatePopoverPosition} invokeImmediately>
        <div
          className={`${styles.popover} ${isVisible ? styles.open : ""}`}
          onBlur={({ relatedTarget }) => {
            if (!popoverRef.current.contains(relatedTarget)) {
              setVisible(false);
            }
          }}
          ref={popoverRef}
          style={popoverStyle}
          tabIndex={-1}
        >
          <ItemsLoadingState loading={isLoading}>
            <div className={styles.search}>
              <div>
                <TextBox
                  className={styles.searchBox}
                  onChange={({ target }) => {
                    dispatch({ type: SEARCH_ITEMS, searchText: target.value });
                  }}
                  placeholder="Search"
                  ref={searchRef}
                  value={state.searchText}
                />
                {state.searchText && (
                  <SearchClearButton
                    onClick={() => {
                      dispatch({ type: CLEAR_SEARCH });
                      searchRef.current.focus();
                    }}
                  />
                )}
              </div>
            </div>
            <div className={styles.items}>
              {state.searchText && state.searchItems.length === 0 && (
                <div className={styles.noMatch}>No matching items</div>
              )}
              {((!state.searchText && state.items.length > 0) ||
                (state.searchText && state.searchItems.length > 0)) && (
                <ul>
                  {(state.searchItems.length > 0
                    ? state.searchItems
                    : state.items
                  ).map(item => (
                    <li key={item.text}>
                      {item.children.length > 0 && !isMultiSelect ? (
                        <span>{item.text}</span>
                      ) : (
                        <button
                          onClick={() => {
                            dispatch({
                              type: TOGGLE_ITEM,
                              isMultiSelect,
                              item
                            });
                          }}
                          onKeyDown={event => {
                            if (
                              event.key === "ArrowDown" &&
                              item.children.length > 0
                            ) {
                              event.preventDefault();
                              event.target.nextElementSibling
                                .querySelector("button")
                                .focus();
                            }
                          }}
                        >
                          <CheckBox {...item} />
                          {item.text}
                        </button>
                      )}
                      {item.children.length > 0 && (
                        <ul>
                          {item.children.map(child => (
                            <li key={child.text}>
                              <button
                                onClick={() => {
                                  dispatch({
                                    type: TOGGLE_ITEM,
                                    isMultiSelect,
                                    item: child
                                  });
                                }}
                                onKeyDown={event => {
                                  const next = event.key === "ArrowDown";
                                  const prev = event.key === "ArrowUp";

                                  if (!next && !prev) return;

                                  event.preventDefault();

                                  const target =
                                    event.target.parentElement[
                                      next
                                        ? "nextElementSibling"
                                        : "previousElementSibling"
                                    ];
                                  if (target) {
                                    target.firstChild.focus();
                                  }
                                }}
                                tabIndex={isMultiSelect ? -1 : 0}
                              >
                                <CheckBox {...child} />
                                {child.text}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ItemsLoadingState>
          <div onFocus={() => inputRef.current.focus()} tabIndex={0} />
        </div>
      </WindowResize>
      {errorText && <span className={styles.errorText}>{errorText}</span>}
    </div>
  );
};

export default SelectInput;
