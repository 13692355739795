import React from 'react'
import styles from './DashboardHeader.module.scss'

const DashboardHeader = () => {
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>Disease Prediction</h4>
        {/* <p>
          Flu Vaccinations administered, sourced from Pharmacy and Medical
          Claims Data.
        </p> */}
      </div>
    </div>
  )
}

export default DashboardHeader
