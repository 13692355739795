import React, { useState } from 'react'

import Button from '../../../components/Button'

import styles from './Email.module.scss'

const Email = ({ email, disease }) => {
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const sendEmailApiUrl = `${clientConfigApiDomain}/${disease}/sendEmails`

  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [emailSent, setEmailSent] = useState(0)

  const SendEmails = () => {
    setIsLoading(true)
    fetch(sendEmailApiUrl, { method: 'post' })
      .then((response) => {
        if (!response.ok) throw Error('Sent email failed')

        response.json().then((responseBody) => {
          setEmailSent(responseBody.emailsSent)
        })

        setIsLoading(false)
        setHasLoaded(true)
      })
      .catch(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
  }

  if (email.body === null) {
    return (
      <div className={styles.base}>
        <p>Please subscribe to a few areas to see this email generated.</p>
      </div>
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div className={styles.content}>
          <Button
            primary
            showArrow
            onClick={() => {
              SendEmails()
            }}
            disabled={isLoading}
          >
            Send {disease} emails
          </Button>
          <div>{isLoading && <span>Sending Emails...</span>}</div>
          <div>
            {hasLoaded && (
              <span>
                {JSON.stringify(emailSent, null, 2)} {disease} emails sent.
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <div>To:</div>
        <div className={styles.to}>{email.to}</div>
      </div>
      <div>
        <div>Subject:</div>
        <div className={styles.subject}>{email.subject}</div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: email.body,
        }}
        className={styles.body}
      ></div>
    </div>
  )
}

export default Email
