const legend = {
  title: {
    text: '',
  },
  enabled: false,
  verticalAlign: 'top',
  valueDecimals: 0,
  backgroundColor: '#fff',
  symbolRadius: 0,
  borderWidth: 0,
  layout: 'vertical',
  align: 'left',
  floating: true,
  width: 20,
  symbolHeight: 100,
  symbolWidth: 20,
}

export default legend
