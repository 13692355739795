import moment from 'moment'

const toolTipFormatter = (ordinate, xAxisLable) => {
  let toolTipHTML = ''
  if (xAxisLable === 'epiWeek') {
    toolTipHTML = `<h6 style="background-color: #000;font-weight:bold; color: #fff; padding: 5px; margin-bottom: 0px;">
        Epi Week : ${ordinate.x}</h4>`
  } else {
    const date = moment(ordinate.x).format('MMM D, YYYY')
    toolTipHTML = `<h4 style="background-color: #000; color: #fff; padding: 5px; margin-bottom: 0px;">
        ${date}</h4>`
  }

  ordinate.points.forEach((point) => {
    toolTipHTML += `<span style="padding: 5px;font-weight:bold">${point.series.name.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 0,
      }
    )}</span>`
    toolTipHTML += `<span style="border-radius:50%;
    background-color:${point.color}; 
    display: inline-block;
    width: 10px;
    height: 10px"></span>&nbsp;`
    if ('high' in point.point) {
      toolTipHTML += `<span style="padding-right:5px"> : ${point.point.low.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
        }
      )} - ${point.point.high.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</span><br/>`
      // toolTipHTML += `<span style="padding: 2px;">${point.point.low.toLocaleString(
      //   undefined,
      //   {
      //     minimumFractionDigits: 0,
      //   }
      // )} - ${point.point.high.toLocaleString(undefined, {
      //   minimumFractionDigits: 0,
      // })}</span>`
    } else {
      toolTipHTML += `<span> ${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</span><br/>`
      // toolTipHTML += `<span style="padding: 2px;">${point.y.toLocaleString(
      //   undefined,
      //   {
      //     minimumFractionDigits: 0,
      //   }
      // )}</span>`
    }
  })

  return toolTipHTML
}

function epiweekToolTip() {
  return toolTipFormatter(this, 'epiWeek')
}

function dateToolTip() {
  return toolTipFormatter(this)
}

export { epiweekToolTip, dateToolTip }
