import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'
import getColumnChartOptions from './getColumnChartOptions'
import styles from './ColumnChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const BarChartDisplay = ({ data, chartName,renderType }) => {
  
  let categories = []
  let graph_female_data = []
  let graph_male_data = []
  const finalData = []
  if (chartName === 'genderAgechart') {
    let res = data //JSON.parse(data)
    res.forEach((data_val) => {
      categories.push(data_val.age_bucket_display)
      graph_female_data.push(data_val.female_Count)
      graph_male_data.push(data_val.male_Count)
    })
    finalData.push({ name: 'category', data: categories })
    finalData.push({ name: 'Male', data: graph_male_data })
    finalData.push({ name: 'Female', data: graph_female_data })
  }
  if(chartName==='genderAgechartSurv')
  {
    data.sort(
      function(a,b){ 
        return a.age_bucket_id-b.age_bucket_id; 
      }
    );
    let res = data //JSON.parse(data)
    res.forEach((data_val) => {
      categories.push(data_val.age_bucket_display)
      graph_female_data.push(data_val.female_count)
      graph_male_data.push(data_val.male_count)
    })
    finalData.push({ name: 'category', data: categories })
    finalData.push({ name: 'Male', data: graph_male_data })
    finalData.push({ name: 'Female', data: graph_female_data })
  }
  if (chartName === 'columnBarChart') {
    let res = data //JSON.parse(data)
    res.forEach((data_val) => {
      categories.push(data_val.age_bucket_display)

      graph_male_data.push(data_val.male_count)
    })
    finalData.push({ name: 'category', data: categories })
    finalData.push({ name: 'Male', data: graph_male_data })
    finalData.push({ name: 'Female', data: graph_female_data })
  }

  //dummy data
  const chartOptions = getColumnChartOptions(finalData,renderType,chartName)

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              fontFamily: 'Optum Sans',
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default BarChartDisplay
