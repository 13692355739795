const infectionTypeOptions = {
  FLU: {
    value: 'FLU',
    label: 'FLU',
    regionsOptions: [
      {
        value: 'STATE',
        label: 'State',
      },
      {
        value: 'CBSA',
        label: 'Metro Area',
      },
    ],
  },
  'COVID-19': {
    value: 'COVID-19',
    label: 'COVID-19',
    regionsOptions: [
      // TO-DO showing big list like COUNTY is causing performance issue need to find optimized way to show COUNTY
      // {
      //   value: 'COUNTY',
      //   label: 'COUNTY',
      // },
      {
        value: 'STATE',
        label: 'State',
      },
      {
        value: 'CBSA',
        label: 'Metro Area',
      },
    ],
  },
}

export default infectionTypeOptions
