import React from 'react'
import styles from './DisplayDateProvider.module.scss'
import moment from 'moment'

export const DisplayDateProviderNew = ({ showWeek, lastweekEndDate }) => {
  const lastweekEndWeek = moment(lastweekEndDate, 'yyyy-MM-DD').week()

  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component

  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        <p className={styles.displayDateMargin}>
          Forecast for Epiweek {showWeek === true && lastweekEndWeek}:{'  '}
          {moment(lastweekEndDate).format('MMM DD, YYYY')}
        </p>
      </div>
    </div>
  )
}

export default DisplayDateProviderNew
