import statesMap from '../../utils/stateMap'
import cbsasMap from '../../utils/cbsasMap'
import countyMap from '../../utils/countyMap'
import Highcharts from 'highcharts'
const getAreaChartOptions = (data, identifier, type, cbsaJson, countyJson) => {
  let displayName = ''
  let displayType = type

  switch (type) {
    case 'state':
      displayType = 'State'
      displayName = statesMap()[identifier]
      break
    case 'metro':
    case 'city':
      displayType = 'Metro'
      displayName = cbsasMap(cbsaJson)[identifier]
      break
    case 'county':
      displayType = 'County'
      displayName = countyMap(countyJson)[identifier]
      break
    default:
    // code block
  }
  return {
    title: {
      text:
        'Accuracy Report Comparison for ' + displayType + ' : ' + displayName, //undefined,
    },

    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
      labels: {
        formatter: function () {
          //return Highcharts.dateFormat('%b %Y', this.value - 86400000) //addedf
          return Highcharts.dateFormat('%b %e', this.value - 86400000)  //added
        },

      },
    },

    yAxis: {
      title: {
        text: 'Flu2',
      },
      min: 0,
    },

    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: null,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: data,
  }
}
export default getAreaChartOptions
