import React from "react";
import BackToHomeLink from "../BackToHomeLink";
import { SUPPORT_EMAIL } from "../../utils/constants";
import Content from "../Content";

const PageNotFound = () => (
  <Content>
    <h1>404: Page Not Found</h1>
    <p>
      The page you requested is not available. If you see this message again,
      contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
    </p>
    <p>
      <BackToHomeLink />
    </p>
  </Content>
);

export default PageNotFound;
