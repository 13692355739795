import React, { useState } from 'react'
import LinkButton from '../../../components/LinkButton'
import styles from './SideTable.module.scss'
import DropdownMenu from './DropdownMenu'
import NewWindow from 'react-new-window'
import { useGeoState } from '../../../components/GeoProvider'
import ReactSwitch from 'react-switch'
const Databind = ({
  state,
  chooseMessage,
  redirecttoGoogleMap,
  chartRef,
  showProvidersMap,
}) => {
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart

    if (showProvidersMap && chart) {
      const point = chart.series[1].points.find(
        (point) =>
          point.location_code + ':' + point.originating_business ===
          element.getAttribute('title')
      )
      point && chart.tooltip.refresh(point)
    } else {
      chooseMessage(state)
    }
  }

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (showProvidersMap && chart && chart.tooltip) {
      chart.tooltip.destroy()
    } else {
      chooseMessage('')
    }
  }
  if (state !== null && state !== undefined) {
    let notAv = state.originating_business === 'Name Not Available'
    return (
      <div style={{ display: 'flex' }} className={styles.Layout}>
        <div
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex' }}>
            <LinkButton
              className={styles.button}
              data-title={state.building_code}
              title={state.location_code + ':' + state.originating_business}
              onMouseOver={(e) => handleMouseOver(e.target)}
              onMouseOut={() => handleMouseOut()}
              onClick={() => redirecttoGoogleMap(state)}
            >
              {notAv !== true
                ? state.location_code + ': ' + state.originating_business
                : state.location_code + ': '}
            </LinkButton>
            <span className={styles.notAvailable}>
              {notAv && '(' + state.originating_business + ')'}
            </span>
          </div>

          <span className={styles.filter}>
            {' '}
            {state.primary_location_type_formated}{' '}
            {state.primary_location_type_formated !== 'Other'
              ? state.primary_location_sub_type_formated
              : ''}
          </span>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ display: 'flex' }} className={styles.Layout}>
        Providers not available for the selected MSA
      </div>
    )
  }
}

const SideTableProvider = ({
  dropdownData,
  stateData,
  chooseMessage,
  chartRef,
}) => {
  const [getOpen, setOpen] = useState(false)
  const [getlatitude, setlatitude] = useState(false)
  const [getlongitude, setlongitude] = useState(false)
  const newWindowUnloaded = () => {
    setOpen(false)
    setlatitude()
    setlongitude()
  }
  const redirecttoGoogleMap = (data) => {
    setOpen(true)
    setlatitude(data.latitude)
    setlongitude(data.longitude)
  }

  const stateDataForDisplayDate = Object.entries(stateData)
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].building_code // state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })
  const { showProvidersMap, setShowProvidersMap } = useGeoState()

  const handleChange = (val) => {
    setShowProvidersMap(val)
  }

  let filteredStateData = mappedStateData

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',

            paddingBottom: '16px',
            paddingTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '30%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '20.25px',
                    lineHeight: '24px',
                    color: '#002677',
                  }}
                >
                  CDOs
                </h5>
              </span>
            </div>

            <div
              style={{
                width: '70%',
                marginTop: '-7px',
                height: '10%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <DropdownMenu dropdownData={dropdownData} />
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '35%',
              justifyContent: 'flex-start',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <h4>Location</h4>
          </div>
          <div
            style={{
              display: 'flex',
              width: '65%',
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <span className={styles.showOnMap}>Show on Map</span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showProvidersMap}
              onChange={handleChange}
            />
          </div>
        </div>
        {getOpen && (
          <NewWindow
            url={`https://maps.google.com/?q=${getlatitude},${getlongitude}`}
            features={{ left: 200, top: 200, width: 800, height: 600 }}
            center="parent"
            onUnload={() => newWindowUnloaded()}
            title="Infectious Disease Platform"
            name="Infectious Disease Platform"
          />
        )}
      </div>

      <div className={styles.scroll}>
        {filteredStateData.length === 0 ? (
          <Databind
            state={null}
            key={null}
            chooseMessage={chooseMessage}
            redirecttoGoogleMap={redirecttoGoogleMap}
            chartRef={chartRef}
            showProvidersMap={showProvidersMap}
          />
        ) : (
          filteredStateData.map((state) => {
            return (
              <Databind
                state={state}
                key={state.location_code}
                chooseMessage={chooseMessage}
                redirecttoGoogleMap={redirecttoGoogleMap}
                chartRef={chartRef}
                showProvidersMap={showProvidersMap}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

export default SideTableProvider
