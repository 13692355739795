import React, { useState, useEffect } from 'react'
import MapLegend from './mapLegend'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import EmptyMap from '../../../../components/Map/emptyMap'
import Map from '../../../../components/Map'
import toolTipFormatter from './toolTipFormatter'
const FluMsaComponent = ({ stateJson, cbsaJson,  states, modelOptions, activeCbsasData, returnErrormetric, fluMetrocombinedata }) => {
    const {
        dataStartDate,
        dataEndDate,
        mapOptions,
        regionSelected,
        diseaseSelected,        
    } = useGeoState()
    const [metros, setMetros] = useState()
    //const [fluActiveCBSA, setFluActiveCBSA] = useState()
    const [errorCode, setErrorCode] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubject, setErrorSubject] = useState()
    const [errorOccured, seterrorOccured] = useState(false)
    const [hrrDataResponse, setHRRsData] = useState()
   //const [fluMetroCombined, setFluMetroCombined] = useState()
    const entity = 'flu'
    const { data: cbsaData, error: cbsaDataError } = useGeoFetch(
        dataStartDate,
        dataEndDate,
        entity,
        'errormetric/msa?model=' + modelOptions[0].value
    )
    useEffect(() => {
        // activityLog(logObject)
        if (cbsaData !== undefined || cbsaData === null) {
            if (cbsaData.response_code === 200) {
                setMetros(cbsaData.response_data)
                returnErrormetric(cbsaData.response_data)
            } else {
                setErrorCode(cbsaData.response_code)
                setErrorMessage(cbsaData.response_message)
                setErrorSubject(cbsaData.response_subject)
                seterrorOccured(true)
            }
        }
    }, [cbsaData])
// metro data for right side graph view
const { data: metrosCombinedData, error: metrosCombinedDataError } =
useGeoFetch(
  dataStartDate,
  dataEndDate,
  entity,
  'accuracyreport/msa?model=' + modelOptions[0].value
)
useEffect(() => {
if (metrosCombinedData !== undefined || metrosCombinedData === null) {
  if (metrosCombinedData.response_code === 200) {
    fluMetrocombinedata(metrosCombinedData.response_data)
  } else {
    setErrorCode(metrosCombinedData.response_code)
    setErrorMessage(metrosCombinedData.response_message)
    setErrorSubject(metrosCombinedData.response_subject)
    seterrorOccured(true)
  }
}
}, [metrosCombinedData])
    return (
        <>
            {regionSelected === 'msa' && diseaseSelected === 'flu' && (
                <>
                    <MapLegend />
                    {metros !== undefined && activeCbsasData !== undefined && cbsaJson ? (
                        <Map
                            toolTipFormatter={toolTipFormatter}
                            activeCbsasData={activeCbsasData}
                            statesData={states}
                            cbsaData={metros}
                            mapOptions={mapOptions}
                            dynamicMapTitle={true}
                            mapType="CIACCURACY"
                            enableButtons={true}
                            stateJson={stateJson}
                            cbsaJson={cbsaJson}
                        />
                    ) : (
                        <EmptyMap />
                    )}
                </>
            )}

        </>
    )
}

export default FluMsaComponent