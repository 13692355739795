import React, {useState } from 'react'
import moment from 'moment'
import styles from './FluIndices.module.scss'
import Content from '../../components/ContentPharma'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate, displayDate,actualsStartDate,forecastsStartDate } from './dates'
import Header from './Header'
import Gender from './GenderAge'
import TrendChart from './TrendChart'

const CovidPharma = () => {
  const [actualStartDate, setActualStartDate] = useState(actualsStartDate)
  const [forecastStartDate, setForecastStartDate] = useState(forecastsStartDate)
  return (
    <Layout>
      <Content style={{  }}>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
            >
              <Header headerType="NAT" headerNumber={1} />
              <DashboardMap />
              <Gender geo_type="NAT" geo_id="NAT" data_type="COVID" />
              
              <TrendChart actualStartDate={actualStartDate}
                        setActualStartDate={setActualStartDate}
                        forecastStartDate={forecastStartDate}
                        setForecastStartDate={setForecastStartDate}
                        time={'-1'} geo_type="NAT" geo_id="NAT" data_type="COVID">
                        </TrendChart>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default CovidPharma
