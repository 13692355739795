import React, { useState, useEffect } from 'react'

import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import HRRDetailedView from './HRRDetailedView'
const HrrErrorMetricsComponent = ({getJsonData,  hash }) => {    
    const {     
        regionSelected,
        setRegionSelected,
        diseaseSelected,       
        setHrrEndDate,
    } = useGeoState()
    const { user } = useAuthState()
    const [errorCode, setErrorCode] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubject, setErrorSubject] = useState()
    const [errorOccured, seterrorOccured] = useState(false)
    const [hrrDataResponse, setHRRsData] = useState()
    const [hrrErrData, setErrHRRsData] = useState()
   
    const { data: errHrrData, error: errHrrDataError } = useGeoFetch(
        '',
        '',
        'covid',
        'hrr/errormetric'
      )
      useEffect(() => {
        if (errHrrData !== undefined || errHrrData === null) {
          if (errHrrData.response_code === 200) {
            setErrHRRsData(errHrrData.response_data)
          } else {
            setErrorCode(errHrrData.response_code)
            setErrorMessage(errHrrData.response_message)
            setErrorSubject(errHrrData.response_subject)
            seterrorOccured(true)
          }
        }
      }, [errHrrData])



    return (
        <>
            <HRRDetailedView hrrMapJson={getJsonData} hash={hash} hrrData={hrrErrData} />
        </>
    )
}

export default HrrErrorMetricsComponent