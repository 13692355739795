import React from 'react'
import Highcharts, { chart } from 'highcharts'
import Chart from '../../../components/Highcharts/Chart'
import styles from './HeaderTab.module.scss'
import getLineChartOptions from '../../../components/LineChart/lineChartOptions'

const ComparisonChart = ( {chartData,selectedSeasons,selectedDisease,selectDiseaseValueComma,weeks}) => {

let season_arr=selectedSeasons.split(',')
let disease_arr=selectedDisease.split(',')
let disease_label_arr=selectDiseaseValueComma.split(',')

//const weeks=[40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
const currentWeekIndex=null
 const currentWeek=null
const SETINTERVAL=true

let chartColors=['#15A796','#C72887','#8061BC','#E4780C','#1E82CB']

let linecharttest=[]
function getSeasonData(chartData){
    let seasonarr=[]
   for(let j=0;j<season_arr.length;j++)
   {
    for(let i=0;i<chartData.length;i++)
    {
       
        if(chartData[i].season===season_arr[j])
        {
        let chartJson=JSON.parse(chartData[i].json_value)
        //sorting json based on week ending
        chartJson.sort(
          function(a,b){ 
            return Date.parse(new Date(a.week_ending)) - Date.parse(new Date(b.week_ending)); 
          }
        );
        chartJson.map((chartval)=>{
            let obj={
                "y": parseFloat(chartval.data_value),
                "marker": {
                    "enabled": true
                },
                "week_ending": chartval.week_ending
            }
            
            if(weeks.includes(chartval.epiweek))
            seasonarr.push(obj)
        })
        let obj1={
            name: chartData[i].season,
            data:seasonarr,
            dashStyle: 'solid',
            color: chartColors[i],
            visible: true,
            showInLegend: true,
          }
          seasonarr=[]
          linecharttest.push(obj1)
        } 
    }
    }
    return []
}  
function getDiseaseData(chartData){
    let diseasearr=[]
   for(let j=0;j<disease_arr.length;j++)
   {
    for(let i=0;i<chartData.length;i++)
    {
        if(chartData[i].disease_id.toString()===disease_arr[j])
        {
            
        let chartJson=JSON.parse(chartData[i].json_value)
        //sorting json based on week ending
        chartJson.sort(
          function(a,b){ 
            return Date.parse(new Date(a.week_ending)) - Date.parse(new Date(b.week_ending)); 
          }
        );
        chartJson.map((chartval)=>{
           
            let obj={
                "y": parseFloat(chartval.data_value),
                "marker": {
                    "enabled": true
                },
                "week_ending": chartval.week_ending
            }
            
            if(weeks.includes(chartval.epiweek))
            diseasearr.push(obj)
        })
       
        let obj1={
            name: disease_label_arr[i],
            data:diseasearr,
            dashStyle: 'solid',
            color:  chartColors[i],
            visible: true,
            showInLegend: true,
          }
          diseasearr=[]
          linecharttest.push(obj1)
        }
        
    }
    
    }
    return []
}
    const lineChartData = [
        
        {
          name: '2023-2024',
          data:season_arr.length>1?getSeasonData(chartData):getDiseaseData(chartData),
          dashStyle: 'solid',
          color: Highcharts.getOptions().colors[9],
          visible: true,
          showInLegend: true,
        },
      ]
      const lineOptions = getLineChartOptions(
        linecharttest,
        weeks,
        currentWeekIndex,
        currentWeek,
        '',
        SETINTERVAL,
        'SURV'
      )
      
      return (
        lineOptions && (
          <div className={styles.chartDiv}>
            <div className={styles.container}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Chart
                  chartOptions={lineOptions}
                  height={'350px'}
                />
              </div>
              
            </div>
          </div>
        )
      )
    }
    
    export default ComparisonChart
  