import React from 'react'

import styles from './CheckBox.module.scss'

const CheckBox = ({
  indeterminate: isIndeterminate,
  label,
  value,
  selected: isSelected,
  onChange,
  readOnly,
  ...rest
}) => {
  return (
    <label className={styles.base}>
      <input
        type="checkbox"
        checked={isSelected}
        {...rest}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
      {label && <span>{label}</span>}
    </label>
  )
}

export default CheckBox
