import { LoginType, MsalAuthProvider } from 'react-aad-msal'

function authProvider(id) {
  
  // var id = uuid()
  // const baseApiUri = process.env.REACT_APP_BACKEND_BASE_URL
  //  fetch(`${baseApiUri}/msid/oauth2/log-request`, {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     id: id,     
  //   }),
  //   headers: {
  //     Accept: 'application/json',
  //   },
  //   credentials: 'include',
  // })

  // Msal Configurations 
  const config = {
    auth: {
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}/`,
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      postLogoutRedirectUri: `${process.env.REACT_APP_UI_HOME}`,
      // redirectUri: window.location.origin,
      redirectUri: `${process.env.REACT_APP_UI_HOME}msid/login-redirect`,
      validateAuthority: true,     
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    // system: {
    //   logger: new Logger(
    //     (logLevel, message, containsPii) => {
    //     },
    //     {
    //       level: LogLevel.Verbose,
    //       piiLoggingEnabled: false,
    //     }
    //   ),
    // },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  }

  // Authentication Parameters
  const authenticationParameters = {
    scopes: ['user.read'],
     state: id,
  }

  // Options
  const options = {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: `${window.location.origin}/auth.html`,
  }

  return new MsalAuthProvider(config, authenticationParameters, options)
}

export default authProvider
