import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../components/DisplayDatePharma/DisplayDateFluNew'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/MapPharma/emptyMap'
import Timeline from '../../components/TimelinePharma'
import Map from '../../components/MapPharma'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './cdcMapLegendVertical'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
const DashboardMap = ({ zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [getStats, setStats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  // const statesData=covidmap
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covidMap',
    'STATE/?geo_parent=US', true
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  // const stats=covidstats
  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'covidStats',
    'US/', true
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  useEffect(() => {
    let url = `${process.env.REACT_APP_UI_HOME}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  //stateDataError || MapError ||
  if ( errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable stateData={getStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined && getStateJson ? (
              <>
               <div className={`${styles.providerHeader}`}>
               <DisplayDateFluNew
                    showWeek={false}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'COVID'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={null}
                  />
               </div>
                <MapLegend />
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAP"
                  enableButtons={true}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="COVID"
                />

                {/* <div className={`${styles.timelinePanel}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Covid'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div> */}
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
