import { useHistory } from 'react-router-dom'
// move this to another file
import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import { useGeoState } from '../../../../components/GeoProvider'
import Spinner from '../../../../components/Loader/Spinner'
import Icon from '../../../../components/Icon'
import moment from 'moment'
import hrrsMap from '../../../../utils/hrrsMap'
import { epiweekToolTip } from './toolTipFormatterFlu'
import getEpiWeek from '../../../../utils/getEpiWeek'
import styles from './FluDetailedView.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}
const DetailedViewDisplay = ({ type, identifier, hrrData }) => {
  const { dataSelected } = useGeoState()
  let dataObj = []

  //var resultData = JSON.parse(hrrData)   
  var resultData = hrrData.results

  Object.entries(resultData).forEach(function (dateObj) {
    const key = dateObj[0]
    const value = dateObj[1][identifier]
    dataObj.push({ date: key, ...value })
  })

  let revised = [],
    unrevised = [],
    pointforecast = [],
    percent95 = [],
    percentOverall = [],
    weeks = []

  for (var i = 1; i <= 2; i++) {
    var per95 = dataObj.map((d) => {
      return d[i] === undefined ? ['NA', 'NA'] : [d[i].low_80, d[i].high_80]
    })
    var rev = dataObj.map((d) => {
      return d[i] === undefined ? ['NA'] : [d[i].revised_value]
    })
    // }

    percent95.push(per95)
    revised.push(rev)

    per95 = null
    rev = null
  }
  dataObj.map((d) => {
    let epiweek = getEpiWeek(moment(d['2'].start_date))
    weeks.push(epiweek)
  })
  let data = []

  const chartOptions = {
    title: {
      text: undefined,
    },
    xAxis: {
      //  type: 'datetime',
      // dataSorting: {
      //   enabled: true,
      // },
      // labels: {
      //   formatter: function () {
      //     // Subtract 1 day from the actual date in this.value to show the x-axis compatible with the data on the trend
      //     return Highcharts.dateFormat('%b %e', this.value - 86400000)
      //   },
      // },
      categories: weeks,
      title: {
        text: 'Epiweek',
      },
    },
    yAxis: {
      title: {
        text: '% Hospitalization Intensity',
      },
      min: 0,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: epiweekToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Hospital admissions',
        data: revised[1],
        zIndex: 1,
        color: '#1192E8',
      },
      {
        name: 'Horizon 1',
        data: percent95[0],
        zIndex: 1,
        color: '#a4d4ff7a',
        type: 'arearange',
        lineWidth: 0,
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
      {
        name: 'Horizon 2',
        data: percent95[1],
        color: '#c7baee',
        type: 'arearange',
        lineWidth: 0,
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
      // {
      //   name: 'Overall',
      //   data: percent95[2],
      //   type: 'arearange',
      //   lineWidth: 0,
      //   color: '#dfe2e4',
      //   fillOpacity: 0.25,
      //   zIndex: 0,
      //   showInLegend: true,
      //   marker: {
      //     enabled: false,
      //   },
      // },
    ],
  }
  return (
    <>
      <div>
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
      {/* <AccuracyTable data={data} /> */}
    </>
  )
}

const CovidDetailedView = ({ hrrMapJson, hrrData, hash }) => {  
  const history = useHistory()
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]
  let name = ''
  let displayType = type

  switch (type) {
    case 'hrr':
      name = hrrsMap(hrrMapJson)[identifier]
      displayType = 'hrr'
    default:
    // code block
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h5>{name}</h5>
        </div>
        <button
          onClick={() => {
            history.push(`/about/model#accuracy`)
          }}
        >
          <Icon type="remove" size={'1rem'} />
        </button>
      </div>
      <div className={styles.body}>
        {type === 'hrr' &&
        hrrData !== undefined &&
        Object.keys(hrrData).length >= 1 ? (
          <DetailedViewDisplay
            hrrData={hrrData}
            hash={hash}
            type={type}
            identifier={identifier}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default CovidDetailedView
