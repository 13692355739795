import React from 'react'
import Spinner from '../Loader/Spinner'
import HorizontalBarChartDisplay from './HorizontalBarChartDisplay'

const HorizontalBarChartCustom = ({  
  toolTipFormatter,
  dataDisplayOptions,
  chartName,
  height,  
  data,
}) => {  
  return data ? (
    <HorizontalBarChartDisplay
      toolTipFormatter={toolTipFormatter}           
      data={data}
      dataDisplayOptions={dataDisplayOptions}
      chartName={chartName}
      height={height}
    />
  ) : (
    <Spinner />
  )
}

export default HorizontalBarChartCustom
