import dayjs from 'dayjs'

export const outbreak = '2020-01-01'
export const startDateTrend = dayjs().add(-60, 'day').format('YYYY-MM-DD')
export const endDateTrend = dayjs().add(28, 'day').format('YYYY-MM-DD')
//export const displayDate = dayjs().add(0, 'day').format('YYYY-MM-DD')

export const startDate = (dayjs().$y - 1).toString() + '-10-02'
export const endDate = dayjs().day(0).add(182, 'day').format('YYYY-MM-DD') //someFutureSunday
export const displayDate = dayjs().day(0).add(6, 'day').format('YYYY-MM-DD')
export const displayWeekStartDate = dayjs().day(0).format('YYYY-MM-DD')
export const hrrdate = dayjs().day(-15).format('YYYY-MM-DD')
export const hrrenddate = dayjs().day(12).format('YYYY-MM-DD')
export const displaydatehrr = dayjs().day(-2).format('YYYY-MM-DD')

// export const startDateCounties = dayjs().add(-30, 'day').format('YYYY-MM-DD')
// export const endDateCounties = dayjs().add(14, 'day').format('YYYY-MM-DD')

export const startDateCounties = (dayjs().$y - 1).toString() + '-10-02' //Added
export const endDateCounties = dayjs().add(182, 'day').format('YYYY-MM-DD')

export const startDateHrr = dayjs().add(-365, 'day').format('YYYY-MM-DD')
export const endDateHrr = dayjs().add(28, 'day').format('YYYY-MM-DD')
