import _capitalize from 'lodash/capitalize'

const errorMessageHandler = (error) => {
  const errorArray = []
  for (let i in error) {
    if (Array.isArray(error[i])) {
      for (let j of error[i])
        errorArray.push(`Error in ${_capitalize(i)}: ${_capitalize(j)}`)
    } else errorArray.push(`Error in ${_capitalize(i)}: ${error[i]}`)
  }
  return errorArray
}

export default errorMessageHandler
