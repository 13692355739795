export function toolTipFormatterWeeklyAdmissions() {
  if (this.point.hospital_admissions <= 0.1) {
    return noDataToolTip(this.point)
  } else {
    return toolTipFormatter(this.point, this.color)
  }
}

function noDataToolTip(point) {
  const cbsaName = point.cbsa === undefined ? '' : point.name
  const hrrName =
    point.HRRNUM === undefined
      ? ''
      : point.geo_name + ' ' + point.geo_parent_code
  const stateName = point.parent === 'US' ? point.name : ''
  const countyName =
    point.code === undefined ? '' : point.name + ', ' + point.parent
  if (hrrName !== '') {
    return ` <h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px">${
      //This condition need to be change or check again when we get the MSA/CBSA data
      `${hrrName}`
    }</h4>
      <table style="padding: 5px 5px 5px 5px; width: 100%;">
      <tr>
          <td style="text-align: center;
          vertical-align: middle;">No recent data available for ${
            //This condition need to be change or check again when we get the MSA/CBSA data
            `${hrrName}`
          }. </th>
          
      </tr>`
  } else {
    return ` <h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px">${
      //This condition need to be change or check again when we get the MSA/CBSA data
      `${stateName} ${cbsaName} ${countyName} ${hrrName}`
    }</h4>
      <table style="padding: 5px 5px 5px 5px; width: 100%;">
      <tr>
          <td style="text-align: center;
          vertical-align: middle;">No recent data available for ${
            //This condition need to be change or check again when we get the MSA/CBSA data
            `${stateName} ${cbsaName} ${countyName} ${hrrName}`
          }. </th>
          
      </tr>`
  }
}

function toolTipFormatter(point, color) {
  // const cbsaName = point.cbsa === undefined ? '' : point.name
  const hrrName =
    point.HRRNUM === undefined
      ? ''
      : point.geo_name + ', ' + point.geo_parent_code
  // const stateName = point.parent === 'US' ? point.name : ''
  // const countyName =
  //   point.code === undefined ? '' : point.name + ', ' + point.parent
  if (hrrName !== '') {
    // return `<div style="width:100%;background-color:#FFFFFF;padding:10px;border-radius:4px;">
    // <div style="width:90%;opacity: 0.94;
    // background-color:#FFFFFF;
    // color:#424242;padding-left:10px;
    // padding-top:10px;padding-right:5px;font-size:14px;
    // display:inline;">${hrrName.toUpperCase()} HOSPITAL ADMISSIONS </div>

    // <div style="padding-top:10px;display: inline-block;padding-right:10px;">
    // <span style="border-radius:50%;
    // background-color:${color};
    // display: inline-block;
    // width: 15px;
    // height: 15px"></span>&nbsp;
    // </div>

    // <div style="width:90%;padding-left: 10px;margin-top:5px">
    // <span style="color:#424242;
    //             font-weight:bold;font-size:18px;padding-right:10px;"> ${point.hospital_admissions.toLocaleString(
    //               undefined,
    //               {
    //                 minimumFractionDigits: 0,
    //                 maximumFractionDigits: 0,
    //               }
    //             )}</span></div>
    // </div>`

    return `<div style="width:110%;background-color:transparent;">
    <div style="width:90%;
    background-color:transparent;
    color:#7d7d7d;padding-left:10px;
    padding-top:10px;padding-right:5px;
    display:inline;">${hrrName.toUpperCase()} HOSPITAL ADMISSIONS</div>
    <div style="padding-top:10px;display: inline-block;padding-right:10px;">
    <span style="border-radius:50%;
    background-color:${color}; 
    display: inline-block;
    width: 10px;
    height: 10px"></span>&nbsp;
    </div>
    
    <div style="width:90%;padding-left: 10px;">
    <span style="color:black;
                font-weight:bold;font-size:14px;padding-right:10px;">${point.hospital_admissions.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}</span></div>
    </div>`
  } else {
    return ``
  }
}
