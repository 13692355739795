import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GeoProvider from '../../components/GeoProvider'
import FluGeo from './FluGeo'
import * as htmlToImage from 'html-to-image';

// import FluGrid from './FluGrid'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate, displayDate } from './dates'
import { useAuthState } from '../../components/AuthProvider';
import activityLog  from '../../utils/activityLog'
const FluTrend = () => {

  const { user } = useAuthState();


   var logObject={page_name: window.location.pathname , dashboard_type : 'O', indicator_type :'indicator_type', trend_type:'trend_type', state_name:'state_name', metro_area:'metro_area', user_name:user.username, email:user.email};
   activityLog(logObject);

  const history = useHistory()
  const location = useLocation()
  const disease='flumeter' 
  
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const saveImageApiUrl = `${clientConfigApiDomain}/${disease}/saveimage`  
  
  const SaveImage = () => {
    htmlToImage.toPng(document.getElementById('root'))
    .then(function (imageData) {     
      var urlName = window.location.hash;
      var ImageName='';
    if (urlName.indexOf('metro')>-1)
    {
      var Code=window.location.hash.split('-')[1];
      //ImageName=urlName.replace('#','') + '.png';
      //ImageName="metro_forecast_trend_" + Code +".png";
      ImageName="city_forecast_trend_" + Code +".png";
    }
    if (urlName.indexOf('state')>-1)
    {
      var Code=window.location.hash.split('-')[1];
      //ImageName=urlName.replace('#','') + '.png';
      ImageName="state_forecast_trend_" + Code +".png";

    }

      var getImgObject={name: ImageName , base64Content: imageData};
      fetch(saveImageApiUrl, { method: 'post' , body: JSON.stringify(getImgObject)})
      .then((response) => {
        if (!response.ok) throw Error('Upload failed')
        response.json().then((responseBody) => {          
        })
      })
      .catch(() => {
        console.log('Catch Error');       
      })
    });
  }
  return (
    <div >
    <GeoProvider
      startDate={startDate}
      endDate={endDate}
      displayDate={displayDate}
      mapOptions={dataDisplayOptions[0]['options'][0]}
    >
      <FluGeo />
    </GeoProvider>
    <button id="btnSaveImage" style={{border:'none',color:'white',backgroundColor:'white'}}  onClick={() => {
              SaveImage()
            }}
      >
     .
      </button>
</div>
  )
}

export default FluTrend
