import { React, useState } from 'react'
import styles from './HeaderTab.module.scss'
import { useGeoState } from '../../../components/GeoProvider'
import Select from 'react-select'
import { components } from 'react-select'
import LeftArrowArrows from '../../../assets/images/icon/arrow-icon-left.png'
import {  FormField, Label } from '@better-ui/react'
const HeaderTitle = ({seasonData }) => {
const {
  setIsChecked,
  diseaseSelectedSurv,
  setRegionSelected,
  setSeasonSelected,
  setDiseaseSelectedSurv,
  showUHCCoverage,
  setSearchFilter}=useGeoState()
const dataSeasons = seasonData.map((pos) => {
  return {
    value: pos.data_id,
    label: pos.data_value,
  }
})


const dataRegion = [
  {
    value: 'STATE',
    label: 'State',
  },
  {
    value: 'MSA',
    label: 'State & MSA',
  },
  {
    value: 'ZIP3',
    label: 'Zip3',
  },
]
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #4B4D4F',
    boxSizing: 'border-box',
    cursor: 'pointer',
  }),
  option: (base, state) => ({
    ...base,
    color: '#4b4d4f',
    backgroundColor: 'white',
    padding: '.5rem 3rem .5rem .5rem',
    cursor: 'pointer',
    fontWeight: state.isSelected ? 'bold' : '',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#002677',
      color: 'white',
      borderRadius: '100px',
      cursor: 'pointer',
      padding: '0px 0px 0px 5px',
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    backgroundColor: '#002677',
    borderRadius: '100px',
    width: '32px',

    '&:hover': {
      backgroundColor: '#002677',
      color: 'white',
      borderRadius: '100px',
    },
  }),
}
const [selectedOptions, setSelectedOptions] = useState([dataSeasons[0]])
const [selectedLRegion, setSelectedLRegion] = useState([dataRegion[0]])
function tabsChangeHandlerNew(e) {
    setSelectedOptions(e)
    setSeasonSelected(e.label)
    setIsChecked(true)
    setSearchFilter('')
  
}
function changeHandlerRegion(e) {
  setRegionSelected(e.value)
  setSelectedLRegion(e)
  setIsChecked(false)
  setSearchFilter('')
}



//// Start here
const Option = (props) => {  

  return (
    <div>
      <components.Option {...props}>
        <FormField >
          <Label > {props.label}</Label>
        </FormField>
      </components.Option>
    </div>
  )

}
////End here 

      return (
     
      <div className={styles.pageHeader}>
        {showUHCCoverage?<div className={styles.pageHeaderTextUHC}>
           
           <h5><span>UHC Coverage</span> </h5>
           
         </div>:
         <>
         <div className={styles.pageHeaderText}>
           <div className={diseaseSelectedSurv!=='all'?styles.iconFrame:styles.iconFrameHide}  
           onClick={() => {setDiseaseSelectedSurv('all')}}>
              <img
                src={LeftArrowArrows}
                alt="arrow-down"
                //style={{ height: '30px', width: '30px', marginBottom: '-3px' }}
              ></img>
              
              </div>
           <h5><span>{diseaseSelectedSurv==='all'?'Surveillance':diseaseSelectedSurv.toUpperCase()}</span> </h5>
           
         </div>
         <div className={styles.dataFilter}>
     
     <div className={styles.filterOption}>
     <span >Seasons</span>
     <Select
           
           options={dataSeasons}
           onChange={tabsChangeHandlerNew}
           value={selectedOptions}
           placeholder={`Select Season`}
           styles={colourStyles}
           components={{
             Option,
           }}
           hideSelectedOptions={false}
           closeMenuOnSelect={true}
         />
     </div>
     <div className={styles.filterOption}>
     <span >Region</span>
     <Select
           options={dataRegion}
           onChange={changeHandlerRegion}
           value={selectedLRegion}
           placeholder={`Select Region`}
           styles={colourStyles}
           components={{
             Option,
           }}
           hideSelectedOptions={false}
           closeMenuOnSelect={true}
         />
     </div>
   
 
     
     </div>
         </>}
           
       
       
        {/* <div className={styles.dataExport}>
          <span className={styles.button}>Export</span>
        </div> */}
        </div>
      
      )
    }
    
    export default HeaderTitle
  