import {
  SET_CREATE_SESSION_FLAG,
  SET_ERROR,
  SET_REDIRECT_ROUTE,
  SET_USER,
  SET_AUTH_MODE,
} from './authActions'

const authReducer = (state, action) => {
  switch (action.type) {
    case SET_REDIRECT_ROUTE:
      return {
        ...state,
        redirectRoute: action.route,
      }
    case SET_USER:
      return {
        ...state,
        user: action.user,
        isAuthenticated: action.user !== null,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case SET_CREATE_SESSION_FLAG:
      return {
        ...state,
        isCreatingSession: action.isCreatingSession,
      }
    case SET_AUTH_MODE:
      return {
        ...state,
        authMode: action.authMode,
      }
    default:
      throw Error(`Action of type ${action.type} is not supported`)
  }
}

export default authReducer
