import React, { useState, useEffect } from 'react'
import styles from './FluIndices.module.scss'
import getVectorImage from '../../utils/getVectorImage'
import { useGeoFetch } from '../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from './typeDisplayOptions'
import SelectList from '../../components/SelectList'
import DefaultBreadcrumbs from '../../components/Breadcrumbs/DefaultBreadcrumbsPharma'
import Search from '../../components/SearchPharma'
const HeaderDisplay = ({ headerType }) => {
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
//   const headerdata={
//     "response_code": 200,
//     "response_message": "Success",
//     "response_subject": "Successful",
//     "response_data": {
//         "geo_id": "US",
//         "statename": "National",
//         "istrue": false,
//         "percentage": "0.8",
//         "datavalue": "0.479"
//     }
// }
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'covidHeader',
    headerType,true
  )
  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        if (
          headerdata.response_data !== null &&
          headerdata.response_data !== undefined
        ) {
          // setHeaderData(headerdata.response_data)
          setgeoid(headerdata.response_data.geo_id)
          setistrue(headerdata.response_data.istrue)
          setPercentage(headerdata.response_data.percentage)
          setDatavalue(headerdata.response_data.datavalue)
        }
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if ( errorOccured) {
    return (
      <div>
        {errorSubject !== undefined
          ? errorSubject
          : 'Unable to get the data for header'}{' '}
        : {errorSubject}
      </div>
    )
  }
  return (
    <>
      <span className={styles.firstSpan}> {getgeoid}</span>
      <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
      <span className={getistrue ? styles.stdatavalueup : styles.datavaluedown}>
        {' '}
        {getPercentage}%{' '}
      </span>
      <span className={styles.forthSpan}> ({getDatavalue})</span>
    </>
  )
}

const Header = ({ headerType, headerNumber }) => {
  const [searchCriteria, setSearchCriteria] = React.useState([])
  const [activeCbsa, setActiveCbsa] = useState()

  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/COVID?geo_type=MSA', true
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  return (
    <>
      
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          {/* <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'COVID'}
          /> */}
          <h5>COVID Incidence</h5>
          {/* <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'covid'}
            defaultLabel={'COVID Incidence'}
          /> */}
        </div>
        <div className={styles.pageHeaderTextValue}>
          <HeaderDisplay
            headerType={headerType}
            dashNo={headerNumber}
          ></HeaderDisplay>
          <div className={styles.pageHeaderSearch}>
            {activeCbsa !== undefined && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={'Covid'}
                activeMetros={activeCbsa}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
