import React from 'react'
import AsyncSelect from 'react-select/async'

import Label from '../../../../components/Label'

import 'react-datepicker/dist/react-datepicker.css'

const UserSelect = ({ value, onChange, onBlur, error, touched, setUsers }) => {
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const userApiUrl = `${baseUrl}/admin`
  let data
  const handleChange = (value) => {
    onChange('username', value)
    onChange('email', value['email'] ? value['email'] : '')
    setUsers(data)
  }

  const handleBlur = () => {
    onBlur('username', true)
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
      width: '98%',
      borderColor: '#999999',
    }),
  }

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 2) {
      const response = await fetch(`${userApiUrl}/userinfo/${inputValue}`)
      data = await response.json()
      const options = data.map((d) => {
        return {
          value: d.username,
          label: `${d.username} (${d.fullName}) `,
          email: d.email,
        }
      })
      callback(options)
    }
  }

  return (
    <div style={{ paddingBottom: '.5rem' }}>
      <Label error={touched && error} htmlFor="username">
        Select User
      </Label>
      <AsyncSelect
        placeholder="Search User"
        isMulti={false}
        loadOptions={loadOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        styles={colourStyles}
      />
      {!!error && touched && (
        <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
      )}
    </div>
  )
}

export default UserSelect
