import React from 'react'
import styles from './TopGraphPanel.module.scss'
import BarChart from '../../components/BarChart'
import LineChart from '../../components/LineChart'
import toolTipFormatter from './toolTipFormatter'
import dataDisplayOptions from './dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import { useGeoState } from '../../components/GeoProvider'
const TopGraphPanel = ({getfilterts}) => {
  const { vulCount } = useGeoState()
  return (
    <div className={styles.base}>
      <div className={styles.genderPanel}>
        <span>
          Gender and Age Distribution
          <span>
            Patients Count: <strong>{vulCount}</strong>
          </span>
        </span>
        <BarChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="DataAgeGender"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart1"
          filterId={getfilterts}
          fluFolder='DataAgeGender'
          fluseason ={getfilterts.season}
          month ={getfilterts.month}
          lob ={getfilterts.lob}
          type={getfilterts.type	}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
      <div className={styles.vaccinePanel}>
        <span>
          Vaccine administered
          <span>
            Patients Count: <strong>{vulCount}</strong>
          </span>
        </span>
        <LineChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="DataMonthWise"
          locationDisplay="State"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          fluFolder='DataMonthWise'
          fluseason ={getfilterts.season}
          month ={getfilterts.month}
          lob ={getfilterts.lob}
          type={getfilterts.type	}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
    </div>
  )
}

export default TopGraphPanel
