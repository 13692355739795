import React from "react";
import Icon from "../Icon";
import styles from "./Message.module.scss";

const Message = ({ children = null, type, onDismiss }) => (
  <div className={`${styles.base} ${styles[type]}`}>
    {children}
    {onDismiss && (
      <button onClick={onDismiss}>
        <Icon type="remove" />
      </button>
    )}
  </div>
);

export default Message;
