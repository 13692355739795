import React from "react";
import CheckBox from "../CheckBox";
import styles from "./ColumnSelectorCheckbox.module.scss";

const ColumnSelectorCheckbox = ({ columns, isHidden }) => (
  <div
    className={`${styles.base} ${styles.columnSelectorDiv} ${
      isHidden ? styles.expandTable : ""
    }`}
  >
    {columns
      .filter(({ id }) => id !== "actions")
      .map(column => (
        <div key={column.id}>
          <CheckBox label={column.Header} {...column.getToggleHiddenProps()} />
        </div>
      ))}
  </div>
);

export default ColumnSelectorCheckbox;
