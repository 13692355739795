import React, { useEffect } from 'react'
import Slider from 'rc-slider'

import { useGeoState } from '../GeoProvider'

import spreadOutObject from '../../utils/spreadOutObject'

import styles from './Timeline.module.scss'
import 'rc-slider/assets/index.css'
import '../../styles/colors.scss'

const TimelineSlider = ({
  days,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  curIndex,
  setCurIndex,
  relativeSlots = true,
}) => {
  const {
    loadedDate,
    UHCStateLoadedDate,
    UHCCountyLoadedDate,
    displayDate,
    setDisplayDate,
    setDisplayWeek,
    setTimelineIsPaused,
    mapOptions,
  } = useGeoState()

  const getPositionDate = (value) => {    
    switch (mapOptions['parent']) {
      case 'UHC Indicators':
        if (value === 'state' || value === 'metro') {
          return UHCStateLoadedDate
        } else {
          return UHCCountyLoadedDate
        }
      default:
        //return loadedDate
        return displayDate
    }
  }
  const maxDayIndex = days.length - 1

  const timelineIndexMarksDisplay = (cur) => {
    return `${slotString} ${cur.epi_period_week}`
  }

  const timelineRelativeIndexMarksDisplay = (displayDate) => {
    // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component
    const timeSpan =
      (new Date(displayDate) - new Date(loadedDate)) / slotTimeInSeconds

    // TODO: can break this into a common function for the display date and this component to use!
    if (timeSpan === 0) {
      return latestPositionString
    } else {
      let sign = ''
      if (Math.abs(timeSpan) === timeSpan) {
        sign = '+'
      } else {
        sign = ''
      }

      let plural = ''
      if (Math.abs(timeSpan) > 1) plural = 's'

      //return `${sign}${timeSpan} ${slotString}${plural}`
      return `${displayDate}`
    }
  }

  const indexWithDate = days.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.index]: relativeSlots
        ? timelineRelativeIndexMarksDisplay(cur.date)
        : timelineIndexMarksDisplay(cur),
    }),
    {}
  )

  const date = getPositionDate()
  const loadedDay = days.filter((w) => w.date === date)[0]
  const marks = spreadOutObject(
    indexWithDate,
    markCounts,
    loadedDay.index,
    latestPositionString
  )

  function handleTimelineChange(value) {
    setCurIndex(value)
    setDisplayDate(days[value].date)
    setDisplayWeek(days[value].epi_period_week)
  }

  useEffect(() => {
    const day = days.filter((w) => w.date === displayDate)[0]
    setCurIndex(day.index)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayDate])

  useEffect(() => {
    setDisplayWeek(days[curIndex].epi_period_week)
    setDisplayDate(days[curIndex].date)
    if (curIndex >= maxDayIndex) {
      setTimelineIsPaused(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curIndex])

  return (
    <Slider
      className={styles.rcSlider}
      min={0}
      max={maxDayIndex}
      step={1}
      marks={marks}
      value={curIndex}
      onChange={handleTimelineChange}
      railStyle={{ height: 10 }}
      trackStyle={{ backgroundColor: '#003C71', height: 10 }}
      handleStyle={{
        borderColor: '#316bbe',
        height: 16,
        width: 16,
        marginLeft: 3,
        marginTop: -3,
        ':active': {
          boxShadow: '0 0 5px #316bbe',
        },
      }}
      dotStyle={{ height: 14, width: 14, bottom: -8 }}
      activeDotStyle={{ borderColor: '#316bbe' }}
    />
  )
}

export default TimelineSlider
