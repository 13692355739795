import React from 'react'
import { useHistory } from 'react-router-dom'

import { useGeoState } from '../GeoProvider'
import RadioButton from '../RadioButtonList'
import DropDownList from '../DropDownList'
import { useAuthState } from '../AuthProvider'
import styles from './AccuracyMapOptions.module.scss'
import getRegionDisplayOptions from '../../pages/Covid-19/Covid19Map/regionDisplayOptions'
import getRegionDisplayOptionsHrr from '../../pages/Forecasts/HRR/regionDisplayOptions'

const AccuracyMapOptions = ({
  regionDisplayOptions,
  dataDisplayOptions,
  diseaseTypeOptions,
}) => {
  const {
    setMapOptions,
    dataSelected,
    setDataSelected,
    regionSelected,
    setRegionSelected,
    diseaseSelected,
    setDiseaseSelected,
    mapOptions,
  } = useGeoState()

  const history = useHistory()
  const { user } = useAuthState()
  let displayOptions = dataDisplayOptions.filter(
    (option) =>
      !option['allowedRoles'] || option['allowedRoles'].includes(user.role)
  )

  if (diseaseSelected === 'covid') {
    //regionDisplayOptions = getRegionDisplayOptions(mapOptions['parent'])
    regionDisplayOptions = getRegionDisplayOptions('Covid Incidence')
  }

  if (diseaseSelected === 'covid' && dataSelected === 'hospital_admissions') {
    regionDisplayOptions = getRegionDisplayOptionsHrr('Covid Hospitalization')
  }
  return (
    <div className={styles.base}>
      <div className={styles.selectorDiv1}>
        <div>
          <p
            style={{
              marginBottom: '5px',
              fontWeight: 500,
              fontFamily: 'graphik',
              fontSize: '14px',
              color: '#424242',
            }}
          >
            SHOW ME{'  '}
          </p>
          <DropDownList
            id="diseases"
            name="diseases"
            onChange={(e) => {              
              history.push({ hash: null })
              setDiseaseSelected(e.target.value)
              if (e.target.value === 'covid') {
                setRegionSelected('state')
                setDataSelected('revised_accuracy')
                setMapOptions(displayOptions[0]['options'][0])
              } else {
                setRegionSelected('state')
                setDataSelected('revised_accuracy')
                setMapOptions(displayOptions[0]['options'][0])
              }
            }}
            className={styles.dropDowns}
            // defaultValue={regionSelected}
          >
            {diseaseTypeOptions.map((disease, regionsIndex) => {
              return (
                <DropDownList.Item
                  value={disease.value}
                  key={disease.value}
                  selected={diseaseSelected === disease.value}
                >
                  {disease.label}
                </DropDownList.Item>
              )
            })}
          </DropDownList>
        </div>
      </div>

      <>
        {diseaseSelected !== 'covid' ? (
          <div className={styles.selectorDiv3}>
            {/* <p style={{ marginBottom: '5px' }}>Include</p> */}
            {displayOptions.map((option) => {
              return option['options'].map((data) => {
                return (
                  <RadioButton
                    label={data.label}
                    value={data.value}
                    id={data.value}
                    name={option.label}
                    selected={dataSelected === data.value ? 'checked' : ''}
                    onChange={(e) => {                      
                      let value = []
                      displayOptions.forEach((options) => {
                        value = [
                          ...value,
                          ...options['options'].filter(
                            (o) => o.value === e.target.value
                          ),
                        ]
                      })
                      if (dataSelected !== data.value) {
                        // setDisplayDate(getDisplayDate(value[0]))
                        history.push({ hash: null })
                        setMapOptions(value[0])
                        setDataSelected(e.target.value)
                      }
                    }}
                  ></RadioButton>
                )
              })
            })}
          </div>
        ) : (
          <div className={styles.selectorDiv3}>
            {displayOptions.map((option) => {              
              return option['optionscovid'].map((data) => {
                return (
                  <RadioButton
                    label={data.label}
                    value={data.value}
                    id={data.value}
                    name={option.label}
                    selected={dataSelected === data.value ? 'checked' : ''}
                    onChange={(e) => {
                      
                      let value = []
                      displayOptions.forEach((options) => {
                        value = [
                          ...value,
                          ...options['optionscovid'].filter(
                            (o) => o.value === e.target.value
                          ),
                        ]
                      })
                      if (dataSelected !== data.value) {
                        // setDisplayDate(getDisplayDate(value[0]))
                        history.push({ hash: null })
                        setMapOptions(value[0])
                        setDataSelected(e.target.value)
                        if (e.target.value === 'hospital_admissions') {
                          setRegionSelected('hrr')
                        } else {
                          if (regionSelected === 'hrr') {
                            setRegionSelected('state')
                          } else {
                            setRegionSelected(regionSelected)
                          }
                        }
                      }
                    }}
                  ></RadioButton>
                )
              })
            })}
          </div>
        )}
      </>
      <div className={styles.selectorDiv2}>
        {' '}
        <div>
          <p
            style={{
              marginBottom: '5px',
              fontFamily: 'graphik',
              fontSize: '14px',
              color: '#424242',
              fontWeight: 500,
            }}
          >
            {' '}
            DISPLAY {'  '}{' '}
          </p>

          <>
            {diseaseSelected === 'covid' ? (
              <DropDownList
                id="regions"
                name="regions"
                onChange={(e) => {
                  history.push({ hash: null })
                  setRegionSelected(e.target.value)
                }}
                className={styles.dropDowns}
                defaultValue={regionSelected}
              >
                {regionDisplayOptions.map((regions, regionsIndex) => {
                  return (
                    <DropDownList.Item
                      value={regions.value}
                      key={regions.value}
                      selected={regionSelected === regions.value}
                    >
                      {regions.label}
                    </DropDownList.Item>
                  )
                })}
              </DropDownList>
            ) : (
              <DropDownList
                id="regions"
                name="regions"
                onChange={(e) => {
                  history.push({ hash: null })
                  setRegionSelected(e.target.value)
                }}
                className={styles.dropDowns}
                defaultValue={regionSelected}
              >
                {regionDisplayOptions.map((regions, regionsIndex) => {
                  return (
                    <DropDownList.Item
                      value={regions.value}
                      key={regions.value}
                      selected={regionSelected === regions.value}
                    >
                      {regions.label}
                    </DropDownList.Item>
                  )
                })}
              </DropDownList>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default AccuracyMapOptions
