import React from "react";

const SearchClearButton = props => (
  <button {...props}>
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M8,0 C12.418278,-8.116245e-16 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 5.41083001e-16,12.418278 0,8 C-5.41083001e-16,3.581722 3.581722,8.116245e-16 8,0 Z M9.15709627,7.99993547 L12.0943312,5.06317856 C12.4129943,4.74451544 12.4129943,4.22468082 12.0943504,3.90603689 C11.7751363,3.58682282 11.255053,3.58682282 10.9363893,3.90635954 L7.99993547,6.842813 L5.06315938,3.90603689 C4.74476429,3.58676904 4.22443197,3.58676904 3.90571467,3.90635954 C3.58676904,4.22443197 3.58676904,4.74476429 3.90635954,5.06348159 L6.842813,7.99993547 L3.90603689,10.9367115 C3.58687659,11.2558718 3.58687659,11.7751901 3.90603689,12.0943504 C4.22468082,12.4129943 4.74451544,12.4129943 5.06315938,12.0943504 L7.99993547,9.15757433 L10.9367115,12.0943504 C11.2553017,12.4129406 11.7748877,12.4129406 12.0940277,12.0946726 C12.4128869,11.7749416 12.4128869,11.2561203 12.0946726,10.9370342 L9.15709627,7.99993547 Z" />
    </svg>
  </button>
);

export default SearchClearButton;
