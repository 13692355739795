import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import { useHistory } from 'react-router-dom'
import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import getMapOptions from './getMapOptions'
import MapBordercolor from '../../utils/MapBordercolor'
import usStateDummy from './dummyStats'
import moment from 'moment'
function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}
const MapPharma = ({
  toolTipFormatter,
  activeCbsasData,
  statesData,
  cbsaData,
  mapOptions,
  showStateLabels,
  showCbsaLabels,
  enableTooltip,
  enableMapNavigation,
  enableMouseTracking,
  mapHeight = '70vh',
  mapWidth = '',
  mapTitleDisable,
  mapType,
  enableButtons = false,
  zoomLocation = '',
  chartRef,
  mapDataType,
  JsonData,
  stateJson,
  dashboardType,
  zip3Json,
  zip3Data,
  activeZip3Data,
  posTrue=false,
  enableMouseZoom
}) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }
  const { displayDate,diseaseSelectedSurv,regionSelected } = useGeoState()

  const history = useHistory()
  let mapActiveCbsaData = null
  let mapStatesData = null
  let mapCbsaData = null
  let mapMsaData = null
  let statesDataForDisplayDate=null
  let zip3DataFiltered=null
  let mapActiveZIP3Data=null
  let survColorAxis=null
  function getIntervalThreshold(min,max,num)
  {
    let diff=max-min
    let diffValue=diff/5

    return parseFloat(((diffValue*num)+min).toFixed(3));
  }
  function getRangeFromThreshold(min,max,num)
  {
    let diff=max-min
    let diffValue=diff/5
    let finalValue=((diffValue*num)+min)
    let rtValue=finalValue<.001?finalValue:finalValue+.001
    return parseFloat(rtValue.toFixed(3));
  }
  function getSurvColorAxis(dataSet)
  {
  let minData=Math.min(...dataSet.map(member => member[1].data_value))
  let maxData=Math.max(...dataSet.map(member => member[1].data_value))
    let cAxis={
      dataClasses: [
        {
          to: 0,
          color: '#6D6F70',
          name: 'No Data',
        },
        {
          from: 0.001,
          to: getIntervalThreshold(minData,maxData,1),
          color: '#E5FCF6',
           plotBorderColor: '#2B9181',
          borderWidth: 5,
          name: getIntervalThreshold(minData,maxData,1)
          ,
        },
        {
          from: getRangeFromThreshold(minData,maxData,1),
          to: getIntervalThreshold(minData,maxData,2),
          color: '#ACCAE4',
          plotBorderColor: '#2D6EA3',
          borderWidth: 1,
          name: getIntervalThreshold(minData,maxData,2),
        },
        {
          from: getRangeFromThreshold(minData,maxData,2),
          to: getIntervalThreshold(minData,maxData,3),
          color: '#7B99CD',
          plotBorderColor: '#0E456B',
          borderWidth: 1,
          name: getIntervalThreshold(minData,maxData,3),
        },
        {
          from: getRangeFromThreshold(minData,maxData,3),
          to: getIntervalThreshold(minData,maxData,4),
          color: '#5768B0',
          plotBorderColor: '#FFFFFF',
          borderWidth: 1,
          name: getIntervalThreshold(minData,maxData,4),
        },
        {
          from: getRangeFromThreshold(minData,maxData,4),
          color: '#48338C',
          plotBorderColor: '#FFFFFF',
          borderWidth: 1,
          name: getIntervalThreshold(minData,maxData,5),
        },
      ],
    }
    let cAxisNoData={dataClasses: [
      {
        to: 0,
        color: '#6D6F70',
        name: 'No Data',
      }]}
    return (minData===0&& maxData===0)?cAxisNoData:cAxis
  }
  function getDynamicColorLegend(posId,diseaseId,dashType,region)
  {
 
    if(dashType==='SURV')
    {
      return true
      // if((diseaseId.toUpperCase()==='FLU'))
      // {
      //   if(posId.toString()!=="0")
      //   {
      //     return true
      //   }
      //   else{
      //     return false
      //   }
      // }
      // else if((
      // diseaseId.toUpperCase()==='COVID-19' || 
      // diseaseId.toUpperCase()==='RSV'))
      // {
      //   if(posId.toString()!=="0")
      //   {
      //     return true
      //   }
      //   else{
      //     if(region.toUpperCase()==='ZIP3')
      //     {return true}
      //     else{
      //     return false
      //     }
      //   }
      // }
      // else{
      //   return true
      // }

    }
    else{
      return false
    }

  }
  function getBorderColorSurv(dataSet,data_value){
    let minData=Math.min(...dataSet.map(member => member[1].data_value))
    let maxData=Math.max(...dataSet.map(member => member[1].data_value))
    if(minData===0 && maxData===0)
    {return '#FFFFFF'}
    if(data_value>=.001 && data_value<=getIntervalThreshold(minData,maxData,1))
    {
      return '#2B9181'
    }
    else if(data_value>=getRangeFromThreshold(minData,maxData,1) && data_value<=getIntervalThreshold(minData,maxData,2))
    {
      return '#2D6EA3'
    }
    else if(data_value>=getRangeFromThreshold(minData,maxData,2) && data_value<=getIntervalThreshold(minData,maxData,3))
    {
      return '#0E456B'
    }
    else if(data_value>=getRangeFromThreshold(minData,maxData,3) && data_value<=getIntervalThreshold(minData,maxData,4))
    {
      return '#FFFFFF'
    }
    else if(data_value>=getRangeFromThreshold(minData,maxData,4))
    {
      return '#FFFFFF'
    }
    else
    {
      return '#FFFFFF'
    }
  }
  if (
    mapType === 'RSVMAP' ||
    mapType === 'RSVMAPMSA' ||
    mapType === 'RSVMAPCDO' ||
    mapType === 'ZIP3MAP'
  ) {
    if (mapType==="ZIP3MAP")
    {
      let maxDateStamp = Math.max(
        ...Object.keys(statesData).map((member) => Date.parse(member))
      )
      let lastData=moment(maxDateStamp).utc().format('YYYY-MM-DD')
      if (displayDate > lastData)
      {          
        statesDataForDisplayDate = Object.entries(usStateDummy)
      }
      else
      {
        statesDataForDisplayDate = Object.entries(statesData[displayDate])
      }
    }
    else
    {
      statesDataForDisplayDate = Object.entries(statesData[displayDate])
    }
     
    let dataSet=statesDataForDisplayDate
    survColorAxis=getSurvColorAxis(dataSet)
    mapStatesData = statesDataForDisplayDate.map((state) => {
      if(getDynamicColorLegend(posTrue,diseaseSelectedSurv,dashboardType,regionSelected))
      {
        
        return {
        value:state[1][mapOptions.tagValue],
        state: state[0],
        borderColor:getBorderColorSurv(dataSet,state[1].data_value),
        ...state[1],
      }}
      else{
        return {
        value:state[1][mapOptions.value] ,
        state: state[0],
        borderColor: MapBordercolor(state[1]['forecast_level']),
        ...state[1],
      }}
      
    })
  }
  if (
    mapType === 'ZIP3MAP'  && zip3Data && !isEmptyObject(zip3Data)
  ) {       
     
    const zip3DataForDisplayDate = Object.entries(zip3Data[displayDate])
    let dataSet=zip3DataForDisplayDate
    survColorAxis=getSurvColorAxis(dataSet)
    zip3DataFiltered = zip3DataForDisplayDate.map((state) => {
      if(getDynamicColorLegend(posTrue,diseaseSelectedSurv,dashboardType,regionSelected))
      {
      return {
        value:state[1][mapOptions.tagValue] ,
        borderColor:getBorderColorSurv(dataSet,state[1].data_value),
        state: state[0],       
        ...state[1],
      }
    }
    else{
      return {
        value:state[1][mapOptions.value] ,
        state: state[0],       
        borderColor: MapBordercolor(state[1]['forecast_level'],dashboardType),
        ...state[1],
      }
    }
    })
    mapActiveZIP3Data = activeZip3Data.map((zip3) => {
      return zip3.toString()
    })
  }
  if (
    (mapType === 'RSVMAPMSA' && cbsaData && !isEmptyObject(cbsaData)) ||
    (mapType === 'RSVMAPCDO' && cbsaData && !isEmptyObject(cbsaData))
  ) {
  
    const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])
    let dataSet=cbsaDataForDisplayDate
    survColorAxis=getSurvColorAxis(dataSet)
    mapCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
      if(getDynamicColorLegend(posTrue,diseaseSelectedSurv,dashboardType,regionSelected))
      {
      return {
        value:cbsa[1][mapOptions.tagValue] ,
        borderColor: getBorderColorSurv(dataSet,cbsa[1].data_value),
        state: cbsa[0].toString(),
        ...cbsa[1],
      }
    }
    else{
      return {
        value:cbsa[1][mapOptions.value] ,
        state: cbsa[0].toString(),
        borderColor: MapBordercolor(cbsa[1]['forecast_level']),
        ...cbsa[1],
      }
    }
    })
    mapActiveCbsaData = activeCbsasData.map((cbsa) => {
      return cbsa.toString()
    })
  }

  let title = mapOptions.mapTitle
  if (!(title === null || title === undefined)) {
    title = ''
  }
  
  const graphOptions = getMapOptions(
    getDynamicColorLegend(posTrue,diseaseSelectedSurv,dashboardType,regionSelected)?survColorAxis:mapOptions.colorAxis,
    mapOptions.legend,
    toolTipFormatter,
    mapActiveCbsaData,
    mapCbsaData,
    mapStatesData,
    mapMsaData,
    history,
    mapType,
    enableButtons,
    zoomLocation,
    mapDataType,
    JsonData,
    stateJson,
    dashboardType,
    title,
    mapOptions.mapSubTitle,
    showStateLabels,
    showCbsaLabels,
    enableTooltip,
    enableMapNavigation,
    enableMouseTracking,
    zip3Json,
    zip3DataFiltered,
    mapActiveZIP3Data,
    true,
    enableMouseZoom
  )
  return mapOptions ? (
    <HighchartsReact
      containerProps={{
        style: {
          height: mapHeight,
          width: mapWidth,
          margin:'0 auto',
          position: 'relative',
          display: 'flex',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
      ref={chartRef}
    />
  ) : (
    <Spinner />
  )
}

export default MapPharma
