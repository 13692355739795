import React from "react";
import WindowResize from "./WindowResize";
import WindowScroll from "./WindowScroll";

const WindowEvent = ({
  children = null,
  handler,
  invokeImmediately = false,
  type
}) => {
  const frame = React.useRef(null);
  const callback = React.useCallback(() => {
    if (frame.current !== null) {
      window.cancelAnimationFrame(frame.current);
    }

    frame.current = window.requestAnimationFrame(handler);
  }, [handler]);

  const hasInvoked = React.useRef(false);
  React.useEffect(() => {
    if (!hasInvoked.current && invokeImmediately) {
      callback();
      hasInvoked.current = true;
    }

    window.addEventListener(type, callback);

    return () => window.removeEventListener(type, callback);
  }, [callback, invokeImmediately, type]);

  return children;
};

export { WindowEvent as default, WindowResize, WindowScroll };
