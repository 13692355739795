import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

import toolTipFormatter from './toolTipFormatterCovidTrends'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const CovidHRRCaseTrend = ({
  area,
  startDate,
  displayDate,
  forecastflag,
  actualflag,
  yAxisLabel = false,
  height = '300px',
}) => {
  const casesActual = area
    .filter(
      (d) =>
        // new Date(d.date) <= new Date() &&
        new Date(d.date) >= new Date(startDate) && d.table_type === 'actuals'
    )
    .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
    .map((d) => {
      return [new Date(d.date).getTime(), d.hospital_admissions]
    })

  const casesForecast = area
    .filter(
      (d) =>
        // new Date(d.date) > new Date() &&
        new Date(d.date) >= new Date(startDate) && d.table_type === 'forecast'
    )
    .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
    .map((d) => {
      return [new Date(d.date).getTime(), d.hospital_admissions]
    })
  // to fill the data gape of one week
  casesActual.push(casesForecast[0])
  const percent95 = area
    .filter(
      (d) =>
        // new Date(d.date) > new Date() &&
        new Date(d.date) >= new Date(startDate) && d.table_type === 'forecast'
    )
    .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
    .map((d) => {
      return [new Date(d.date).getTime(), d.forecast_lo_95, d.forecast_hi_95]
    })
  const chartOptions = {
    title: {
      text: undefined,
    },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
      labels: {
        formatter: function () {
          //return Highcharts.dateFormat('%b %e', this.value)
          return Highcharts.dateFormat('%b %e', this.value - 86400000) //added
        },
      },
      plotLines: [
        {
          color: '#cdcdcd',
          width: 1,
          value: new Date(displayDate).getTime(),
          zIndex: 10,
          label: {
            x: -20,
            rotation: 0,
            text: moment(displayDate).format('MMM D'),
            style: { color: 'black' },
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      labels: { enabled: yAxisLabel },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: toolTipFormatter,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Actual Data',
        data: casesActual,
        zIndex: 1,
        color: '#15A796',

        marker: {
          enabled: false,
        },
        visible: actualflag,
      },
      {
        name: 'Forecasted Data',
        data: casesForecast,
        zIndex: 1,
        color: '#15A796',
        dashStyle: 'Dot',
        marker: {
          enabled: false,
        },
        visible: forecastflag,
      },
      {
        name: '95% Confidence level',
        data: percent95,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
        visible: true,
      },
    ],
  }
  return (
    <>
      {/* <h4>Hospitalizations</h4> */}
      <HighchartsReact
        containerProps={{
          style: {
            width: '100%',
            position: 'relative',
            height: height,
          },
        }}
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate
        updateArgs={[true, true, true]}
      />
    </>
  )
}

export default CovidHRRCaseTrend
