import dedupList from '../../utils/dedupeList'
import mapData from '@highcharts/map-collection/countries/us/us-all.geo.json'
import proj4 from 'proj4'
import getThresholdValue from '../../utils/getThresholdValue'
import toolTipFormatter from './toolTipFormatter'
window.proj4 = proj4
function getStateOptions(enableDataLabels) {
  return {
    borderWidth: 1,
    mapData: mapData,
    name: 'US RSV Incidence',
    joinBy: ['hc-a2', 'state'],
    tooltip: { headerFormat: undefined, pointFormat: undefined },
    dataLabels: {
      enabled: enableDataLabels,
      format: '{point.hc-a2}',
    },
  }
}
function getMapOptions(
  colorAxis,
  legend,
  stateData,
  providers,
  providersAll,
  enableButtons,
  zoomLocation,
  showStateLabels = true,
  enableTooltip = true,
  enableMapNavigation = true,
  titleText = null,
  subtitleText = null
) {
  let cbsaList = []
  providers.forEach((feat) => {
    let featObj = feat
    cbsaList.push(featObj.cbsa_id)
  })
  //for all providers
  let filteredProviders = []
  providersAll.forEach((feat) => {
    if (
      feat.latitude !== null &&
      feat.longitude !== null &&
      feat.longitude !== 'N/A' &&
      feat.latitude !== 'N/A'
    ) {
      let featObj1 = feat
      featObj1.lat = parseFloat(feat.latitude)
      featObj1.lon = parseFloat(feat.longitude)
      if (cbsaList.includes(feat.cbsa_id)) {
        featObj1.marker = {
          symbol: 'circle',
          radius: 6,
          lineWidth: 1,
          lineColor: getThresholdValue(feat.forecast_level).OutlineColor,
          fillColor: getThresholdValue(feat.forecast_level).color,
        }
      } else {
        featObj1.marker = {
          symbol: 'circle',
          radius: 3,
          lineWidth: 1,
          lineColor: getThresholdValue(feat.forecast_level).OutlineColor,
          fillColor: getThresholdValue(feat.forecast_level).color,
        }
      }
      filteredProviders.push(featObj1)
    }
  })

  let stateOptions = {}

  if (stateData === null) {
    stateOptions.visible = false // = {}
  } else {
    stateOptions = getStateOptions(showStateLabels)
    stateOptions.visible = true
    stateOptions.data = dedupList(stateData)
  }
  return {
    title: {
      text: titleText,
      style: {
        fontWeight: '700',
        fontsize: '8px',
      },
    },
    subtitle: {
      text: subtitleText,
    },
    chart: {
      map: mapData,
      proj4,
      displayErrors: false,
      events: {
        load: function () {
          let point = this.series[0].points.find(function (p) {
            return p.state === zoomLocation
          })

          setTimeout(function () {
            if (point !== null && point !== undefined) {
              point.state !== null && point.zoomTo()
            }
          }, 1000)
        },
      },
    },

    colorAxis: colorAxis,
    legend: legend,
    series: [
      stateOptions,
      {
        type: 'mappoint',
        joinBy: ['hc-a2', 'state'],
        data: filteredProviders,
        cursor: 'pointer',
      },
    ],
    mapNavigation: {
      enabled: enableMapNavigation,
      enableMouseWheelZoom: false,
      enableButtons: enableButtons,
      buttonOptions: {
        verticalAlign: 'bottom',
        align: 'right',
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#0C55B8',
          backgroundColor: '#FFFFFF',
        },
      },
    },
    tooltip: {
      formatter: toolTipFormatter,
      enabled: enableTooltip,
      borderRadius: 10,
      backgroundColor: 'white',
      borderWidth: 1,
      borderColor: '#CBCCCD',
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
      },
      map: {
        enableMouseTracking: false,
      },
    },
  }
}

export default getMapOptions
