import axios from 'axios'
import useSWR from 'swr'

export default function useRequest(request, { initialData, ...config } = {}) {
  return useSWR(
    request && JSON.stringify(request),
    () => axios(request || {}).then((response) => response.data),
    {
      ...config,
      // refreshInterval: 0,
      // shouldRetryOnError: false,
      dedupingInterval: 1000 * 60 * 60 * 2, // 2hrs nothing is reloaded till refreshed
      focusThrottleInterval: 1000 * 60 * 60 * 2, // 2hrs nothing is reloaded till refreshed
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: initialData,
      },
    }
  )
}
