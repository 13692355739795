import React, { useState, useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'

import Spinner from '../../../../components/Loader/Spinner'
import FluDetailedView from './FluDetailedView'
const FluDetailedViewComponent = ({
  statesData,
  hash,
  stateJson,
  cbsaJson, }) => {
    const {
        dataStartDate,
        dataEndDate,       
      } = useGeoState()
      const entity = 'flu'
    const { user } = useAuthState()
   
    const [getmetrosCombinedData, setmetrosCombinedData] = useState()
    const { data: metrosCombinedData, error: metrosCombinedDataError } =
    useGeoFetch(dataStartDate, dataEndDate, entity, 'msa?seasons=all')

  useEffect(() => {
    if (metrosCombinedData !== null && metrosCombinedData !== undefined) {
      if (metrosCombinedData.response_code === 200) {
        setmetrosCombinedData(metrosCombinedData.response_data)
      } else {
        // setErrorCode(metrosCombinedData.response_code)
        // setErrorMessage(metrosCombinedData.response_message)
        // setErrorSubject(metrosCombinedData.response_subject)
        // seterrorOccured(true)
      }
    }
    //setMetros(cbsaData)
  }, [metrosCombinedData])
    return (
        <>      
        {getmetrosCombinedData !== undefined ? (
        <FluDetailedView
        cbsaData={getmetrosCombinedData}
        statesData={statesData}
        hash={hash}
        stateJson={stateJson}
        cbsaJson={cbsaJson}
      />
        ) : (
         <Spinner/>
        )}
      </>
    )
}

export default FluDetailedViewComponent