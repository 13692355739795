import React from 'react'
import styles from './BottomGraphPanel.module.scss'
import PieChart from '../../components/PieChart'
import HorizontalBarChart from '../../components/HorizontalBarChart'
import toolTipFormatter from './toolTipFormatter'
import dataDisplayOptions from './dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import {  useGeoState } from '../../components/GeoProvider'
import { startDate, endDate } from './dates'

const BottomGraphPanel = ({getfilterts}) => {

  const { vulCount } = useGeoState('')
  return (
    <div className={styles.base}>
      <div className={styles.vaccinePanel}>
        <span>
          Vaccine Rate by Line Of Business
          <span>
            Patients Count <strong>{vulCount}</strong>
          </span>
        </span>
        <HorizontalBarChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="VaccinationRateDetails"
          locationDisplay="state"
          startDate={startDate}
          endDate={endDate}
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart3"
          filterId={getfilterts}
          fluFolder='VaccinationRateDetails'
          //filterid='VaccinationRate-identifier-OREGON-FLUSEASON-10_MONTH-All'
          height='200'
          month ={getfilterts.month}
          fluseason ={getfilterts.season}
          state={getfilterts.state}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
      <div className={styles.ratePanel}>
        <span>
          Type of Vaccines Adminstered
          <span>
            Patients Count <strong>{vulCount}</strong>
          </span>
        </span>
        <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="DataMedical"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart4"
          pieFor="vaccinationdetails"
          height={220}
          filterId={getfilterts}
          //filterid='DataMedical-STATE-OREGON_FLUSEASON-10_MONTH-All'
          fluFolder='DataMedical'
          month ={getfilterts.month}
          fluseason ={getfilterts.season}
          state={getfilterts.state}
          
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
    </div>
  )
}

export default BottomGraphPanel
