import { useState } from 'react'
import getThresholdValue from '../../utils/getThresholdValue'
import { useGeoState } from '../../components/GeoProvider'
export default function MapLegend(props) {
  const {
    displayDate,
  } = useGeoState()

  function getIntervalThreshold(min,max,num)
  {
    
    let diff=max-min
    let diffValue=diff/5

    return ((diffValue*num)+min).toFixed(3);
  }
  function getFromRangeThreshold(min,max,num)
  {
    let diff=max-min
    let diffValue=diff/5
    let finalValue=((diffValue*num)+min)
    let rtValue=finalValue<.001?finalValue:finalValue+.001
    return (rtValue.toFixed(3))
  }
  let displayData=Object.entries(props.dataSet[displayDate])
  let minData=Math.min(...displayData.map(member => member[1].data_value))
  let maxData=Math.max(...displayData.map(member => member[1].data_value))
  let gapValue=maxData<0.001?0.000:0.001
  const provider = props.provider
  const geojson = props.geojson
  const thresholdVal = props.thresholdVal
  const[getistrue, setistrue]=useState(true)
  if (provider)
  {
    const removelayercolor = () => {
      
       setistrue(!getistrue)
      const refStateMap = geojson
      refStateMap.current.eachLayer(function (layer) {   
           
        layer.setStyle({
        fillColor: getistrue ? 'none': getThresholdValue(thresholdVal).color,
        weight: 1,
        opacity: 1,
        color: getThresholdValue(thresholdVal).OutlineColor,
        dashArray: '1.5',
        fillOpacity: 0.7,
      })
      })
    }
       
    const svgstyle = {
      viewBox:"0 0 90 150",
      height: "19",
      width: (thresholdVal===5 || thresholdVal===1) ? "85": thresholdVal===3 ? "80": "55",
      align: "center",
      MarginTop:"10",
    };
    const rectstyle = {
      x:"1",
      y:"1",
      width:"16",
      height:"16",
      fill:getThresholdValue(thresholdVal).color,
      stroke:getThresholdValue(thresholdVal).OutlineColor,
      strokeWidth:"1px",
    };
    return (
       <svg
       style={svgstyle}
      {...props}
    >
        <rect
        
          style={rectstyle}
      ></rect> 

      <text
       
        x="25"
        y="13"
        style={{
          width: '57px',
          height: '16px',
         fontWeight:'400',
          fontSize: '12.64px',
          fill: '#0c55b8',         
          cursor:'pointer',
          textDecoration: 'underline'
        }}
      >
      <a  onClick={removelayercolor} >{ getThresholdValue(thresholdVal).threshold}</a>  
      </text>  
      </svg>)
  }
  else {
  return (
    <svg
      viewBox="0 0 170 170"
      height="170"
      width="170"
      align="center"
      margin-top="0"
      {...props}
    >
      <rect
        x="1"
        y="0"
        width="25"
        height="25"
        fill="#48338C"
        stroke="#48338C"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="25"
        width="25"
        height="25"
        fill="#5768B0"
        stroke="#5768B0"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="50"
        width="25"
        height="25"
        fill="#7B99CD"
        stroke="#0E456B"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="75"
        width="25"
        height="25"
        fill="#ACCAE4"
        stroke="#2D6EA3"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="100"
        width="25"
        height="25"
        fill="#E5FCF6"
        stroke="#2B9181"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="125"
        width="25"
        height="25"
        fill="#6D6F70"
        stroke="#6D6F70"
        strokeWidth="1px"
      ></rect>

      <text
        x="35"
        y="16"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
      {getFromRangeThreshold(minData,maxData,4)} - {getIntervalThreshold(minData,maxData,5)}
      </text>
      <text
        x="35"
        y="42"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
       {getFromRangeThreshold(minData,maxData,3)} - {getIntervalThreshold(minData,maxData,4)}
      </text>
      <text
        x="35"
        y="66"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
       {getFromRangeThreshold(minData,maxData,2)} - {getIntervalThreshold(minData,maxData,3)}
      </text>
      <text
        x="35"
        y="90"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
       {getFromRangeThreshold(minData,maxData,1)} - {getIntervalThreshold(minData,maxData,2)}
      </text>
      <text
        x="35"
        y="116"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
       {gapValue.toFixed(3)} - {getIntervalThreshold(minData,maxData,1)}
      </text>
      <text
        x="35"
        y="142"
        style={{
          width: '57px',
          height: '16px',
          fontw: 400,
          fontSize: '12.64px',
        }}
      >
        No Data
      </text>
    </svg>
  )
      }
}
