import React from 'react'

import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import FluIndicesTabs from './FluIndicesTabs'

import styles from './FluIndices.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
const FluTabs = () => {
  const { user } = useAuthState()

  // var url = window.location.href;
  // var name='';
  // if (url.indexOf('map#datatable') !== -1)
  //  name='Influenza Data Table';
  //  if (url.indexOf('map#map') !== -1)
  //  name='Influenza Map';

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Influenza Map',
    indicator_name: 'Influenza Map',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <FluIndicesTabs />
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default FluTabs
