import { dateToolTip } from '../../toolTipFormatterFlu'

const getAreaChartOptions = (data, chartName = '') => {
  return {
    title: {
      text: chartName,
    },

    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
    },

    yAxis: {
      title: {
        text: 'Flu2',
      },
      min: 0,
    },

    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: dateToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: data,
  }
}
export default getAreaChartOptions
