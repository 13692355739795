import React from 'react'

import { useAuthDispatch } from '../../components/AuthProvider'
import Spinner from '../../components/Loader/Spinner'
import Logo from '../../components/Logo'
import Card from '../../components/Card'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'

import { APP_NAME } from '../../utils/constants'

import styles from './ActiveDirectorySignOut.module.scss'

const ActiveDirectorySignOut = () => {
  const { user } = useAuthState()

  var url = window.location.href
  var name = 'Sign Out'
  var logObject = {
    dashboard_url: url,
    dashboard_name: name,
    indicator_name: name,
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  const { clearSession } = useAuthDispatch()

  React.useEffect(() => {
    clearSession()
    window.location.replace(
      `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_UI_HOME}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.base}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo color="#fff" />
        </div>
        <Card className={styles.loginCard}>
          <div className={styles.appName}>
            <span>{APP_NAME}</span>
          </div>
          <div className={styles.loginPreference}>
            <Spinner /> Redirecting to sign out page...
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ActiveDirectorySignOut
