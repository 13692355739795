import React from "react";
import Group from "./Group";
import Item from "./Item";
import OptionGroup from "./OptionGroup";
import Label from "../Label";
import { useMedia } from "../ResponsiveMedia";

import styles from "./DropDownList.module.scss";

const DropDownList = React.forwardRef(
  (
    {
      children = null,
      defaultValue,
      errorText,
      id,
      label,
      onChange,
      required: isRequired = false,
      value,
      ...rest
    },
    ref
  ) => (
    <React.Fragment>
      {typeof label === "string" ? (
        <Label error={Boolean(errorText)} htmlFor={id} required={isRequired}>
          {label}
        </Label>
      ) : (
        label
      )}
      <select
        aria-required={isRequired}
        className={`${styles.base} ${errorText ? styles.error : ""} ${
          rest.className ? rest.className : ""
        }`}
        defaultValue={defaultValue}
        id={id}
        onChange={onChange}
        ref={ref}
        style={{ width: useMedia(rest.width) }}
        value={value}
        disabled={rest.isDisabled}
      >
        {children}
      </select>
      {errorText && <span className={styles.errorText}>{errorText}</span>}
    </React.Fragment>
  )
);

DropDownList.Group = Group;
DropDownList.Item = Item;
DropDownList.OptionGroup = OptionGroup;

export default DropDownList;
