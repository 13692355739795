import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../components/DisplayDatePharma/DisplayDateFluNew'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/MapPharma/emptyMap'
import Map from '../../components/MapPharma'
//import toolTipFormatter from './toolTipFormatter'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import { useHistory } from 'react-router-dom'

const DashboardMap = ({ zoomLocation }) => {
  const { user } = useAuthState()
  //const chartRef = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  // const statesData=covidmap
  const { data: statesData, error: MapError } = useGeoFetch(
    dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    dayjs().add(6, 'month').format('YYYY-MM-DD'),
    'covidMap',
    'STATE/?geo_parent=US',
    true
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  useEffect(() => {
    let url = `${process.env.REACT_APP_UI_HOME}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  //stateDataError || MapError ||
  if ( errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div 
    onClick={() => {
      history.push('/forecasts/CovidDashboard')
    }}
    className={styles.base}>
      
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined && getStateJson ? (
              <>               
                <Map
                  toolTipFormatter={null}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={false}
                  mapType="RSVMAP"
                  enableButtons={false}
                  chartRef={null}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="COVID"
                  mapHeight={'40vh'}
                  enableMapNavigation={false}
                  enableMouseTracking={false}
                />               
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
     
    </div>
  )
}

export default DashboardMap
