export default function MapLegend(props) {
  return (
    <svg
    viewBox="0 0 150 150"
    height="150"
    width="150"
    align="center"
    margin-top="0"
      {...props}
    >

<rect
        x="1"
        y="0"
        width="25"
        height="25"
        fill="#48338C"
        stroke="#48338C"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="25"
        width="25"
        height="25"
        fill="#5768B0"
        stroke="#5768B0"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="50"
        width="25"
        height="25"
        fill="#7B99CD"
        stroke="#0E456B"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="75"
        width="25"
        height="25"
        fill="#ACCAE4"
        stroke="#2D6EA3"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="100"
        width="25"
        height="25"
        fill="#E5FCF6"
        stroke="#2B9181"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="125"
        width="25"
        height="25"
        fill="#6D6F70"
        stroke="#6D6F70"
        strokeWidth="1px"
      ></rect>
       <text
        x="35"
        y="16"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
     100%
      </text>
      <text
        x="35"
        y="42"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
      80%
      </text>
      <text
        x="35"
        y="66"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
      60%
      </text>
      <text
        x="35"
        y="90"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
      40%
      </text>
      <text
        x="35"
        y="116"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
       20%
      </text>
      <text
        x="35"
        y="142"
        style={{
          width: '57px',
          height: '16px',
          fontw: 400,
          fontSize: '12.64px',
        }}
      >
        No Data
      </text>
    </svg>
  )
}
