const ageDisplayOptions = [
  { value: '0', label: 'Select'},
  { value: '1', label: '<30'},
  { value: '2', label: '30-44'},
  { value: '3', label: '45-59'},
  { value: '4', label: '60-74'},
  { value: '5', label: '75+' },
]

export default ageDisplayOptions
