import React from 'react'
import { useGeoState, useGeoFetch } from '../GeoProvider'
import ErrorMessage from '../Layout/ErrorMessage'
import Spinner from '../Loader/Spinner'

import HorizontalBarChartDisplay from './HorizontalBarChartDisplay'

const HorizontalBarChart = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  dataDisplayOptions,
  chartName,
  height,
  filterId,
  fluFolder,
  fluseason,
  month,
  lob,
  type,
  state,
  datafill = false,
}) => {
  const { setVulCount } = useGeoState('')
  const { data, error } = useGeoFetch(
    startDate,
    endDate,
    entity,
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`)

  
  if (error) {
    return <ErrorMessage />
  }
  if (data !== null && data !== undefined && datafill)
  {    
    setVulCount(data.response_data.countyPatientCount[0]);
  }

  return data ? (
    <HorizontalBarChartDisplay
      toolTipFormatter={toolTipFormatter}
      entity={entity}
      location={location}
      locationDisplay={locationDisplay}
      options={options}
      data={data}
      dataDisplayOptions={dataDisplayOptions}
      chartName={chartName}
      height={height}
    />
  ) : (
    <Spinner />
  )
}
export default HorizontalBarChart
