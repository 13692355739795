function toolTipFormatter() {
  const pointName =
    this.point.properties.cbsa_name === undefined
      ? ''
      : `/${this.point.properties.cbsa_name}`

  return `<div style="width:110%;background-color:transparent;">
    <div style="width:90%;
    background-color:transparent;
    color:#7d7d7d;padding-left:10px;
    padding-top:10px;padding-right:5px;
    display:inline;">${this.point.name.toUpperCase()}${pointName} CI ACCURACY </div>

    <div style="padding-top:10px;display: inline-block;padding-right:10px;">
    <span style="border-radius:50%;
    background-color:${this.color}; 
    display: inline-block;
    width: 10px;
    height: 10px"></span>&nbsp;
    </div>
    
    <div style="width:90%;padding-left: 10px;">
    <span style="color:black;
                font-weight:bold;font-size:14px;padding-right:10px;">${this.point.value.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}%</span></div>
   
    <hr/ style="width:90%">
    <div style="width:90%;
    padding-left:10px;padding-top:2px;
    font-size:12px;padding-right:10px;">

    <span style="">MAE</span>
    <span style="padding-left:10px">MAPE</span>
    <span style="padding-left:15px">RMSE</span>
 
    
    <br/>

    <span style="">${this.point.mae.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}</span>
    <span style="padding-left:10px">${this.point.mape.toLocaleString(
      undefined,
      {
        maximumFractionDigits: 2,
      }
    )}</span>
    <span style="padding-left:20px">${this.point.rmse.toLocaleString(
      undefined,
      {
        maximumFractionDigits: 2,
      }
    )}</span>
    
    </div>
    </div>`
}

export default toolTipFormatter
