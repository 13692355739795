import React from 'react'
import MediaContext from './MediaContext'

// eslint-disable-next-line import/no-anonymous-default-export
export default (mediaProps, transform = (value) => value) => {
  if (typeof mediaProps !== 'object' || Array.isArray(mediaProps)) {
    return transform(mediaProps) // Static value
  }

  const mediaKeys = React.useContext(MediaContext)
  const defaultMediaKey = Object.keys(mediaProps)[0]
  const [value, setValue] = React.useState(
    transform(mediaProps[defaultMediaKey])
  )

  const nextValue = transform(
    (() => {
      for (let i = mediaKeys.length - 1; i > -1; i--) {
        if (mediaProps[mediaKeys[i]] !== undefined) {
          return mediaProps[mediaKeys[i]]
        }
      }

      return mediaProps[defaultMediaKey]
    })()
  )

  if (value !== nextValue) {
    setValue(nextValue)
  }

  return value
}
