const colorAxis = {
  dataClasses: [
    {
      to: 0,
      color: '#6D6F70',
      name: 'No Data',
    },
    {
      from: 0.0000000001,
      to: 1,
      color: '#218371',
      plotBorderColor: '#FFFFFF',
      borderWidth: 1,
      name: 'Minimal',
    },
    {
      from: 1.0000000001,
      to: 2,
      color: '#6CB2A2',
      plotBorderColor: '#0D5E4F',
      borderWidth: 1,
      name: 'Low',
    },
    {
      from: 2.0000000001,
      to: 3,
      color: '#FFFFF0',
      plotBorderColor: '#AD8D01',
      borderWidth: 1,
      name: 'Medium',
    },
    {
      from: 3.0000000001,
      to: 4,
      color: '#FB8D7B',
      plotBorderColor: '#990000',
      borderWidth: 1,
      name: 'High',
    },
    {
      from: 4.0000000001,
      color: '#C40000',
      plotBorderColor: '#FFFFFF',
      borderWidth: 1,
      name: 'Very High',
    },
  ],
}
export default colorAxis
