
import ReactSwitch from 'react-switch'
import { useGeoState } from '../../components/GeoProvider'
export default function MapZoomToggle(props) {
  const {
    getMapZoom, setMapZoom 
    
  } = useGeoState()
  const handleChangeCoverage = (val) => {
    setMapZoom(val)
  }
  return (
    <div style={{width:'100%',display:'flex',flexDirection:'row-reverse', marginLeft:'-10px',marginBottom: '-10px'}} >
      <div className={props.className}>  <span>Pinch zoom</span>
      <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={getMapZoom}
              onChange={handleChangeCoverage}
            /></div>
     
            </div>
  )
      
}
