import React, { useState,useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'

import ErrorMessage from '../../components/Layout/ErrorMessage'
import Timeline from '../../components/Timeline'
import Map from '../../components/Map'
import EmptyMap from '../../components/Map/emptyMap'
import MapOptions from '../../components/MapOptions'
import toolTipFormatter from './toolTipFormatter'
import colorRanges from './colorAxisFluIndex'
import regionDisplayOptions from './regionDisplayOptions'
import dataDisplayOptions from './dataDisplayOptions'
import MapLegend from './cdcMapLegendVertical'
import StateStats from './StateStats'
import CbsaStats from './CbsaStats'

import styles from './FluMeter.module.scss'

const FluMap = () => {
  const entity = 'flumeter'
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  //+ getEpiWeek(displayDateUnformatted)
  const [getStateJsonFile, setStateJsonFile] = useState();
  const [cbsaJsonFile, setCbsaJsonFile] = useState();
  const slotString = 'Week'

  const { dataStartDate, dataEndDate, mapOptions, regionSelected } =
    useGeoState()

    useEffect(() => {
      let url = `${window.location.origin}/usStatesAll.json`
      fetch(url)
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {

          setStateJsonFile(data);
        })
        .catch(function (err) {
          console.log(err, " error file load usStatesAll ");
        });
    }, [1]);

    
    
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {

       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  const { data: statesData, error: stateDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'state'
  )
  const { data: cbsasData, error: cbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'msa'
  )

  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'flu/activecbsa'
  )

  if (stateDataError || cbsasDataError || activeCbsasDataError) {
    return <ErrorMessage />
  }

  return statesData && cbsasData && activeCbsasData ? (
    <>
      <div className={styles.container}>
        <div className={styles.map}>
          <MapOptions
            regionDisplayOptions={regionDisplayOptions}
            dataDisplayOptions={dataDisplayOptions}
          />
          {regionSelected === 'state' && getStateJsonFile && (
            <Map
              colorRanges={colorRanges}
              showStateLabels={true}
              toolTipFormatter={toolTipFormatter}
              activeCbsasData={null}
              statesData={statesData}
              cbsaData={null}
              mapOptions={mapOptions}
              mapHeight={'365px'}
              dynamicMapTitle={false}
              dynamicMapLevelWeek={true}
              mapType="FLUMETERMAP"
              stateJson={getStateJsonFile}

            />
          )}
          {regionSelected === 'msa' && getStateJsonFile && cbsaJsonFile &&  (
            <>
            
            
            <Map
              colorRanges={colorRanges}
              showStateLabels={true}
              toolTipFormatter={toolTipFormatter}
              activeCbsasData={activeCbsasData}
              statesData={statesData}
              cbsaData={cbsasData}
              mapOptions={mapOptions}
              mapHeight={'365px'}
              dynamicMapTitle={false}
              dynamicMapLevelWeek={true}
              mapType="FLUMETERMAP"
              stateJson={getStateJsonFile}
              cbsaJson={cbsaJsonFile}
            />
            </>
          )}
          <div className={styles.legend}>
          
            <MapLegend height="210" width="90" />
          </div>
        </div>
        <div className={styles.rightPanel}>
          {regionSelected === 'state' && (
            <StateStats stateData={statesData} mapOptions={mapOptions} />
          )}

          {regionSelected === 'msa' && (
            <CbsaStats cbsaData={cbsasData} mapOptions={mapOptions} />
          )}
        </div>
      </div>
      <Timeline
        statesData={statesData}
        latestPositionString={latestPositionString}
        slotString={slotString}
        slotTimeInSeconds={slotTimeInSeconds}
        markCounts={3}
        // statesData={statesData}
        // latestPositionString={latestPositionString}
        // slotString={slotString}
        // slotTimeInSeconds={slotTimeInSeconds}
        // markCounts={3}
        // showOptions={false}
        // relativeSlots={false}
      />
    </>
  ) : (
    <EmptyMap mapHeight={'365px'} />
  )
}

export default FluMap
