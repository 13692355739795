const colorAxis = {
  dataClasses: [
    {
      to: 0,
      color: '#D9D8D8',
    },
    {
      from: 1,
      to: 20,
      color: '#E5FCF6',
    },
    {
      from: 20.01,
      to: 40,
      color: '#ACCAE4',
    },
    {
      from: 40.01,
      to: 60,
      color: '#7B99CD',
    },
    {
      from: 60.01,
      to: 80,
      color: '#5768B0',
    },
    {
      from: 80.01,
      to: 100,
      color: '#48338C',
    },
    
  ],
}
export default colorAxis
