import colorAxisFluIndex from './colorAxisFluIndex'
const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'forecast_level',
        tagKey: 'forecast_level',
        tagValue: 'data_value',
        label: 'Incidense Rate',
        colorAxis: colorAxisFluIndex,
        mapTitle: 'US RSV Incidence',
        mapSubTitle: undefined,
        indicatorName: 'Forecasts',
        legend: {
          enabled: false,
          title: {
            text: 'US RSV Incidence',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
