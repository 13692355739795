import React from 'react'
import styles from './MapPage.module.scss'
import getVectorImage from '../../../utils/getVectorImage'
const ProviderDetails = ({ providerData, redirecttoGoogleMap }) => {
  let notAv = providerData.originating_business === 'Name Not Available'
  return (
    providerData && (
      <div className={styles.Popup}>
        <div className={styles.Popupheader}>
          <div className={styles.PopupheaderText}>
            {providerData.location_code + ': '}
            {notAv === true ? (
              <span className={styles.notAvailable}>
                ({providerData.originating_business})
              </span>
            ) : (
              providerData.originating_business
            )}
          </div>
          <div className={styles.filter}>
            {' '}
            {providerData.primary_location_type_formated}{' '}
            {providerData.primary_location_type_formated !== 'Other'
              ? providerData.primary_location_sub_type_formated
              : ''}
          </div>
          <div
            onClick={(event) => redirecttoGoogleMap(providerData)}
            className={styles.PopupheaderText1}
          >
            <span style={{ paddingRight: '12px' }}>
              {getVectorImage('location', '19px', '16px')}
            </span>{' '}
            {providerData.address_line1} {providerData.address_line2}
            <br></br>
            {providerData.geo_name}
          </div>
        </div>
      </div>
    )
  )
}

export default ProviderDetails
