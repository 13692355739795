import React from "react";

import styles from "./Links.module.scss";

const Links = ({ children = null, columns, heading }) => (
  <div className={styles.base}>
    <h4>{heading}</h4>
    <nav>
      <ul style={{ columns }}>{children}</ul>
    </nav>
  </div>
);

export default Links;
