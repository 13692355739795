import React from 'react'
import styles from './GraphPanel.module.scss'
import { useGeoState } from '../../components/GeoProvider'

const GraphPanel = () => {
  const { getRiskResult } = useGeoState()

  return (
    <div className={styles.base}>
      <div className={styles.firstPanel}>
        <p
          style={{
            fontSize: '16px',

            paddingLeft: '8px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          Results based on above selections
        </p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
            paddingLeft: '8px',
            marginTop: '10px',
            // marginBottom: '20px',
          }}
        >
          Probability of Cardiac Risk*
        </p>
        <span style={{ fontSize: '5em', paddingLeft: '8px' }}>
          {getRiskResult}%
        </span>
      </div>
      <div className={styles.secondPanel}>
        {/* <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="flu"
          location="state"
          locationDisplay="State"
          startDate={startDate}
          endDate={endDate}
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
        /> */}
      </div>
    </div>
  )
}

export default GraphPanel
