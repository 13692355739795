import React from "react";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import PageNotFound from "../../components/PageNotFound";

const NotFound = () => (
  <Layout>
    <Container>
      <PageNotFound />
    </Container>
  </Layout>
);

export default NotFound;
