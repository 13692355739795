import Highcharts from 'highcharts'

import Chart from '../../components/Highcharts/Chart'

import getAreaChartOptions from './areaChartOptions'

import styles from './FluDetails.module.scss'

const FluAccuracyChart = ({ cbsaJson,countyJson, data, identifier, type }) => {
  const actual = data
    .filter((d) => d.actual_value > 0)
    .map((d) => {
      return [new Date(d.date).getTime(), d.actual_value]
    })

  let high_point
  const high = data.map((d) => {
    high_point = data[0].high_point
    return [new Date(d.date).getTime(), high_point]
  })

  const forecast = data.map((d) => {
    if (d.forecast_value === 0) {
      return [new Date(d.date).getTime(), null]
    } else {
      return [new Date(d.date).getTime(), d.forecast_value]
    }
  })

  const accuracyChartData = [
    {
      name: 'Baseline',
      data: high,
      zIndex: 1,
      color: 'red',
      showInLegend: true,
    },
    {
      name: 'Actual',
      data: actual,
      zIndex: 1,
      color: 'black',
    },
    {
      name: 'Forecast',
      data: forecast,
      lineWidth: 2,
      zIndex: 1,
      color: Highcharts.getOptions().colors[0],
    },
  ]
  const accuracyChartOptions = getAreaChartOptions(
    accuracyChartData,
    identifier,
    type, cbsaJson, countyJson
  )

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {/* <h4
          style={{
            paddingRight: '10px',
          }}
        >
          Accuracy Report Comparison
        </h4> */}
        {/* <BaseLine></BaseLine> */}
      </div>
      <div className={styles.container}>
        {/* <div
          style={{
            marginTop: '2rem',
            padding: '1rem',
            backgroundColor: '#f7f7f7',
          }}
        >
          <h4>Flu activity data compare</h4>

          <p>
            Please note that actual values are released after 2 weeks, thus
            comparison can only be done with T-2 weeks.
          </p>
          <p>
            It takes up to 10 weeks for the claims data to stabilize in UDW for
            a particular week. Hence, the actual v/s forecast comparison for the
            most recent 10 weeks may not be an accurate representation of the
            forecast accuracy. Accuracy comparison may also be impacted by the
            viral strains prevalent in the United States for a particular flu
            season. The 2009 pandemic is an example of the previous statement.
          </p>
        </div> */}
        <div>
          <Chart chartType="arearange" chartOptions={accuracyChartOptions} />
        </div>
      </div>
    </div>
  )
}

export default FluAccuracyChart
