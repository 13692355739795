import React from "react";
import Content from "../Content";

import styles from "./Content.module.scss";

const FooterContent = ({ children = null, heading }) => (
  <div className={styles.base}>
    <h4>{heading}</h4>
    <Content maxWidth="12rem">{children}</Content>
  </div>
);

export default FooterContent;
