import statesMap from './stateMap'
import cbsasMap from './cbsasMap'
import countyMap from './countyMap'

const geographyHandler = (geoType, geoId, cbsaJson) => {
  let geography = ''
  switch (geoType.toUpperCase()) {
    case 'STATE':
      geography = statesMap()[geoId]
      break
    case 'CITY':
    case 'CBSA':
      //geography = cbsasMap()[geoId]
      geography = cbsasMap(cbsaJson)[geoId]
      break
    case 'COUNTY':
      geography = countyMap()[geoId]
      break
    default:
      break
  }
  return geography
}

export default geographyHandler
