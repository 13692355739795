import React from 'react'
import Highcharts from 'highcharts'
import Chart from '../../../../components/Highcharts/Chart'
import { CurrentEpiWeek } from '../FluGrid/transformDataForGrid'
import getLineChartOptions from './lineChartOptions'
import weeks from './seasonChartWeeks.json'
import styles from './FluDetails.module.scss'
const FluSeasonsChart = ({ data, identifier,  selectedOptions }) => { 

  let transformedData = new Map()
  Object.entries(data).forEach(([epiWeekDate, epiWeekData]) => {
   
    let weekMap = epiWeekData[identifier]
    let season = epiWeekData['epi_season']
    let week = epiWeekData['epi_period_week']
    // let season = epiWeekData[identifier].epi_season
    // let week = epiWeekData[identifier].epi_period_week
    transformedData[season] = {
      ...transformedData[season],
      [week]: { ...weekMap, epi_week: epiWeekDate },
    }
  })

  
  
  // TO-DO revisit the code if we can send seasons
  // for chart based on api response
  const data_2023_24 = transformedData['2023-24']
  const data_2022_23 = transformedData['2022-23']
  const data_2021_22 = transformedData['2021-22']
  const data_2020_21 = transformedData['2020-21']
  const data_2019_20 = transformedData['2019-20']
  const data_2018_19 = transformedData['2018-19']
  const data_2017_18 = transformedData['2017-18']
  const data_2016_17 = transformedData['2016-17']
  const data_2015_16 = transformedData['2015-16']
  const data_2014_15 = transformedData['2014-15']
  const currentWeekMinus3Weeks = new Date(CurrentEpiWeek())
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  const chart_2023_24 = []
  const chart_2022_23 = []
  const chart_2021_22 = []
  const chart_2020_21 = []
  const chart_2019_20 = []
  const chart_2018_19 = []
  const chart_2017_18 = []
  const chart_2016_17 = []
  const chart_2015_16 = []
  const chart_2014_15 = []
  const forecast_values = []
  const high_line = [] 
  weeks.forEach((week, i) => {
    if (data_2019_20 !== undefined || data_2019_20 !== null)
    {
    high_line.push(
      data_2019_20[week]
        ? data_2019_20[week].high_point
        : high_line.length > 0
        ? high_line[i - 1]
        : null
    )
    }
    if (data_2022_23!==undefined || data_2022_23!==null)
    {
      chart_2022_23.push(
        data_2022_23[week]
        ? data_2022_23[week].actual_value
        : chart_2022_23.length > 0
        ? chart_2022_23[i - 1]
        : null
    )}
    if (data_2021_22!==undefined || data_2021_22!==null)
    {
      chart_2021_22.push(
      data_2021_22[week]
        ? data_2021_22[week].actual_value
        : chart_2021_22.length > 0
        ? chart_2021_22[i - 1]
        : null
    )}
    if (data_2020_21!==undefined || data_2020_21!==null)
    {
    chart_2020_21.push(
      data_2020_21[week]
        ? data_2020_21[week].actual_value
        : chart_2020_21.length > 0
        ? chart_2020_21[i - 1]
        : null
    )
    }
    if (data_2019_20!==undefined || data_2019_20!==null)
    {
    chart_2019_20.push(
      data_2019_20[week]
        ? data_2019_20[week].actual_value
        : chart_2019_20.length > 0
        ? chart_2019_20[i - 1]
        : null
    )
    }

    if (data_2018_19!==undefined || data_2018_19!==null)
    {
    chart_2018_19.push(
      data_2018_19[week]
        ? data_2018_19[week].actual_value
        : chart_2018_19.length > 0
        ? chart_2018_19[i - 1]
        : null
    )
    }
    if (data_2017_18!==undefined || data_2017_18!==null)
    {
    chart_2017_18.push(
      data_2017_18[week]
        ? data_2017_18[week].actual_value
        : chart_2017_18.length > 0
        ? chart_2017_18[i - 1]
        : null
    )
    }
    if (data_2016_17!==undefined)
    {
    chart_2016_17.push(
      data_2016_17[week]
        ? data_2016_17[week].actual_value
        : chart_2016_17.length > 0
        ? chart_2016_17[i - 1]
        : null
    )
    }
    if (data_2015_16!==undefined)
    {
    chart_2015_16.push(
      data_2015_16[week]
        ? data_2015_16[week].actual_value
        : chart_2015_16.length > 0
        ? chart_2015_16[i - 1]
        : null
    )
    }
    if (data_2014_15 !== undefined )
    {    
    chart_2014_15.push(
      data_2014_15[week]
        ? data_2014_15[week].actual_value
        : chart_2014_15.length > 0
        ? chart_2014_15[i - 1]
        : null
    )
    }

    if (data_2023_24 !== undefined || data_2023_24 !== null)
    { 
    if (data_2023_24[week]) {      
      if (new Date(data_2023_24[week].epi_week) <= currentWeekMinus3Weeks) {
        chart_2023_24.push(data_2023_24[week].actual_value)
        if (
          new Date(data_2023_24[week].epi_week).getDate() ===
          currentWeekMinus3Weeks.getDate()
        ) {
          forecast_values.push(data_2023_24[week].actual_value)
        } else {
          forecast_values.push(null)
        }
      } else if (
        new Date(data_2023_24[week].epi_week).getTime() ===
        currentWeekMinus3Weeks.getTime()
      ) {
        chart_2023_24.push(data_2023_24[week].actual_value)
        forecast_values.push(data_2023_24[week].actual_value)
      } else {
        forecast_values.push(data_2023_24[week].forecast_value)
        chart_2023_24.push(null)
      }
    } else {
      chart_2023_24.push(null)
      forecast_values.push(null)
    }
  }


  //   if (data_2022_23 !== undefined || data_2022_23 !== null)
  //   { 
  //   if (data_2022_23[week]) {      
  //     if (new Date(data_2022_23[week].epi_week) <= currentWeekMinus3Weeks) {
  //       chart_2022_23.push(data_2022_23[week].actual_value)
  //       if (
  //         new Date(data_2022_23[week].epi_week).getDate() ===
  //         currentWeekMinus3Weeks.getDate()
  //       ) {
  //         forecast_values.push(data_2022_23[week].actual_value)
  //       } else {
  //         forecast_values.push(null)
  //       }
  //     } else if (
  //       new Date(data_2022_23[week].epi_week).getTime() ===
  //       currentWeekMinus3Weeks.getTime()
  //     ) {
  //       chart_2022_23.push(data_2022_23[week].actual_value)
  //       forecast_values.push(data_2022_23[week].actual_value)
  //     } else {
  //       forecast_values.push(data_2022_23[week].forecast_value)
  //       chart_2022_23.push(null)
  //     }
  //   } else {
  //     chart_2022_23.push(null)
  //     forecast_values.push(null)
  //   }
  // }
  })

  var SET2023_2024=true,  SET2022_2023=true,  SET2021_2022=true,  SET2020_2021=true,  SET2019_2020=false,
    SET2018_2019=false, SET2017_2018=false,  SET2016_2017=false,
    SET2015_2016=false,  SET2014_2015=false
    
  if (selectedOptions.length > 0) {
    SET2023_2024=false; 
    SET2022_2023=false;  SET2021_2022=false;  SET2020_2021=false;  SET2019_2020=false;
    SET2018_2019=false; SET2017_2018=false;  SET2016_2017=false;
    SET2015_2016=false;  SET2014_2015=false;
    SET2023_2024 = selectedOptions.some(el => el.label ==="2023-24")
    SET2022_2023 = selectedOptions.some(el => el.label ==="2022-23")  
    SET2021_2022 = selectedOptions.some(el => el.label ==="2021-22")  
    SET2020_2021 = selectedOptions.some(el => el.label ==="2020-21")    
    SET2019_2020 = selectedOptions.some(el => el.label ==="2019-20")  
    SET2018_2019 = selectedOptions.some(el => el.label ==="2018-19")  
    SET2017_2018 = selectedOptions.some(el => el.label ==="2017-18")  
    SET2016_2017 = selectedOptions.some(el => el.label ==="2016-17")  
    SET2015_2016 = selectedOptions.some(el => el.label ==="2015-16")  
    SET2014_2015 = selectedOptions.some(el => el.label ==="2014-15")    
    
  }
    
    // Object.entries(selectedOptions).forEach(([index, value]) => {

    //   var season = value.label;
       
    //   // if (season==="2021-22")  {  SET2021_2022=false;} 
    //   if (season === "2022-23") { SET2022_2023 = true } else { SET2022_2023 = false }
    //   if (season === "2021-22") { SET2021_2022 = true } else { SET2021_2022 = false }
    //   if (season === "2020-21") { SET2020_2021 = true } else { SET2020_2021 = false }
    //   if (season === "2019-20") { SET2019_2020 = true } else { SET2019_2020 = false }
    //   if (season === "2018-19") { SET2018_2019 = true } else { SET2018_2019 = false }
    //   if (season === "2017-18") { SET2017_2018 = true } else { SET2017_2018 = false }
    //   if (season === "2016-17") { SET2016_2017 = true } else { SET2016_2017 = false }
    //   if (season === "2015-16") { SET2015_2016 = true } else { SET2015_2016 = false }
    //   if (season === "2014-15") { SET2014_2015 = true } else { SET2014_2015 = false }
    // })

  const lineChartData = [
    {
      name: 'forecast',
      data: forecast_values,
      dashStyle: 'dot',
      color: Highcharts.getOptions().colors[9],
      visible:true,
    },
    {
      name: '2023-2024',
      data: chart_2023_24,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[9],
      visible:SET2023_2024,
    },
    {
      name: '2022-2023',
      data: chart_2022_23,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[8],
      visible:SET2022_2023,
    },
    {
      name: '2021-2022',
      data: chart_2021_22,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[7],
      visible:SET2021_2022,
    },
    {
      name: '2020-2021',
      data: chart_2020_21,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[0],
      visible:SET2020_2021,
    },
    {
      name: '2019-2020',
      data: chart_2019_20,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[1],
      visible:SET2019_2020,
    },
    {
      name: '2018-2019',
      data: chart_2018_19,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[2],
      visible:SET2018_2019,
    },
    {
      name: '2017-2018',
      data: chart_2017_18,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[3],
      visible:SET2017_2018,
    },
    {
      name: '2016-2017',
      data: chart_2016_17,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[4],
      visible:SET2016_2017,
    },
    {
      name: '2015-2016',
      data: chart_2015_16,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[5],      
      visible:SET2015_2016,
    },
    {
      name: '2014-2015',
      data: chart_2014_15,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[6],
      visible:SET2014_2015,
    },
    {
      name: 'Baseline',
      data: high_line,
      dashStyle: 'solid',
      lineWidth: 1,
      color: 'red',
    },
  ]  
  const lineOptions = getLineChartOptions(lineChartData, weeks)  
  return (
    lineOptions && (
     
      <div className={styles.chartDiv}>
         
         <div className={styles.container}>
          <div style={{width:'100%'}}>
            <Chart chartOptions={lineOptions} height={'600px'} />
            <span className={styles.bottomText}>Interact with the Graph Key to Show, Hide or Highlight specific Lines.</span>
          </div>

          
        </div>
      </div>
    )
  )
}

export default FluSeasonsChart
