import React from "react";
import { useMedia } from "../ResponsiveMedia";
import transformGutter from "./transformGutter";
import transformSpan from "./transformSpan";
import FlexContext from "./FlexContext";

const Item = ({ children = null, span }) => {
  const { gutter } = React.useContext(FlexContext);
  const boxSizing = "border-box";
  const flexBasis = useMedia(span, transformSpan);
  const maxWidth = flexBasis;
  const padding = useMedia(gutter, transformGutter);

  return (
    <div style={{ boxSizing, flexBasis, maxWidth, padding }}>{children}</div>
  );
};

export default Item;
