import React from 'react'
import styles from './DashboardHeader.module.scss'

const DashboardHeader = () => {
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>Forecasts</h4>
        <p>
          You can find latest forecasts for Flu, COVID-19 Cases & Deaths and
          Hospitalizations.
        </p>
      </div>
    </div>
  )
}

export default DashboardHeader
