import { useHistory } from 'react-router-dom'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'

import { useGeoState } from '../../../../components/GeoProvider'
//import usCountiesAll from '../../../../components/Map/cb_2018_us_county_20m_wkid102004.json1'
import dayjs from 'dayjs'

const CovidCountiesMap = ({ countyData, mapOptions, toolTipFormatter, countyJson }) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }

  const nowcastDate = dayjs().day(0).format('YYYY-MM-DD')
  const actualsDate = dayjs().day(-7).format('YYYY-MM-DD')

  const history = useHistory()

  const { displayDate , dataSelected} = useGeoState()  
  let countyDataForDisplayDate=''
  if (dataSelected ==='Deaths' || dataSelected ==='Cases' || dataSelected ==='hrr')
  {
     countyDataForDisplayDate = Object.entries(countyData['Accuracy'])
  }
  else
  {
     countyDataForDisplayDate = Object.entries(countyData[displayDate])
  }  

  const mapCountyData = countyDataForDisplayDate.map((county) => {
    return {
      value:  (dataSelected ==='Deaths')? county[1].overall_new_deaths[mapOptions.ci95Value]:
       (dataSelected ==='Cases')? county[1].overall_new_cases[mapOptions.ci95Value]: 
       (dataSelected ==='hrr')? county[1].overall_new_cases[mapOptions.ci95Value]:
       county[1][mapOptions.value],
      code: county[0].toString(),
      mape:
          dataSelected === 'Deaths'
            ? county[1].overall_new_deaths[mapOptions.mapeValue]
            : dataSelected === 'hrr'? county[1].overall_new_cases[mapOptions.mapeValue]
            :county[1].overall_new_cases[mapOptions.mapeValue],
        mae:
          dataSelected === 'Deaths'
            ? county[1].overall_new_deaths[mapOptions.maeValue]
            : dataSelected === 'hrr'? county[1].overall_new_cases[mapOptions.maeValue]
            : county[1].overall_new_cases[mapOptions.maeValue],
        rmse:
          dataSelected === 'Deaths'
            ? county[1].overall_new_deaths[mapOptions.rmseValue]
            : dataSelected === 'hrr'? county[1].overall_new_cases[mapOptions.rmseValue]
            : county[1].overall_new_cases[mapOptions.rmseValue],
      identifier: county[0].toString(),
      ...county[1],
    }
  })
  let title = mapOptions.mapTitle

  if (title !== null || title !== undefined) {
    //title = title + ' for Counties'
    // if (displayDate === nowcastDate) {
    //   title = title + ' Forecast for Counties' 
    // } else 
    if (displayDate <= actualsDate) {
      title = title + ' Actuals for Counties' 
    } else {
      title = title + ' Forecast for Counties' 
    }
  }

  const graphOptions = {
    title: {
      text: title,
    },
    subtitle: {
      text: mapOptions.mapSubTitle,
    },
    chart: {
      backgroundColor: 'none',
      displayErrors: false,
    },
    legend: mapOptions.legend,
    mapNavigation: {
      enableMouseWheelZoom:false,
      enableButtons:true,
      //enabled: true,
      buttonOptions: {
        verticalAlign: 'top',
        align: 'right',
      },
    },
    credits: {
      enabled: false,
    },
    colorAxis: mapOptions.colorAxis,
    tooltip: {
      useHTML: true,
      padding: 0,
      formatter: toolTipFormatter,
    },
    series: [
      {
        mapData: countyJson,
        data: mapCountyData,
        joinBy: ['GEOID', 'code'],
        borderColor: 'gray',
        borderWidth: 1,
        states: {
          select: {
            color: null,
            borderColor: 'gray',
          },
          hover: {
            borderColor: 'gray',
          },
        },
        shadow: false,
        cursor: 'pointer',
      },
    ],
    plotOptions: {
      series: {
        events: {
          click: function (e) {
            history.push({ hash: `county-${e.point.identifier}` })
          },
        },
      },
    },
    mapline: {
      showInLegend: false,
      enableMouseTracking: false,
    },
  }
  return (
    <HighchartsReact
      containerProps={{
        style: {
          height: '70vh',
          position: 'relative',
          display: 'flex',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
    />
  )
}

export default CovidCountiesMap
