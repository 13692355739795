import React from 'react'
import moment from 'moment'

import { useGeoState } from '../GeoProvider'
import { useHistory } from 'react-router-dom'
import Tooltip from '../Tooltip'
import Content from '../Content'
import styles from './DisplayDateNew.module.scss'

export const DisplayDateFluNew = ({ slotTimeInSeconds, showWeek }) => {
  const history = useHistory()
  const {
    loadedDate,
    displayDate,
    displayWeek,
    setDisplayDate,
    setTimelineIsPaused,
  } = useGeoState()

  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component

  const timeSpan =
    (new Date(displayDate) - new Date(loadedDate)) / slotTimeInSeconds

  const aboutText =
    'The weekly forecasted percentages of outpatient UHC claims coded as influenza or pneumonia, by state or metro area.'
  // TODO: can break this into a common function for the display date and this component to use!

  let position = ''

  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        <div className={styles.infoIcon}>
          <Tooltip placement="top">
            <Content maxWidth="300px" fontSize="12px">
              <p>{aboutText}</p>
            </Content>
          </Tooltip>
          <span className={styles.aboutThis}>
            <a
              href=""
              onClick={() => {
                history.push('/about/model')
              }}
            >
              About Models
            </a>
          </span>

          <span className={styles.displayDateMargin}>
            {'Viewing Week of ' + moment(displayDate).format('MMMM D, YYYY')}{' '}
            {showWeek ? `- Epiweek ${displayWeek}` : null}
          </span>
        </div>

        <div className={styles.setHeight}>
          <span className={styles.position}>{position}</span>

          {timeSpan !== 0 && (
            <button
              className={styles.positionButton}
              onClick={(e) => {
                e.preventDefault()
                setDisplayDate(loadedDate)
                setTimelineIsPaused(true)
              }}
            ></button>
          )}
        </div>
      </div>
    </div>
  )
}

export default DisplayDateFluNew
