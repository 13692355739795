import React from 'react'
import AzureAD from 'react-aad-msal'

import authProvider from '../../components/AuthProvider/ActivateDirectoryAuthProvider'
import Spinner from '../../components/Loader/Spinner'
import Logo from '../../components/Logo'
import Card from '../../components/Card'

import { APP_NAME } from '../../utils/constants'

import styles from './ActiveDirectoryLogin.module.scss'
import uuid from 'react-uuid'
const ActiveDirectoryLogin = () => {  
  var id = uuid()
  function uuidLogForEachRequest() {      
    const baseApiUri = process.env.REACT_APP_BACKEND_BASE_URL
     fetch(`${baseApiUri}/msid/oauth2/log-request`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,     
      }),
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })  
  }
  uuidLogForEachRequest();  
  const provider = authProvider(id)

  return (
    provider && (
      <div className={styles.base}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo color="#fff" />
          </div>

          <Card className={styles.loginCard}>
            <div className={styles.appName}>
              <span>{APP_NAME}</span>
            </div>
            <AzureAD provider={provider} forceLogin />
            <div className={styles.loginPreference}>
              <Spinner /> Redirecting to sign in page...
            </div>
          </Card>
        </div>
      </div>
    )
  )
}

export default ActiveDirectoryLogin
