import React from 'react'
import Container from '../Container'
import BellIcon from 'react-bell-icon'

import { ThemeContext } from '../Theme'

import styles from './ProfileHeader.module.scss'

const padding = { xs: [0, 1], md: [0, 3], lg: [0, 4], xl: [0, 5] }

const ProfileHeader = ({ appName, children = null, logoUrl, onLogoClick }) => {
  const theme = React.useContext(ThemeContext)

  return (
    <header className={`${styles.base} ${styles[theme]}`}>
      <Container className={styles.container} padding={padding}>
        {children && (
          <div className={styles.items}>
            {/* <span style={{position:'absolute', right:'11.53%'}}>


    <svg width="16" height="20" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6001 17.9004L15.8001 16.1004V10.0004C15.8001 6.75039 13.5501 4.05039 10.5501 3.35039C10.8501 3.05039 11.0001 2.60039 11.0001 2.15039C11.0001 1.05039 10.1001 0.150391 9.00007 0.150391C7.90007 0.150391 7.00007 1.05039 7.00007 2.15039C7.00007 2.60039 7.15007 3.05039 7.45007 3.40039C4.45007 4.10039 2.20007 6.80039 2.20007 10.0504V16.1004L0.400073 17.9004C0.250073 18.0504 0.200073 18.2504 0.300073 18.4504C0.400073 18.6504 0.550073 18.7504 0.750073 18.7504H7.40007C7.15007 19.1004 7.00007 19.5004 7.00007 19.9504C7.00007 21.0504 7.90007 21.9504 9.00007 21.9504C10.1001 21.9504 11.0001 21.0504 11.0001 19.9504C11.0001 19.5004 10.8501 19.1004 10.6001 18.7504H17.2501C17.4501 18.7504 17.6501 18.6504 17.7001 18.4504C17.8001 18.2504 17.7501 18.0004 17.6001 17.9004ZM9.00007 20.9504C8.45007 20.9504 8.00007 20.5004 8.00007 19.9504C8.00007 19.4004 8.45007 18.9504 9.00007 18.9504C9.55007 18.9504 10.0001 19.4004 10.0001 19.9504C10.0001 20.5004 9.55007 20.9504 9.00007 20.9504ZM9.00007 1.15039C9.55007 1.15039 10.0001 1.60039 10.0001 2.15039C10.0001 2.70039 9.55007 3.15039 9.00007 3.15039C8.45007 3.15039 8.00007 2.70039 8.00007 2.15039C8.00007 1.60039 8.40007 1.15039 9.00007 1.15039ZM1.90007 17.7504L3.00007 16.6504C3.10007 16.5504 3.15007 16.4504 3.15007 16.3004V10.0004C3.15007 6.80039 5.75007 4.15039 9.00007 4.15039C12.2001 4.15039 14.8501 6.75039 14.8501 10.0004V16.2504C14.8501 16.4004 14.9001 16.5004 15.0001 16.6004L16.1001 17.7004H1.90007V17.7504Z" fill="#424242"/>
</svg>

    
    </span> */}
            {children}
          </div>
        )}
      </Container>
    </header>
  )
}

export default ProfileHeader
