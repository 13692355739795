import toTitleCase from './../../../utils/toTitleCase'
function toolTipFormatter() {
  return `<div style="width:110%;background-color:white;">
    <div style="width:90%;font-size:14px;
    font-weight:700;font-family:Optum Sans;
    background-color:white;
    color:#002677;padding-left:10px;
    padding-top:10px;padding-right:5px;
    display:inline;">${toTitleCase(this.point.name)} UHC Coverage </div>

    <div style="padding-top:10px;display: inline-block;padding-right:10px;">
    
    <span style="font-weight:bold;font-size:14px">${this.point.value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}%</span>
    </div>

    <div style="width:110%;background-color:back;color:black;
    font-size:14px;padding-right:10px;">


    <div style="margin-left:10px;margin-top:10px;border-radius:0%;background-color:${this.color}; 
  display: inline-block; width:16px; height:16px;border:1px solid black"></div>

    <div style="width:90%;padding-left: 10px;padding-top:5px">

    

    <div style="width:90%;
   padding-top:2px;
   font-family:Optum Sans;
    font-size:14px;padding-right:10px;">
    <table>
    <tr>
      <td style="">EI : <span style="font-weight:bold;font-family:Optum Sans;">${this.point.ei_per.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )}%</span></td>
      <td style="padding-left:15px;">MR: <span style="font-weight:bold;font-family:Optum Sans;"> ${this.point.mr_per.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )}%</span></td>
      <td style="padding-left:15px;">CS: <span style="font-weight:bold;font-family:Optum Sans;">${this.point.cs_per.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )}%</span></td>
    </tr>
    </table>
    </div>
    <div style="width:90%;
    padding-top:10px;
    font-size:14px;padding-right:10px;padding-bottom:10px;">

    State Population: <span style="font-weight:bold;font-family:Optum Sans;">${
      this.point.population
    }</span>
    </div>
    </div>`
}

export default toolTipFormatter
