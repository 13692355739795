import React, { useState, useEffect } from 'react'
import MapLegend from './mapLegend'
import dataDisplayOptions from './dataDisplayOptions'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import EmptyMap from '../../../../components/Map/emptyMap'
import Map from '../../../../components/Map'
import toolTipFormatter from './toolTipFormatter'
const CovidComponent = ({stateJson,  covidData }) => {
    const {
        dataStartDate,
        dataEndDate,   
        mapOptions,    
      } = useGeoState()
    
    const { user } = useAuthState()
    const [errorCode, setErrorCode] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubject, setErrorSubject] = useState()
    const [errorOccured, seterrorOccured] = useState(false)
    const [covidStatesData, setCovidStatesData] = useState()
    const { data: covidStateData, error: covidStateDataError } = useGeoFetch(
        dataStartDate,
        dataEndDate,
        'covid',
        'accuracyreport/state'
      )
      useEffect(() => {
        if (covidStateData !== undefined || covidStateData === null) {
          if (covidStateData.response_code === 200) {
            setCovidStatesData(covidStateData.response_data)
            covidData(covidStateData.response_data)
          } else {
            setErrorCode(covidStateData.response_code)
            setErrorMessage(covidStateData.response_message)
            setErrorSubject(covidStateData.response_subject)
            seterrorOccured(true)
          }
        }
      }, [covidStateData])
    return (
        <>
        <MapLegend/>
        {covidStatesData !== undefined ? (
          <Map
            toolTipFormatter={toolTipFormatter}
            activeCbsasData={null}
            statesData={covidStatesData}
            cbsaData={null}
            mapOptions={mapOptions}
            dynamicMapTitle={true}
            mapType="COVIDACC"
            enableButtons={true}
            stateJson={stateJson}
          />
        ) : (
          <EmptyMap  stateJson={stateJson}/>
        )}
      </>
    )
}

export default CovidComponent