import React from 'react'
import GeoProvider from '../../../components/GeoProvider'
import Subscription from './Subscription'
const Subscriptions = () => {
  return (
    <GeoProvider showAddsubscribePop={false}>
      <Subscription></Subscription>
    </GeoProvider>
  )
}
export default Subscriptions
