const colorAxis = {
  dataClasses: [
                        
    {
        to: 0,
        color: '#D9D8D8',
    }, {
        from: 1,
        to: 10,
        color: '#CCDAFF',

    }, {
        from: 11,
        to: 20,
        color: '#B5C4EC',

    }
    , {
        from: 21,
        to: 30,
        color: '#879BC8',
    },
    {
        from: 31,
        to: 40,
        color: '#7086B6',


    },
    {
        from: 41,
        to: 50,
        color: '#5973A4',
    },
    {
        from: 51,
        to: 60,
        color: '#426093',
    }, {
        from: 61,
        to: 70,
        color: '#284D82',


    }, {
        from: 71,
        to: 80,
        color: '#003C71',
    },
    {
        from: 81,
        to: 90,
        color: '#D9D8D8',


    }
],
}
export default colorAxis
