import React from 'react'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import styles from './Vulnerability.module.scss'
import { useLocation } from 'react-router-dom'
import Header from './VulHeader'
import VulDashboard from './VulDashboard'
import VulCountyGraphPanel from './VulCountyGraphPanel'
import GeoProvider from '../../components/GeoProvider'
import dataDisplayOptions from '../../pages/Flu/FluAccuracy/dataDisplayOptions'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
import { startDate, endDate, displayDate } from '../../pages/Flu/dates'

const Vulnerability = () => {
  const { user } = useAuthState()
  const location = useLocation()
  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Vulnerability Dashboard',
    indicator_name: 'Vulnerability Dashboard',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  var hash = location.hash
  var stateCode = ''
  if (hash) {
    if (hash.split('-').length > 1) {
      stateCode = hash.split('-')[1].replace('#', '')
      hash = hash.split('-')[0].replace('#', '')
    }
  }
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.content}>
              <Header />
              <GeoProvider
                startDate={startDate}
                endDate={endDate}
                displayDate={displayDate}
                mapOptions={dataDisplayOptions[0]['options'][0]}
                vulCount={0}
              >
                <VulDashboard />

                {stateCode && hash === 'state' && (
                  <div>
                    <VulCountyGraphPanel stateCode={stateCode} />
                    {/* <AgeDistributionGraph  stateCode={stateCode}/> */}
                    {/* <GenderDistributionGraph stateCode={stateCode}/> */}
                  </div>
                )}
              </GeoProvider>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Vulnerability
