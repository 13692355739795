import React, { useState, useEffect } from 'react'
import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import TimelineSlider from './TimelineSlider'
import styles from './Timeline.module.scss'

const TimelineDisplay = ({
  statesData,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  relativeSlots = true,
  maxDate
}) => {
  
  const { displayDate, timelineIsPaused } = useGeoState()
  const keys = Object.keys(statesData).sort()
  const values = Object.values(statesData)
  const daysData = values.map((weeklyData, index) => {
    return { index, date: keys[index], ...weeklyData }
  })
  const maxDayIndex = daysData.length - 1

  const day = daysData.filter((w) => w.date === displayDate)[0]
  const [curIndex, setCurIndex] = useState(day.index)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!timelineIsPaused && curIndex < maxDayIndex) {
        setCurIndex((prevIndex) => prevIndex + 1)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineIsPaused])

  return (
    <div className={styles.base}>
      <div className={styles.slider}>
        <TimelineSlider
          days={daysData}
          latestPositionString={latestPositionString}
          slotString={slotString}
          slotTimeInSeconds={slotTimeInSeconds}
          markCounts={markCounts}
          curIndex={curIndex}
          setCurIndex={setCurIndex}
          relativeSlots={relativeSlots}
          maxDate={maxDate}
        />
      </div>
    </div>
  )
}

const TimelineSurveillance = ({
  statesData,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  loadError,
  relativeSlots = true,
  maxDate
}) => {
  const keys = Object.keys(statesData).sort()
  if (loadError) {
    return (
      <div className={styles.base}>
        <div className={styles.loading}>
          Error loading data to enable timeline.
        </div>
      </div>
    )
  }
  if (keys.length <= 1) {
    return (
      <div className={styles.base}>
        <div className={styles.loadingSpinner}>
          <Spinner />
        </div>
        <div className={styles.loading}>Loading data to enable timeline</div>
      </div>
    )
  } else {
    return (
      <TimelineDisplay
        statesData={statesData}
        latestPositionString={latestPositionString}
        slotString={slotString}
        slotTimeInSeconds={slotTimeInSeconds}
        markCounts={markCounts}
        relativeSlots={relativeSlots}
        maxDate={maxDate}
      />
    )
  }
}

export default TimelineSurveillance
