import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import getVectorImage from '../../utils/getVectorImage'
import styles from './UHCSideTable.module.scss'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
import ReactSwitch from 'react-switch'
import ExpandArrowRight from '../../assets/images/icon/arrow_expand_right.png'
import countingMethod from './../../utils/getCountingMethod'
import dataDisplayOptions from './dataDisplayOptions'
import dayjs from 'dayjs'
const Databind = ({ state, chartRef }) => {
  const {
    regionSelected,
  } = useGeoState()
  const history = useHistory()
  
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "metro" ? 1 :regionSelected === "zip3"? 2: 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent
      )
      point && chart.tooltip.refresh(point)
    }
  }

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  //let statename=state.geo_name
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '70%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.geo_name}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          
          data-title={state.geo_name}
          title={state.geo_name} 
          geo_id={state.geo_id}
        >
             {state.geo_name}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{countingMethod(state.population_count!==undefined?state.population_count:0,'fixed')}</div>
      <div className={styles.columnDays}>
          {' '}
          {state.state_per.toFixed(2)}%
      </div>
    </div>
  )
}

const FilterData=({coverageData,chartRef})=>{
  const {
    regionSelected,
    showUHCCoverage, setUHCCoverage ,
    showFullTable, setShowFullTable
  } = useGeoState()
  const handleChange = (val) => {
    setUHCCoverage(val)
  }
  const handleFullTableChange = () => {
    setShowFullTable(!showFullTable)
  }

  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      coverageData.sort((a, b) => b.population_count - a.population_count)
    } else if (action === 'down') {
      setfilter('up')
      coverageData.sort((a, b) => a.population_count - b.population_count)
    }
  }
  return (
    <div className={styles.base}>
      
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                   {regionSelected.toUpperCase()==='MSA'?'State & MSA':toTitleCase(regionSelected)}
                 
                </h5>
              </span>
            </div>
            <div  style={{
                display: 'inline-flex',
                width: '30%',
                flexDirection: 'row-reverse',
              }} >
              <img
                src={ExpandArrowRight}
                alt="expand-right"
                onClick={handleFullTableChange}
                style={{ height: '20px', width: '20px', marginBottom: '-3px',cursor:'pointer' }}
              ></img>
              </div>
          </div>
         
        </div>
       
          
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <span className={styles.showOnMap}>UHC Coverage </span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showUHCCoverage}
              onChange={handleChange}
            />
          </div>
       
        <div
          style={{
            padding: '1rem 0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '50%' }}>
          <h4>Area Name</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '30%',
              justifyContent: 'flex-middle',
              cursor: 'pointer',
            }}
          >
            <h4 onClick={() => sortBy(getFilter)}>
            <span style={{ marginLeft: '4px',cursor:'pointer' }}>
                {getVectorImage(getFilter)}
              </span>
              Pop.{' '}
             
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '18%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Pct. %</h4>
          </div>
        </div>
      </div>

      <div className={styles.scroll}>
        {coverageData.map((state) => {
          return (
            <Databind state={state} key={state.statename} chartRef={chartRef} />
          )
        })}
      </div>
    </div>
  )
}
const UHCSideTable = ({ stateData,coverageData, chartRef }) => {

 
  const sundayDate = dayjs().day(0).format('YYYY-MM-DD')
  let mapOptions=dataDisplayOptions[0]['options'][1]

  const stateDateData = Object.entries(coverageData[sundayDate])

   let filteredStateData = stateDateData.map((state) => {
      return {
        value: state[1].MR!==undefined?state[1].MR[mapOptions.value]:0,
        state_per: state[1].MR!==undefined?state[1].MR[mapOptions.value]:0,
        state_count: state[1].MR!==undefined?state[1].MR[mapOptions.stateCount]:0,
        mr: state[1].MR!==undefined?state[1].MR[mapOptions.lobValue]:0,
        mr_per: state[1].MR!==undefined?state[1].MR[mapOptions.lobPer]:0,
        ei: state[1].EI!==undefined?state[1].EI[mapOptions.lobValue]:0,
        ei_per: state[1].EI!==undefined?state[1].EI[mapOptions.lobPer]:0,
        cs: state[1].CS!==undefined? state[1].CS[mapOptions.lobValue]:0,
        cs_per: state[1].CS!==undefined?state[1].CS[mapOptions.lobPer]:0,
        population: countingMethod(state[1].MR!==undefined?state[1].MR[mapOptions.popCount]:0, 'fixed'),
        state: state[0],
        ...state[1].MR,
      }
    })
    
    return(
      <FilterData coverageData={filteredStateData} chartRef={chartRef} ></FilterData>
    )

}

export default UHCSideTable
