import useRequest from '../../utils/useRequest'

function useGeoFetch(startDate, endDate, entity, location, rsv) {  
  const { data, error } = useRequest({
    baseURL: rsv ? process.env.REACT_APP_BACKEND_BASE_URL_RSV: process.env.REACT_APP_BACKEND_BASE_URL,
    url: `/${entity}/${location}`,
    headers: {
      'Content-Type': 'application/json',
      requester: rsv? JSON.stringify(window.localStorage.getItem('requesterinc'))  : JSON.stringify(window.localStorage.getItem('requester')) 
    },
    params:
      location === 'activecbsa'  || location === 'activecounty'
        ? {}
        : {
            startDate: startDate,
            endDate: endDate,
          },
    withCredentials: true,    
  })

  return { data, error }
}

export default useGeoFetch
