import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import MapLegend from './mapLegend'
import { useGeoFetch } from '../../../components/GeoProvider'
import GeoProvider from '../../../components/GeoProvider'
import Map from '../../../components/Map'
import EmptyMap from '../../../components/Map/emptyMap'
import dataDisplayOptions from '../dataDisplayOptions'

import toolTipFormatter from './toolTipFormatter'

const CoverageMap = ({stateJson,chartRef}) => {
  const mapOptions = dataDisplayOptions[0]['options'][1]
  const [states, setStates] = useState()
  const today = dayjs().day(-7).format('YYYY-MM-DD') 
  const weekEndData = dayjs().day(-1).format('YYYY-MM-DD')

  // States Error Metric Data
  const { data: statesData, error: stateDataError } = useGeoFetch(
    '',
    '',
    'surveillance',
    'uhccoverage'
  )
  useEffect(() => {
    setStates(statesData)
  }, [statesData])
  if (stateDataError) {
    return '<h1>Error</h1>'
  }
  return (
    <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
      {states !== undefined ? (
        <>
          <MapLegend></MapLegend>
          <Map
            toolTipFormatter={toolTipFormatter}
            activeCbsasData={null}
            statesData={states}
            cbsaData={null}
            mapOptions={mapOptions}                       
            chartRef={chartRef}
            mapHeight="90vh"
             mapWidth=""
            mapType="COVERAGEMAP"
            dynamicMapTitle={false}
            mapTitleDisable={true}
            stateJson={stateJson}
            enableButtons={true}
          />
           
        
        </>
      ) : (
        <EmptyMap />
      )}
    </GeoProvider>
  )
}

export default CoverageMap
