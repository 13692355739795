import moment from 'moment'

function getPieChartOptions(
  toolTipFormatter,
  data,
  trendName,
  finalData,
  pieFor,
  height
) {
 
  
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor:
        pieFor === 'vaccination' || pieFor === 'vaccinationdetails'
          ? ''
          : '#f7f7f7',
      type: 'pie',
      height: height,
      fontFamily: 'Graphik',
    },
    title: {
      text:
        pieFor === 'vaccination' || pieFor === 'vaccinationdetails'
          ? '<span class="patientsCountValueChart">' +
            finalData[0].data +
            '</span> <br/> <span class="patientsCount">Claims Count</span>'
          : pieFor === 'vul'
          ? '<span class="patientsCountValueChart">' +
            finalData[0].data +
            '</span> <br/> <span class="patientsCount">Sample Count</span>'
          : pieFor === 'vulPopulation'
          ? '<span class="patientsCountValueChart">' +
            finalData[0].data +
            '</span> <br/> <span class="patientsCount">Population Count</span>'
          : 'Majorly Contributing Factors',
      align:
        pieFor === 'vaccination' ||
        pieFor === 'vaccinationdetails' ||
        pieFor === 'vul' ||
        pieFor === 'vulPopulation'
          ? 'center'
          : 'top',
      verticalAlign:
        pieFor === 'vaccination' ||
        pieFor === 'vaccinationdetails' ||
        pieFor === 'vul' ||
        pieFor === 'vulPopulation'
          ? 'middle'
          : 'top',
      y: 20,
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
      itemMarginTop: 5,
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    tooltip: {
      xDateFormat: '%b  %d,  %Y',
      shared: true,
      valueSuffix: '%',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        // shadow: false,
        // center: ['50%', '50%'],

        cursor: 'pointer',
        dataLabels: {
          enabled: true,

          formatter: function () {
            return this.y + '%'
            //return  '<b>' + this.point.name + ':</b> ' +    this.y + '%' ;
          },
        },
        showInLegend: true,
        size: 200,
      },
    },
    series: [
      {
        colors: [
          '#003C71',
          '#426093',
          '#7086B6',
          '#9EAFDA',
          '#9BADD8',
          '#003C71',
        ],
        name: '',
        colorByPoint: true,
        innerSize: '75%',
        data: finalData[1].data,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            //maxWidth: 1200
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              padding:-1,
              verticalAlign: 'right',
              // useHTML: true,
              // fontFamily: 'graphik',
              // labelFormatter: function () {
              //   return this.name + ': ' + this.y + '%'
              // },
              labelFormatter: function () {
                let url =''
                if (pieFor === 'vaccination' )
                {
                   url =  '<a href="https://www.aapc.com/codes/cpt-codes/' + this.name + '" target="_blank" rel="noopener noreferrer">' + this.name + ' : </a>' + this.y + '%';       
                }
                else{
                  url = this.name + ': ' + this.y + '%'
                }
                      
              return    (
                url
              )          
            }
            },
          },
        },
      ],
    },
  }
}

export default getPieChartOptions
