import React from 'react'
import { Link } from 'react-router-dom'

import Breadcrumbs from '../Breadcrumbs'
import { RouteContext } from '../RouteProvider'
import { useAuthState } from '../../components/AuthProvider'

const DefaultBreadcrumbs = () => {
  const { breadcrumbs } = React.useContext(RouteContext)
  const { user } = useAuthState()

  return (
    <Breadcrumbs>
      {breadcrumbs
        .filter((route) => route.authorizedRoles.includes(user.role))
        .map((route, index) => {
          const isLast = index === breadcrumbs.length - 1
          if (isLast) {
            return (
              <Breadcrumbs.Item key={route.label} label={route.label} last />
            )
          } else {
            return (
              <Breadcrumbs.Item
                key={route.label}
                render={() => <Link to={route.path}>{route.label}</Link>}
              />
            )
          }
        })}
    </Breadcrumbs>
  )
}

export default DefaultBreadcrumbs
