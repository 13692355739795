import colorAxisCasesWeeklyHrr from './colorAxisCasesWeeklyHrr'
import legend from './legend'
import { toolTipFormatterWeeklyAdmissions } from './toolTipFormatter'
import colorAxisCasesWeekly from './colorAxisCasesWeekly' //added by Avanish
const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'hospital_admissions',
        label: 'Weekly Hospitalizations',
        parent: 'Covid Hospitalization',
        toolTip: toolTipFormatterWeeklyAdmissions,
        colorAxis: colorAxisCasesWeekly,
        countyColorAxis: colorAxisCasesWeeklyHrr,
        mapTitle: 'Weekly Hospital Admissions',
        mapSubTitle: undefined, //'Source - John Hopkins and other sources',
        legend: {
          ...legend,
          title: {
            text: 'Weekly Hospitalizations',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
