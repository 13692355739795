import React, { useState, useEffect  } from 'react'
import styles from './DashboardHeader.module.scss'
import countingMethod from './../../utils/getCountingMethod'
import Dropdown from './Dropdown'
import Moment from 'moment';
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import CountPanel from './CountPanel';
import Spinner from '../../components/Loader/Spinner';
import Tooltip from '../../components/Tooltip'
import Content from '../../components/Content'
import dropdown_arrow from '../../assets/images/dropdown-arrow.png';
const longText = `
 `
 const DashboardHeader = ({getfilterts, typeData,lobData,seasonData,monthData , setMemberVaccinatedData}) => {
  const [getIstrue, setIstrue] = useState(true)
  
  const [getData, setData] = useState()  
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const dataType = [
    {
      value: 2,
      label: "Pharmacy"
    },
    {
      value: 1,
      label: "Medical"
    }
  ];
  const dataLineOfBusiness = [
    {
      value: 1,
      label: "CS"
    },
    {
      value: 2,
      label: "EI"
    },
    {
      value: 3,
      label: "MR"
    }
  ];

 const defaultDataFluSeason = [
    {
      value: 11,
      label: "2023-24"
    },
  ]
  const dataFluSeason = [
    {
      value: 11,
      label: "2023-24"
    },
    {
      value: 10,
      label: "2022-23"
    },
    {
      value: 9,
      label: "2021-22"
    },
    {
      value: 8,
      label: "2020-21"
    },
    {
      value: 7,
      label: "2019-20"
    },
    {
      value: 6,
      label: "2018-19"
    },
    {
      value: 5,
      label: "2017-18"
    },
    {
      value: 4,
      label: "2016-17"
    },
    {
      value: 3,
      label: "2015-16"
    },
    {
      value: 2,
      label: "2014-15"
    },    
  ];
  const dataMonth = [
    {
      value: 1,
      label: "January"
    },
    {
      value: 2,
      label: "Febuary"
    },
    {
      value: 3,
      label: "March"
    },
    {
      value: 4,
      label: "April"
    },
    {
      value: 5,
      label: "May"
    },
    {
      value: 6,
      label: "June"
    },
    {
      value: 7,
      label: "July"
    },
    {
      value: 8,
      label: "August"
    },
    {
      value: 9,
      label: "September"
    },
    {
      value: 10,
      label: "October"
    },
    {
      value: 11,
      label: "November"
    },
    {
      value: 12,
      label: "December"
    }
  ];
  const [isHovering, setIsHovering] = useState("hidden");

 
  const handleMouseOver = () => {
    setIsHovering("visible")
   
  };
  const handleMouseOut = () => {
    setIsHovering("hidden")
  };
 
  // set monday 
  const date = new Date();
  const previousMonday = new Date();
  previousMonday.setDate(date.getDate() - ((date.getDay() + 6) % 7));
  // 👇️ Reset hours, minutes, seconds, milliseconds to `0`
  previousMonday.setHours(0, 0, 0, 0);
  const MonDate = previousMonday

  const entity='FluVaccine'
  const location='VaccineDataCount'
  const startDate=''
  const endDate=''
  const month = getfilterts.month
  const fluseason = getfilterts.season
  const state= getfilterts.state
  const fluFolder = 'VaccineDataCount'
  const lob =getfilterts.lob
  const type=getfilterts.type
  const filterId =''
  var isture =true

if (type=='1' || type=='2')
{  
  isture=false

}
  const { data, error } = useGeoFetch(startDate, endDate, entity
  
     ,
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`) 
  useEffect(() => {
    if (data !== undefined && data !== null) {
      if (data.response_code === 200) { 
        setData(data.response_data)       
      } else {
        setErrorCode(data.response_code)
        setErrorMessage(data.response_message)
        setErrorSubject(data.response_subject)
        seterrorOccured(true)
      }
    }
  }, [data])

  
  if (errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }  
  
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>
          Vaccination Demographics  
          {/* <Tooltip placement="bottom">
            <Content maxWidth="300px" fontSize="11px">
              <p>{longText}</p>
            </Content>
          </Tooltip> */}
        </h4>
        <p >
        Flu Vaccinations administered, sourced from Pharmacy and Medical Claims Data
         ( last  <span>updated on {Moment(MonDate).format('MMM')} {Moment(MonDate).format('DD')}, {Moment(MonDate).format('YYYY')} </span>)
        </p>
      </div>
      
      {getData ? <CountPanel data={getData}  getIstrue={isture} /> : <Spinner/>}     
      <div  onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} className={styles.filterPanel}>
        <div style={{ paddingRight: 10, borderRight: '1px solid grey', width:'205px' }}>
        <span id="filter-text1">Filters Available</span>
        <br />
        <span id="filter-text" >
          <span id="list_type">Vaccine Type, LOB +2 more</span>
        </span>
        </div>
         <img id="open-filter" className={styles.arrow} src={dropdown_arrow}/>
      
          <div style={{visibility: isHovering}} className={styles.filterdivpopup}>
            <div>
              <div>Type</div>
              <div><Dropdown  dataType='type' typeData={typeData} data={dataType}  /></div>
            </div>
            <div>

              <div>Line of business</div>
              <div><Dropdown dataType='lob' lobData={lobData}  data={dataLineOfBusiness} /></div>
            </div>
            <div>

              <div>Flu Season</div>
              <div><Dropdown dataType='season' defaultdata={defaultDataFluSeason}  seasonData={seasonData} data={dataFluSeason}  /></div>
            </div>
            <div>

              <div>Month</div>
              <div><Dropdown dataType='month' monthData={monthData} data={dataMonth} /></div>

            </div>
          </div>
  
      </div>


    </div>
  )
}

export default DashboardHeader
