const FormulaMAPE = ({ color = '#000000' }) => {
  return (
    <>
      <p
        style={{
          marginBottom: '0px',
          'line-height': '18px',
          'padding-top': '10px',
        }}
      >
        <strong>MAPE : </strong> also known as mean absolute percentage
        deviation (MAPD), is a measure of prediction accuracy of a forecasting
        method in statistics.
        <svg viewBox="0 0 900.000000 258.000000" focusable="false">
          <g
            transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)"
            fill={color}
            stroke="none"
          >
            <path
              d="M4190 2432 c-8 -2 -27 -16 -42 -30 -21 -19 -25 -29 -17 -39 9 -10 15
-10 32 2 32 22 50 19 44 -7 -18 -73 -30 -115 -39 -134 -15 -33 -6 -69 19 -72
26 -4 36 13 53 88 15 71 35 111 65 132 21 16 26 16 38 4 12 -13 12 -22 1 -68
-8 -29 -14 -69 -14 -89 0 -36 20 -69 41 -69 13 0 61 31 76 50 28 35 4 53 -37
28 -25 -16 -36 -4 -23 25 15 32 27 130 19 155 -13 39 -91 35 -123 -6 -12 -16
-14 -16 -30 4 -21 24 -42 33 -63 26z"
            />
            <path
              d="M5513 2193 c-10 -4 -13 -79 -13 -329 0 -348 0 -351 49 -324 21 10 21
17 21 330 0 188 -4 320 -9 320 -5 0 -15 2 -23 4 -7 3 -19 2 -25 -1z"
            />
            <path
              d="M7881 2191 c-11 -7 -14 -65 -14 -326 0 -333 2 -347 44 -329 21 9 21
13 21 328 0 255 -3 320 -14 327 -16 11 -20 11 -37 0z"
            />
            <path
              d="M3495 2119 c-4 -5 -32 -22 -63 -36 -58 -26 -72 -38 -72 -61 0 -23 28
-24 58 -2 17 12 35 19 41 16 16 -11 15 -330 -2 -344 -6 -6 -28 -12 -47 -14
-27 -2 -35 -7 -35 -23 0 -19 7 -20 135 -20 128 0 135 1 135 20 0 16 -8 21 -40
23 -26 2 -44 9 -50 20 -6 10 -8 96 -5 212 3 107 1 201 -4 208 -10 15 -42 16
-51 1z"
            />
            <path
              d="M7288 2092 c-46 -37 -73 -76 -63 -92 3 -5 -5 -14 -17 -21 -30 -16
-57 -51 -51 -66 7 -18 21 -16 50 7 14 11 29 18 33 15 4 -2 15 -44 24 -92 10
-49 21 -102 27 -119 16 -52 10 -75 -27 -121 -33 -40 -40 -44 -69 -39 -24 5
-36 2 -49 -13 -29 -32 -17 -56 32 -59 25 -2 42 1 42 7 0 6 6 11 14 11 20 0 80
72 147 175 33 50 62 95 66 100 5 6 18 33 31 60 13 28 30 63 38 78 14 25 14 33
-2 82 -9 30 -19 55 -23 55 -3 0 -24 16 -45 35 -54 48 -94 47 -158 -3z m138
-49 c49 -32 53 -38 24 -46 -21 -5 -26 -32 -11 -56 16 -25 -59 -216 -82 -208
-7 2 -13 23 -15 48 -4 75 -34 183 -54 197 -27 20 -22 34 25 64 52 34 59 34
113 1z"
            />
            <path
              d="M3840 1986 c-16 -16 -12 -30 18 -62 16 -16 111 -139 212 -274 101
-135 192 -254 202 -264 35 -39 32 -53 -29 -131 -32 -41 -68 -89 -81 -107 -13
-18 -32 -42 -43 -54 -10 -11 -19 -24 -19 -29 0 -4 -7 -15 -15 -24 -45 -46
-265 -357 -263 -370 3 -14 50 -16 422 -19 324 -2 421 1 427 10 4 7 8 44 8 83
0 39 5 83 10 99 19 47 14 73 -14 80 -14 4 -30 4 -34 1 -5 -2 -15 -34 -22 -70
-7 -36 -20 -72 -28 -79 -13 -12 -75 -15 -324 -18 -345 -4 -337 -6 -269 67 20
22 54 63 74 90 21 28 47 62 58 76 29 39 66 89 93 129 14 19 32 41 41 49 9 7
16 18 16 24 0 6 7 17 15 26 30 30 105 141 105 153 0 7 -14 29 -31 48 -17 19
-39 46 -47 60 -33 51 -309 416 -325 429 -9 7 -14 17 -11 22 3 5 131 9 285 9
l280 0 24 -26 c21 -23 30 -48 38 -114 2 -10 13 -15 32 -15 l30 0 1 94 c0 65
-4 97 -13 107 -11 10 -91 13 -413 11 -249 -1 -404 -5 -410 -11z"
            />
            <path
              d="M5720 1982 c-50 -33 -63 -65 -33 -76 11 -5 22 -1 31 11 19 27 45 4
55 -48 4 -24 14 -74 22 -110 22 -97 19 -111 -22 -159 -34 -39 -40 -41 -74 -36
-40 6 -49 -3 -49 -50 0 -50 94 -24 152 42 77 87 238 362 238 406 0 25 -23 38
-67 38 -48 0 -50 -3 -28 -47 16 -32 10 -57 -34 -158 -5 -11 -12 -28 -15 -37
-7 -22 -32 -23 -40 -2 -3 9 -9 48 -13 87 -12 137 -55 185 -123 139z"
            />
            <path
              d="M6494 1856 c-3 -14 -4 -29 -1 -33 3 -5 101 -8 219 -8 l213 0 0 30 0
30 -212 3 -213 2 -6 -24z"
            />
            <path
              d="M6129 1813 c-35 -42 -48 -70 -37 -81 8 -8 8 -19 -1 -39 -7 -15 -15
-58 -18 -95 -7 -76 9 -108 54 -108 35 0 103 46 103 70 0 24 -22 26 -56 4 -41
-27 -49 -8 -33 83 l14 78 53 3 c44 3 53 6 50 20 -2 12 -14 17 -38 18 -31 0
-36 4 -40 29 -6 35 -30 43 -51 18z"
            />
            <path
              d="M7604 1795 c-8 -19 -19 -35 -25 -35 -6 0 -8 -11 -4 -28 3 -16 1 -49
-6 -73 -23 -91 -23 -118 2 -145 12 -13 33 -24 46 -24 40 0 103 47 103 77 0 18
-38 16 -57 -2 -36 -36 -46 8 -26 115 l8 45 48 3 c39 3 48 6 45 20 -2 11 -15
18 -36 20 -28 3 -32 7 -32 33 0 24 -4 29 -26 29 -19 0 -28 -8 -40 -35z"
            />
            <path
              d="M355 1579 c-4 -5 1 -19 11 -30 16 -18 16 -26 6 -82 -7 -34 -17 -86
-23 -116 -5 -29 -14 -61 -18 -70 -5 -9 -12 -40 -16 -69 -5 -38 -13 -57 -31
-71 -43 -34 -26 -51 49 -51 62 0 75 12 50 44 -17 21 -19 33 -12 63 19 85 39
202 39 229 0 16 4 36 10 44 8 13 10 13 14 0 8 -21 36 -238 36 -277 0 -17 4
-34 8 -37 13 -8 52 5 52 16 0 6 26 54 58 107 32 53 62 103 66 111 37 75 44 85
51 67 4 -9 4 -27 1 -40 -20 -64 -56 -214 -56 -230 0 -11 -14 -32 -30 -47 -30
-28 -30 -29 -11 -39 28 -15 104 -14 126 3 18 13 18 14 -3 25 -26 14 -27 35 -8
105 16 56 42 179 51 240 4 21 17 49 31 63 36 36 25 53 -34 53 -48 0 -50 -1
-82 -52 -19 -29 -43 -69 -54 -88 -11 -19 -30 -53 -43 -75 -12 -22 -28 -52 -34
-67 -6 -16 -19 -28 -29 -28 -15 0 -18 14 -24 108 -11 169 -15 189 -39 196 -36
10 -105 7 -112 -5z"
            />
            <path
              d="M1133 1578 c-6 -7 -18 -29 -28 -48 -10 -19 -21 -37 -24 -40 -4 -3
-12 -16 -18 -30 -7 -14 -26 -47 -42 -75 -16 -27 -42 -72 -56 -100 -49 -93 -60
-110 -95 -143 -29 -27 -32 -34 -20 -42 17 -11 100 -13 124 -4 21 8 20 24 -1
39 -15 12 -15 15 1 49 22 46 42 56 114 56 55 0 60 -2 66 -25 10 -38 -3 -71
-32 -85 -26 -11 -26 -12 -7 -26 29 -22 145 -17 145 6 0 4 -8 17 -18 28 -15 17
-21 47 -27 144 -20 324 -17 303 -46 306 -14 2 -30 -3 -36 -10z m14 -282 c-7
-19 -102 -23 -113 -5 -3 6 2 23 13 37 10 15 27 47 38 72 10 25 26 51 34 58 14
12 17 4 25 -67 5 -44 6 -87 3 -95z"
            />
            <path
              d="M1410 1580 c-12 -8 -12 -11 3 -28 21 -23 21 -49 3 -121 -17 -69 -34
-151 -45 -214 -5 -31 -17 -57 -37 -78 -36 -38 -23 -49 57 -49 66 0 96 20 58
40 -19 11 -21 17 -15 66 3 29 11 65 18 79 10 23 16 25 77 25 113 0 175 60 175
173 1 52 -2 60 -29 81 -17 14 -43 27 -59 30 -45 9 -189 7 -206 -4z m204 -65
c30 -28 33 -53 10 -100 -20 -43 -52 -64 -108 -71 -50 -7 -61 2 -45 43 6 15 15
50 20 77 5 27 14 55 20 63 19 22 73 16 103 -12z"
            />
            <path
              d="M1850 1580 c-12 -8 -11 -12 7 -32 19 -21 21 -28 12 -63 -6 -22 -14
-62 -19 -90 -4 -27 -12 -61 -18 -75 -5 -14 -12 -48 -16 -77 -7 -58 -25 -97
-52 -118 -14 -11 -16 -17 -7 -23 18 -12 280 -14 302 -2 24 12 49 106 33 122
-14 14 -62 -16 -62 -37 0 -23 -54 -45 -111 -45 l-51 0 7 48 c12 85 25 122 43
123 40 3 68 -3 78 -17 15 -19 41 -18 53 4 5 9 12 43 16 75 7 52 6 57 -11 57
-11 0 -27 -14 -36 -30 -15 -26 -22 -30 -63 -30 -50 0 -51 2 -30 69 8 25 17 59
20 74 6 28 6 28 73 25 l67 -3 5 -35 c4 -31 8 -35 35 -35 29 0 30 2 37 55 4 38
3 57 -5 62 -17 11 -289 9 -307 -2z"
            />
            <path
              d="M2445 1400 c-3 -6 -4 -18 0 -29 5 -17 20 -18 217 -17 191 1 212 3
222 19 8 14 8 20 -4 27 -21 13 -427 13 -435 0z"
            />
            <path
              d="M3493 1332 c-191 -2 -202 -3 -208 -22 -3 -11 -3 -24 0 -30 7 -11 402
-15 429 -4 21 8 20 41 -1 51 -10 4 -109 7 -220 5z"
            />
            <path
              d="M4878 1323 c-5 -7 -8 -20 -6 -30 3 -17 81 -18 1839 -21 1947 -2 1880
-4 1846 41 -11 16 -144 17 -1840 20 -1414 1 -1831 -1 -1839 -10z"
            />
            <path
              d="M2445 1229 c-4 -12 -2 -26 3 -31 8 -8 289 -13 425 -8 4 0 7 14 7 30
l0 30 -214 0 c-209 0 -215 -1 -221 -21z"
            />
            <path
              d="M6452 1133 c-91 -68 -137 -185 -129 -330 7 -139 38 -210 121 -278 33
-26 52 -36 73 -33 43 5 37 30 -15 63 -73 47 -112 141 -112 271 0 139 44 239
122 278 32 17 46 44 28 56 -24 15 -35 12 -88 -27z"
            />
            <path
              d="M7253 1163 c-10 -4 -13 -77 -13 -321 0 -174 4 -323 9 -330 4 -8 19
-12 32 -10 l24 3 3 324 c2 253 -1 326 -10 333 -14 9 -27 9 -45 1z"
            />
            <path
              d="M8115 1159 c-5 -7 -10 -564 -6 -646 1 -16 47 -17 56 -3 4 6 7 153 7
327 0 253 -3 317 -14 324 -18 12 -36 11 -43 -2z"
            />
            <path
              d="M8281 1161 c-21 -14 -7 -36 41 -62 28 -16 50 -39 66 -68 28 -54 56
-185 48 -231 -13 -78 -27 -137 -41 -165 -17 -36 -73 -90 -104 -100 -33 -10
-23 -39 15 -43 25 -2 41 6 82 43 91 83 136 229 112 361 -23 125 -68 201 -151
252 -40 24 -48 26 -68 13z"
            />
            <path
              d="M5635 956 c-73 -34 -125 -127 -125 -224 0 -84 28 -122 92 -122 28 0
44 7 67 31 32 31 51 31 51 -1 0 -35 63 -41 104 -9 14 11 26 27 26 34 0 23 -20
28 -41 9 -17 -16 -22 -16 -29 -4 -13 19 -12 25 4 90 40 155 46 182 40 191 -3
5 -22 9 -42 8 -20 0 -55 2 -77 5 -25 4 -51 1 -70 -8z m105 -61 c20 -56 -55
-213 -111 -230 -51 -16 -67 54 -35 151 23 71 59 106 103 102 25 -2 38 -9 43
-23z"
            />
            <path
              d="M6182 962 c-14 -2 -37 -22 -55 -48 -36 -52 -57 -53 -70 -4 -11 42
-21 50 -65 50 -23 0 -40 -8 -57 -26 -14 -15 -25 -31 -25 -36 0 -17 31 -20 49
-4 10 9 21 16 25 16 13 0 36 -69 36 -108 0 -31 -7 -45 -39 -76 -24 -23 -46
-36 -54 -33 -8 3 -24 -1 -36 -10 -27 -18 -25 -66 2 -71 37 -7 63 9 101 63 39
54 55 58 56 12 0 -10 7 -31 15 -47 13 -25 22 -30 53 -30 45 0 97 45 86 73 -8
21 -14 21 -42 1 -12 -8 -28 -14 -34 -12 -12 4 -38 80 -38 113 0 34 70 95 108
95 19 0 32 5 32 13 0 6 3 22 6 34 7 25 -18 41 -54 35z"
            />
            <path
              d="M6715 962 c-74 -26 -100 -77 -64 -125 l22 -30 -24 -18 c-68 -52 -76
-66 -63 -114 17 -64 146 -87 217 -40 21 15 37 34 37 45 0 26 -26 26 -68 0 -41
-25 -84 -26 -105 -2 -23 25 -21 34 9 63 17 16 41 25 77 29 47 5 52 7 52 30 0
23 -4 25 -47 28 -41 3 -49 7 -54 27 -16 66 59 90 111 35 22 -23 32 -27 42 -19
14 12 18 75 5 83 -14 9 -127 15 -147 8z"
            />
            <path
              d="M7674 956 c-3 -8 1 -25 10 -38 13 -21 13 -29 -6 -83 -52 -149 -79
-158 -94 -30 -5 50 -18 105 -27 123 -15 28 -23 32 -57 32 -45 0 -80 -27 -80
-60 0 -24 22 -26 40 -5 7 8 16 15 20 15 10 0 28 -46 36 -95 3 -22 13 -65 20
-95 21 -81 18 -101 -22 -145 -30 -35 -41 -40 -77 -40 -41 0 -42 -1 -42 -35 0
-31 3 -35 28 -38 57 -7 114 48 219 208 65 100 112 186 129 237 10 32 10 38 -7
50 -24 17 -83 17 -90 -1z"
            />
            <path
              d="M3318 935 c-27 -23 -35 -45 -22 -58 8 -7 54 13 54 25 0 4 7 8 15 8
20 0 20 -61 -1 -131 -9 -30 -22 -76 -30 -102 -17 -56 -13 -67 25 -67 38 0 47
14 55 85 9 81 28 133 66 177 35 43 74 51 83 18 3 -11 -2 -48 -10 -82 -22 -93
-27 -166 -13 -183 33 -40 140 1 140 53 0 27 -6 27 -48 2 -28 -17 -33 -18 -38
-5 -4 8 1 38 10 66 23 69 32 170 17 197 -9 18 -21 22 -59 22 -40 0 -51 -4 -72
-30 -30 -37 -36 -37 -52 -2 -19 38 -81 43 -120 7z"
            />
            <path
              d="M4913 937 c-40 -27 -44 -57 -8 -57 14 0 28 7 31 15 8 21 31 19 38 -3
4 -10 -3 -54 -14 -97 -11 -43 -20 -85 -20 -94 0 -9 -7 -29 -15 -45 -19 -36 -9
-49 36 -44 34 3 34 4 41 63 4 33 12 71 17 85 5 14 12 37 15 52 8 35 70 98 97
98 11 0 22 -7 26 -16 6 -16 -10 -110 -37 -213 -14 -56 -8 -71 29 -71 27 0 31
4 36 38 15 90 29 149 43 177 35 73 103 110 117 65 6 -18 2 -38 -33 -164 -14
-52 -14 -61 -1 -85 11 -20 23 -27 55 -29 33 -2 44 2 69 28 23 24 26 33 17 42
-10 10 -17 10 -32 0 -11 -6 -27 -12 -36 -12 -19 0 -17 14 15 135 23 83 22 113
-5 139 -23 23 -99 21 -116 -3 -20 -28 -57 -36 -65 -14 -16 45 -84 46 -133 3
-35 -31 -45 -30 -52 5 -5 30 -73 31 -115 2z"
            />
            <path
              d="M7872 790 c-9 -11 -28 -32 -41 -45 -20 -21 -21 -25 -7 -31 17 -7 21
-34 7 -50 -4 -5 -14 -37 -20 -72 -15 -77 -2 -112 46 -126 28 -8 39 -5 73 19
28 19 40 35 40 51 0 29 -9 30 -49 7 -47 -28 -56 -10 -41 74 15 80 24 95 59 92
46 -3 61 1 61 16 0 15 -7 17 -60 19 -21 1 -24 5 -22 27 4 35 -23 45 -46 19z"
            />
            <path
              d="M6948 698 c0 -7 -2 -34 -2 -60 -1 -29 -8 -53 -19 -66 -19 -21 -20
-63 -1 -69 22 -7 83 68 93 115 13 62 1 92 -39 92 -16 0 -31 -6 -32 -12z"
            />
            <path
              d="M3933 495 c-18 -22 -28 -48 -29 -72 -1 -21 -9 -68 -18 -105 -18 -79
-10 -113 31 -129 44 -17 123 23 123 63 0 22 -33 24 -50 3 -15 -18 -36 -20 -45
-4 -8 12 13 134 27 162 6 11 20 17 42 17 33 0 62 20 49 33 -3 4 -21 7 -38 7
-28 0 -33 4 -38 30 -8 39 -20 38 -54 -5z"
            />
            <path
              d="M4553 489 c-41 -20 -63 -37 -63 -48 0 -28 10 -31 42 -15 19 10 34 12
40 6 5 -5 7 -51 6 -103 l-3 -94 -30 -5 c-24 -4 -30 -10 -30 -30 0 -21 4 -25
25 -22 14 1 45 3 70 3 25 0 56 -2 70 -3 21 -3 25 1 25 22 0 20 -6 26 -30 30
l-30 5 -3 143 c-2 104 -6 142 -15 142 -6 0 -40 -14 -74 -31z"
            />
            <path
              d="M4122 434 c-12 -8 -12 -15 -3 -33 12 -21 17 -21 153 -19 121 3 142 5
152 20 21 34 -6 41 -150 42 -84 1 -143 -3 -152 -10z"
            />
            <path
              d="M4116 312 c-3 -5 -2 -18 1 -31 5 -21 8 -21 157 -19 l151 3 0 25 0 25
-152 3 c-83 1 -154 -1 -157 -6z"
            />
          </g>
        </svg>
      </p>

      <p style={{ marginBottom: '0px' }}>
        ϵ is an arbitrary small yet strictly positive number to avoid undefined
        results when y is zero, y_t is the actual value, y ̂_t is the predicted
        value and n is the number of observations. Closer to 0 the better it is.
      </p>
    </>
  )
}

export default FormulaMAPE
