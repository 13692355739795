import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import styles from './Thal.module.scss'
import Spinner from '../../components/Loader/Spinner'
import CustomDataTable from './CustomDataTable'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
// import data1 from './data'
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`

// const ClearButton = styled(Button)`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    {/* <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton> */}
  </>
)

function toCamelCase(str) {
  var finalstring = ''
  var strn = str.split(' ')
  for (var i = 0; i < strn.length; i++) {
    finalstring +=
      strn[i]
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase()
        })
        .replace(/\s+/g, '') + (i === strn.length - 1 ? '' : ' ')
  }
  return finalstring
}

const columns = [
  {
    name: 'Patient Name',
    Header: 'Patient Name',
    width: '26%',
    //accessor: 'first_name',
    // selector: d => <table className={styles.alignText}><tr className={styles.nameHeader}><th>{d.first_name}{d.last_name}</th></tr><tr><th className={styles.formatDOB}>Date of Birth</th><th className={styles.formatSex}>Sex</th><th className={styles.formatRace}>Race</th></tr>
    //   <tr><td>{d.dob}</td><td>{d.gender}</td><td>{d.race}</td></tr>
    // </table>,
    selector: (row) => (
      <div>
        <div>
          <span style={{ fontSize: '14px' }}>
            {toCamelCase(row.first_name)} {toCamelCase(row.last_name)}
          </span>
        </div>
        <div className={styles.maindiv}>
          <div className={styles.rowdiv} style={{ width: '100px' }}>
            <div>Date of Birth</div>
            <div className={styles.datadiv}>{row.dob}</div>
          </div>
          <div className={styles.rowdiv} style={{ width: '71px' }}>
            <div>Sex</div>
            <div className={styles.datadiv}>{toCamelCase(row.gender)}</div>
          </div>
          <div className={styles.rowdiv} style={{ width: '130px' }}>
            <div>Race</div>
            <div className={styles.datadiv}>{toCamelCase(row.race)}</div>
          </div>
        </div>
      </div>
    ),
  },
  {
    name: 'Risk Level',
    Header: 'Risk Level',
    selector: (d) => d.probability,
    width: '14%',
    sortable: true,
  },
  {
    name: 'Clinical Values',
    Header: 'Clinical Values',
    width: '25%',
    // selector: d => <table className={styles.alignText}><tr><th>Transfusions</th></tr>
    //   <tr><th>No of Transfusions</th><td>{d.transfusion_counte}</td></tr>
    //   <tr><th >Last Transfusion</th><td>{d.last_transfusion}</td></tr>
    //   <tr><th>Hydroxyurea</th><td>{d.hydroxyurea}</td></tr>
    // </table>,

    selector: (row) => (
      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
        <span className={styles.captionText}>Transfusions</span>
        <div>
          <div className={styles.rowdivCli}>
            {' '}
            No of Transfusions{' '}
            <span className={styles.datadivCli}>
              {toCamelCase(row.transfusion_count)}
            </span>
          </div>
          <div className={styles.rowdivCli}>
            Last Transfusion{' '}
            <span className={styles.datadivCli}>
              {toCamelCase(row.last_transfusion)}
            </span>
          </div>
          <div className={styles.rowdivCli}>
            Hydroxyurea{' '}
            <span className={styles.datadivCli}>{row.hydroxyurea}</span>
          </div>
        </div>
      </div>
    ),
  },
  {
    Header: '  ',
    // selector: d => <table className={styles.alignText}><tr><th>Additional Details</th></tr>
    //   <tr><th>Provider Name</th><td>{d.provider_first_name}  {d.provider_last_name}</td></tr>
    //   <tr><th>NPI</th><td>{d.npi}</td></tr>  <tr><th>  </th><th></th></tr>
    // </table>,

    selector: (row) => (
      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
        <span className={styles.captionText}>Additional Details</span>
        <div>
          <div className={styles.rowdivCli}>
            {' '}
            Provider Name{' '}
            <span className={styles.datadivCli}>
              {toCamelCase(row.provider_first_name)}{' '}
              {toCamelCase(row.provider_last_name)}
            </span>
          </div>
          <div className={styles.rowdivCli}>
            NPI <span className={styles.datadivCli}>{row.npi}</span>
          </div>
          <div className={styles.rowdivCli}> </div>
        </div>
      </div>
    ),
  },
]
const ExpandedComponent = ({ data }) => (
  <div className={styles.expandFormat}>
    <ul className={styles.ulexpandFormat}>
      <div className={styles.expanHeaderText}>
        Reasons why this Patient was Identified as High Risk
      </div>
      <div className={styles.expanBodyText}>
        (Ordered in Ascending order of Most Important to Least Important)
      </div>
      <li>{toCamelCase(data.top_code_1)}</li>
      <li>{toCamelCase(data.top_code_2)}</li>
      <li>{toCamelCase(data.top_code_3)}</li>
      <li>{toCamelCase(data.top_code_4)}</li>
      <li>{toCamelCase(data.top_code_5)}</li>
      <li>{toCamelCase(data.top_code_6)}</li>

      {/* <li>{data.top_code_7}</li>
<li>{data.top_code_8}</li><li>{data.top_code_9}</li><li>{data.top_code_10}</li> */}
    </ul>
    
  </div>
)
const ThalassemiaDetails = () => {
  const entity = 'raredisease'

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  const { data: thalData, error1 } = useGeoFetch('', '', entity, 'getthal')
  //check status here

  if (thalData !== undefined) {
    if (thalData.response_code === 200) {
    } else {
      return (
        <ErrorMessage
          errorCode={thalData.response_code}
          errorMessage={thalData.response_message}
          errorSubject={thalData.response_subject}
        />
      )
    }
  }
  if (error1) {
    return (
      <ErrorMessage
        errorCode={thalData.response_code}
        errorMessage={thalData.response_message}
        errorSubject={thalData.response_subject}
      />
    )
  }
  var filteredItems = []
  if (thalData !== undefined && thalData !== null && thalData !== '') {
    if (thalData.response_code === 200) {
      //  filteredItems = thalData.filter(
      //   item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()),
      // );
      filteredItems = thalData.response_data?.filter(
        (row) =>
          row?.first_name.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.last_name.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.race.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.gender.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.dob.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.provider_first_name
            .toLowerCase()
            ?.includes(filterText.toLowerCase()) ||
          row?.provider_last_name
            .toLowerCase()
            ?.includes(filterText.toLowerCase()) ||
          row?.npi.toLowerCase()?.includes(filterText.toLowerCase()) ||
          row?.transfusion_count
            .toLowerCase()
            ?.includes(filterText.toLowerCase()) ||
          row?.last_transfusion
            .toLowerCase()
            ?.includes(filterText.toLowerCase()) ||
          row?.probability.toLowerCase()?.includes(filterText.toLowerCase())
        // row?.hydroxyurea.toLowerCase()?.includes(filterText.toLowerCase())
      )
    }
  }

  return (
    <div className={styles.base}>
      <div className={styles.cityPanel}>
        <span className={styles.textFormat}>
          Updated on <strong>24th March</strong>. Data Displayed is from the{' '}
          <strong> last 12 months.</strong>
        </span>
        {filteredItems ? (
          <div>
            <CustomDataTable
              title=""
              columns={columns}
              data={filteredItems}
              expandableRows={true}
              expandableRowsComponent={ExpandedComponent}
              pagination
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeaderComponent={subHeaderComponentMemo}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default ThalassemiaDetails
