import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import getVectorImage from '../../utils/getVectorImage'
import styles from './SideTable.module.scss'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
import ReactSwitch from 'react-switch'
import ExpandArrowRight from '../../assets/images/icon/arrow_expand_right.png'
import SearchIcon from '../../assets/images/icon/search.png'
const Databind = ({ state, chartRef }) => {
 
  const {
    regionSelected,
  } = useGeoState()
  const history = useHistory()
  
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "MSA" ? 1 :regionSelected === "ZIP3"? 2: 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent
        //element.getAttribute('title')
      )
      point && chart.tooltip.refresh(point)
    }
  }

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  
  let statename=(regionSelected.toUpperCase()==='STATE'?state.statename+' ('+state.geo_id+')':state.statename)
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '80%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={statename}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
         
          data-title={state.geo_name}
          title={state.geo_name} 
          geo_id={state.geo_id}
        >
             {state.geo_name}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.data_value.toFixed(3)}</div>
      <div className={styles.columnDays}>
          {' '}
          <p> {state.istrue ? getVectorImage('trendup') : getVectorImage('trenddown') } </p>
          {parseFloat(state.change_actual_percentage).toFixed(2)}%
      </div>
    </div>
  )
}
function checkSideTableShow(lob,pos)
{
  let visible=true
  if(lob==='0' || pos==='0')
  {
        visible=true  
  }
  else if(lob!=='0')
  {
    if(pos!=='0')
    visible=false
    else
    visible=true
  }
  else
  {
      visible=true
  }
  return visible
}
const FilterData=({mappedStateData,chartRef})=>{
  const inputRef = React.useRef(false)
  let isCancelled = React.useRef(false)
  const {
    regionSelected,
    showUHCCoverage, setUHCCoverage ,
    showFullTable, setShowFullTable,searchFilter, setSearchFilter,setIsChecked,
    lobSelected,posSelected
  } = useGeoState()

  const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }
  const handleChangeCoverage = (val) => {
    showSearch()
    setUHCCoverage(val)
  }
  const handleFullTableChange = () => {
    showSearch()
    setShowFullTable(!showFullTable)
    setIsChecked(false)
  }
  let filteredStateData = mappedStateData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredStateData = mappedStateData.filter((cbsa) => {
     
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredStateData = filteredStateData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })
  function showSearch() {
   
    isCancelled.current = true
    btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
    btnSearchLocation ? setSearchFilter('') : (inputRef.current = false)
  }
  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      mappedStateData.sort((a, b) => b.data_value - a.data_value)
    } else if (action === 'down') {
      setfilter('up')
      mappedStateData.sort((a, b) => a.data_value - b.data_value)
    }
  }
  return (
    <div className={styles.base}>
      
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                  {regionSelected.toUpperCase()==='MSA'?'State & MSA':toTitleCase(regionSelected)}
                 
                </h5>
              </span>
            </div>
            <div  style={{
                display: 'inline-flex',
                width: '30%',
                flexDirection: 'row-reverse',
              }} >
              
              <img
                src={ExpandArrowRight}
                alt="expand-right"
                onClick={handleFullTableChange}
                style={{display:checkSideTableShow(lobSelected.toString(),posSelected.toString())?'block':'none', height: '20px', width: '20px', marginBottom: '-3px',cursor:'pointer' }}
              ></img>
                 <span >
                 <img
                src={SearchIcon}
                onClick={showSearch}
                alt="expand-right"
                style={{ height: '24px', 
                width: '24px',
                marginRight:'10px',
                marginBottom: '-3px',
                cursor:'pointer' }}
              ></img>
              </span>
              </div>
          </div>
          <div>
          {btnSearchLocation ? (
            <input
              ref={inputRef}
              type="text"
              onChange={handleChange}
              placeholder={"Search "+toTitleCase(regionSelected)}
            />
          ) : null}
          </div>
         
        </div>
       
          
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <span className={styles.showOnMap}>UHC Coverage </span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showUHCCoverage}
              onChange={handleChangeCoverage}
            />
          </div>
       
        <div
          style={{
            padding: '1rem 0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '40%' }}>
          <h4>Area Name</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '40%',
              justifyContent: 'flex-middle',
              cursor: 'pointer',
            }}
          >
            <h4 onClick={() => sortBy(getFilter)}>
            <span style={{ marginRight: '10px' }}>
                {getVectorImage(getFilter)}
              </span>
              Incidence{' '}
             
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '20%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Change</h4>
          </div>
        </div>
      </div>

      <div className={styles.scroll}>
        {filteredStateData.map((state) => {
          return (
            <Databind state={state} key={state.statename} chartRef={chartRef} />
          )
        })}
      </div>
    </div>
  )
}
const SideTable = ({ stateData, chartRef }) => {
  
  const {
    displayDate
  } = useGeoState()
   const statesDataForDisplayDate = Object.entries(stateData[displayDate])
  const mappedStateData = statesDataForDisplayDate.map((state) => {
    const stateName = state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })
  return ( <FilterData mappedStateData={mappedStateData}  chartRef={chartRef}></FilterData>)
 
}

export default SideTable
