import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { useAuthState } from '../../components/AuthProvider'
import ButtonList from '../../components/ButtonList'
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import Card from '../../components/Card'

import { APP_NAME } from '../../utils/constants'

import styles from './Home.module.scss'
const Home = () => {
  

  const history = useHistory()
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Redirect to="/home" />
  } else {
    return (
      <div className={styles.base}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo color="#fff" />
          </div>

          <Card className={styles.loginCard}>
            <div className={styles.appName}>
              <span>{APP_NAME}</span>
            </div>           
            <div className={styles.loginPreference}>
              {/* <p>Choose your login preference:</p> */}
            </div>
            <ButtonList className={styles.loginBtns}>
              <Button
                primary
                showArrow
                onClick={() => history.push('/ohid-login')}
              >
                One Healthcare ID
              </Button>
             
            </ButtonList>
          </Card>
        </div>
      </div>
    )
  }
}

export default Home
