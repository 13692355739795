const colorAxisCasesWeekly = {
    tickInterval: 95208.66667,
    min: 0,
    max: 856878,
    // stops: [
    //   [0, '#aaa'],
    //   [0.0001, '#fff9bc'],
    //   [0.005403306      , '#fff9bc'],
    //   [0.013972612      , '#E0F500'],
    //   [0.025319555      , '#FCB100'],
    //   [0.042424396      , '#FC8200'],
    //   [0.066114516      , '#FA4F00'],
    //   [0.110936629      , '#CC0000'],
    //   [0.217483072      , '#960000'],
    //   [1, '#6E0000'],
    // ],
    stops: [
      [0, '#aaa'],
      [0.0001, '#fff9bc'],
      [0.017508588      , '#fff9bc'],
      [0.037758588      , '#E0F500'],
      [0.060474353      , '#FCB100'],
      [0.087417176      , '#FC8200'],
      [0.122272529      , '#FA4F00'],
      [0.175138529      , '#CC0000'],
      [0.251526353      , '#960000'],
      [0.391565118      , '#6E0000'],
      [1, '#3b0000'],
    ],
  }
  
  export default colorAxisCasesWeekly