import React from 'react'
import { useMedia } from '../ResponsiveMedia'
import transformPadding from './transformPadding'
import { ThemeContext } from '../Theme'

import styles from './Card.module.scss'

const Card = ({
  children = null,
  maxWidth,
  padding: defaultPadding = { xs: 2, md: 3, lg: 4, xl: 5 },
  primary: isPrimary,
  ...rest
}) => {
  const padding = useMedia(defaultPadding, transformPadding)
  const theme = React.useContext(ThemeContext)
  const className = `${styles.base} ${styles[theme] ? styles[theme] : ''} ${
    rest.className ? rest.className : ''
  }`

  return (
    <section
      className={`${className ? className : ''} ${
        isPrimary ? styles.primary : ''
      }`}
      style={{ padding, maxWidth }}
    >
      {children}
    </section>
  )
}

export default Card
