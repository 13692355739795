import cdcMapLegendHorizontal from './cdcMapLegendHorizontal'
import legend from './legend'
import colorAxis from './colorAxisIndex'

const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'revised_accuracy',
        tagKey: 'revised_accuracy',
        tagValue: 'revised_accuracy',
        mapeValue: 'revised_mape',
        maeValue: 'revised_mae',
        rmseValue: 'revised_rmse',
        maseValue: 'revised_mase',
        label: 'Revised',
        colorAxis: colorAxis,
        mapTitle: 'CI Accuracy ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        //reversed: true,
        legend: {
          enabled: false,
          // ...legend,
          title: {
            text: '',
          },
          // reversed: true,
          itemWidth: 100,
        },
      },
      {
        value: 'unrevised_accuracy',
        tagKey: 'unrevised_accuracy',
        tagValue: 'unrevised_accuracy',
        mapeValue: 'unrevised_mape',
        maeValue: 'unrevised_mae',
        rmseValue: 'unrevised_rmse',
        maseValue: 'unrevised_mase',
        label: 'UnRevised',
        colorAxis: colorAxis,
        mapTitle: 'CI Accuracy  ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        // reversed: true,
        legend: {
          enabled: false,
          // ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },
    ],
    optionscovid: [
      {
        value: 'revised_accuracy',
        tagKey: 'new_deaths',
        tagValue: 'new_deaths',
        mapeValue: 'revised_mape',
        maeValue: 'revised_mae',
        rmseValue: 'revised_rmse',
        //ci95Value: 'ci_95',
        maseValue: 'revised_mase',
        label: 'Revised',
        colorAxis: colorAxis,
        mapTitle: 'Covid Accuracy  ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        // reversed: true,
        legend: {
          enabled: false,
          // ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },
      {
        value: 'unrevised_accuracy',
        tagKey: 'new_cases',
        tagValue: 'new_cases',
        mapeValue: 'unrevised_mape',
        maeValue: 'unrevised_mae',
        rmseValue: 'unrevised_rmse',
        //ci95Value: 'ci_95',
        maseValue: 'unrevised_mase',
        label: 'UnRevised',
        colorAxis: colorAxis,
        mapTitle: 'Covid Accuracy  ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        // reversed: true,
        legend: {
          enabled: false,
          // ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },

      {
        value: 'hospital_admissions',
        tagKey: 'hospital_admissions',
        tagValue: 'hospital_admissions',
        mapeValue: 'mape',
        maeValue: 'mae',
        rmseValue: 'rmse',
        ci95Value: 'ci_80',
        label: 'Hospitalization',
        colorAxis: colorAxis,
        countyColorAxis: colorAxis,
        mapTitle: 'Covid Hospitalization Accuracy',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        // reversed: true,
        legend: {
          enabled: false,
          // ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },
    ],
  },
]

export default dataDisplayOptions
