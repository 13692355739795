import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../../components/DisplayDatePharma/DisplayDateFluNew'
import Spinner from '../../../components/Loader/Spinner'
import EmptyMap from '../../../components/Map/emptyMap'
import Timeline from '../../../components/TimelinePharma'
import Map from '../../../components/MapPharma'
import toolTipFormatter from './../toolTipFormatter'
import MapLegend from './../cdcMapLegendVertical'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
const DashboardMap = ({ geo_type, geo_parent, zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  // const entity = 'flu'
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [metros, setMetros] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [getStats, setStats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covidMap',
    'STATE/?geo_parent=US', true
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covidMap',
    'MSA/?geo_parent=' + geo_parent, true
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Provider Dashboard success',
          user: user,
        })
        setMetros(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setMetros(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covidLocations',
    'activelocations?geo_type=MSA&geo_parent_code=' + geo_parent, true
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'covidStats',
    geo_parent + '/', true
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else if (stats.response_code === 404) {
        setStats(stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${stats.response_subject}, ${stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  useEffect(() => {
    let url = `${process.env.REACT_APP_UI_HOME}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${process.env.REACT_APP_UI_HOME}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])
  if (
    stateDataError ||
    MapError ||
    errorOccured ||
    activeCbsasDataError ||
    MapmsaError
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorCode === 404 ? `for ${geo_parent}` : errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable
                  stateData={getStats}
                  chartRef={chartRef}
                  geo_parent={geo_parent}
                  geo_id={zoomLocation}
                />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {metros !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined &&
            getCBSAJsonData ? (
              <>
               <div className={`${styles.providerHeader}`}>
               <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'COVID'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
               </div>
                <MapLegend />
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={activeCbsa}
                  statesData={getMapStates}
                  cbsaData={metros}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAPMSA"
                  enableButtons={true}
                  zoomLocation={zoomLocation}
                  chartRef={chartRef}
                  mapDataType={geo_type === 'STATE' ? 0 : 1}
                  JsonData={getCBSAJsonData}
                  stateJson={getStateJson}
                  dashboardType="COVID"
                />
                {/* <div className={`${styles.timelinePanel}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Covid'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div> */}
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
