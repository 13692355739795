const regionDisplayOptions = [
  { value: 'state', label: 'States' },
  { value: 'msa', label: 'State + CBSA' },
  { value: 'county', label: 'Counties' },
]
const regionDisplayOptionsHRR = [
  { value: 'hrr', label: 'Hospital Referral Regions' },
]

const getRegionDisplayOptions = (option) => {
  switch (option) {
    case 'Covid Hospitalization':
      return regionDisplayOptionsHRR
    default:
      return regionDisplayOptions
  }
}

export default getRegionDisplayOptions
